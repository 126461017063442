import { IFeed } from '../../../frontend-libs/evlapp-types';
import cx from 'classnames';
import { useAppSelector } from '../../hooks';
import { t } from '../../i18n';
import { selectSecurity } from '../../store/securitySlice';
import { formatDate } from '../../utils/Date/Date';
import { Text, ButtonLink, ButtonLinkProps } from '../index';

import { Card } from './Card';

interface CardFeedProps extends IFeed {
  detail: ButtonLinkProps['to'];
}

export const CardFeed: React.FC<CardFeedProps> = (props) => {
  const security = useAppSelector(selectSecurity).ui;
  const isAdmin = security.isAdmin;

  const createdAt = formatDate(props.createdAt);

  return (
    <Card className={cx('CardFeed')}>
      <div className="CardFeed__header">
        {/*
          <Text as="span" size="body-small" color="Neutral600" weight="500" className="CardFeed__type">
            {t(`Card.Feed.${props.type}`)}
          </Text>
        */}
        {!props.isPublished && (
          <Text as="span" size="body-small" color="Neutral600" weight="500" className="CardFeed__type">
            {t(`Card.Feed.concept`)}
          </Text>
        )}

        {props.isPublished && isAdmin && (
          <Text as="span" size="body-small" color="Neutral600" weight="500" className="CardFeed__type">
            {t(`Card.Feed.published`)}
          </Text>
        )}
        <Text as="span" size="body-small" color="Neutral600" weight="500" className="CardFeed__published">
          {createdAt}
        </Text>

        <ButtonLink
          to={props.detail}
          title="Detail"
          variant="text"
          iconAfter="arrowRight"
          className="CardFeed__button"
        />
      </div>
      <div className="Card__divider"></div>
      <Text size="h3" color="Neutral750">
        {props.name}
      </Text>
      {/*
        <Text as="div" size="body-medium" color="Neutral700" className="CardFeed__message">
          {props.content}
        </Text>
      */}
    </Card>
  );
};
