import { TSFixMe } from '../../frontend-libs/evlapp-types';

import { getBuildingsByAddress } from '../services/RegistrationService';

export const getBuildingOptions = async (value: string, cb: (options: TSFixMe[]) => void): Promise<void> => {
  if (value.length <= 3) return cb([]);

  try {
    const res = await getBuildingsByAddress(value);

    const options = res.map((item: TSFixMe) => {
      return { addressId: item.id, address: item.address, type: item.type, isRegistered: item.isRegistered };
    });

    return cb(options);
  } catch {
    return cb([]);
  }
};
