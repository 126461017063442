import cx from 'classnames';
import { IPoll } from '../../../frontend-libs/evlapp-types';
import { IconNames } from '../Icon/IconNames';
import { t } from '../../i18n';
import { diffDays } from '../../utils/Date/Date';
import { Text, Icon, Card, ButtonLinkProps, ButtonLink } from '../index';

interface CardPollActiveProps extends IPoll {
  icon?: IconNames;
  name: string;
  className?: string;
  buttonVoteLink?: ButtonLinkProps | false;
  buttonDetailLink?: ButtonLinkProps | false;
}

export const CardPollActive: React.FC<CardPollActiveProps> = (props) => {
  const { icon = 'horn', name, quorate, endAt, buttonDetailLink, buttonVoteLink, className } = props;

  let days = 0;
  if (endAt) days = diffDays(endAt);

  return (
    <Card className={cx('CardPoll', `CardPoll--active`, className)}>
      <div className="CardPoll__header">
        {icon && <Icon name={icon} className="CardPoll__icon" />}
        <Text size="h3" color="Neutral800">
          {name}
        </Text>
      </div>
      <div className="Card__divider"></div>
      <div className="CardPollDetails">
        <div className="mr-auto">
          <Text as="div" size="body-small" weight="500" color="Neutral700" className="CardPoll__quorum">
            {t(`${'Card.Poll.Active.quorateText'}`)}
            <div className="CardPoll__quorum-divider"></div>
            <Text as="span" size="body-small" weight="600">
              {quorate.participated}/{quorate.total}
            </Text>
          </Text>

          <Text as="div" size="body-small" color="Neutral600" className="CardPollStatus__date">
            {t(`${days >= 0 ? 'Date.daysRemains' : 'Date.daysExpired'}`, undefined, { count: Math.abs(days) })}
          </Text>
        </div>

        <div className="FlexColumn FlexAlign--end FlexGap--1">
          {buttonVoteLink && <ButtonLink size="small" iconAfter="arrowRight" {...buttonVoteLink} />}
          {buttonDetailLink && <ButtonLink size="small" variant="text" iconAfter="arrowRight" {...buttonDetailLink} />}
        </div>
      </div>
      {/*
        TODO: EVLAPP-815
        <div className="Card__divider"></div>
        <Text size="body-xs" color="Blue500" children={t('Poll.Results.TabVoterList.Ticket.generateAll')} />
      */}
    </Card>
  );
};
