import {
  IPostReqSecurityAuthenticationLogin,
  IPostReqSecurityResetPasswordRequest,
  IPostReqSecurityResetPasswordReset,
  IProfile,
  IProfileBuilding,
  IPutReqUserProfile,
  TSFixMe,
} from '../../frontend-libs/evlapp-types';
import { createAsyncThunk as asyncThunk, createSlice } from '@reduxjs/toolkit';

import { userLogin } from '../../Login/services/loginService';
import { changePassword, resetPassword } from '../../PasswordReset/services/PasswordResetService';
import { getUserProfile, updateUserProfile, updateUserProfileRequest } from '../../Profile/services/ProfileService';
import { registerAdmin as register } from '../../Registration/services/RegistrationService';
import { localStorage, RestApi } from '../services';
import type { RootState } from './store';

import { IAdminRegistration } from '../../Registration/Admin/interfaces';
import { registerMemberWithInvitation } from '../../Registration/Invitation/services/InvitationService';

interface SecurityState {
  state: 'authenticated' | 'notAuthenticated' | 'pending' | 'badCredentials';
  token?: string | null;
  profile?: IProfile;
  buildings?: TSFixMe;
  ui: {
    state: 'member' | 'admin';
    buildingId: string | null;
    buildingState: 'initialization' | 'loading' | 'ready' | 'error';
    address: string | null;
    isAdmin: boolean;
    isMember: boolean;
    subscription: {
      isExpired: boolean;
      expiresAt: string;
      inGracePeriod: boolean;
      gracePeriodEndsAt: string;
      isAfterGracePeriod: boolean;
    };

    tokens: IProfileBuilding['tokens'];
  };
}

const initialState: SecurityState = {
  state: 'notAuthenticated',
  token: localStorage.getToken(),
  ui: {
    state: 'member',
    buildingId: null,
    address: null,
    isAdmin: false,
    isMember: false,
    buildingState: 'error',
    tokens: [],
    subscription: {
      isExpired: false,
      expiresAt: '',
      inGracePeriod: false,
      gracePeriodEndsAt: '',
      isAfterGracePeriod: false,
    },
  },
};

export const loginUser = asyncThunk('security/login', async (values: IPostReqSecurityAuthenticationLogin) => {
  return userLogin(values);
});

export const getProfile = asyncThunk('security/profile/get', async () => {
  return getUserProfile();
});

export const updateProfile = asyncThunk(
  'security/profile/update',
  async (values: IPutReqUserProfile, { rejectWithValue }) => {
    try {
      return await updateUserProfile(values);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateProfileProtectedRequest = asyncThunk(
  'security/profile/update-request',
  async (values: IPutReqUserProfile, { rejectWithValue }) => {
    try {
      return await updateUserProfileRequest(values);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const registerUserWithInvitation = asyncThunk(
  'security/register/user-invitation',
  async (values: TSFixMe, { rejectWithValue }) => {
    try {
      return await registerMemberWithInvitation(values);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const registerAdmin = asyncThunk('security/register/admin', async (values: IAdminRegistration) => {
  return register(values);
});

export const passwordReset = asyncThunk(
  'security/password-reset/request',
  async (values: IPostReqSecurityResetPasswordRequest) => {
    return resetPassword(values);
  }
);

export const passwordChange = asyncThunk(
  'security/password-reset/change',
  async (values: IPostReqSecurityResetPasswordReset) => {
    return changePassword(values);
  }
);

const setUIState = (state: SecurityState['ui'], payload: TSFixMe): SecurityState['ui'] => {
  delete payload.profileId;
  let userState: SecurityState['ui']['state'] = 'member';
  if (!payload.isMember && payload.isAdmin) userState = 'admin';

  let subscription = {
    isExpired: false,
    inGracePeriod: false,
    isAfterGracePeriod: false,
    expiresAt: '',
    gracePeriodEndsAt: '',
  };

  if (payload.subscription) {
    subscription = {
      isExpired: payload.subscription.isExpired || false,
      inGracePeriod: payload.subscription.inGracePeriod || false,
      isAfterGracePeriod: (payload.subscription.isExpired && !payload.subscription.inGracePeriod) || false,
      expiresAt: payload?.subscription?.expiresAt || '',
      gracePeriodEndsAt: payload.subscription.gracePeriodEndsAt || '',
    };
  }

  return {
    ...state,
    ...payload,
    state: userState,
    buildingState: payload.state || 'ready',
    subscription: subscription,
  };
};

export const securitySlice = createSlice({
  name: 'security',
  initialState,
  reducers: {
    toggleUI: (state) => {
      let newState: SecurityState['ui']['state'] = 'member';
      if (state.ui.state === 'member') newState = 'admin';

      state.ui = { ...state.ui, state: newState };
    },
    changeBuilding: (state, { payload }) => {
      let newState: SecurityState['ui']['state'] = 'member';
      if (!payload.isMember && payload.isAdmin) newState = 'admin';

      let subscription = {
        isExpired: false,
        inGracePeriod: false,
        isAfterGracePeriod: false,
        expiresAt: '',
        gracePeriodEndsAt: '',
      };

      if (payload.subscription) {
        subscription = {
          isExpired: payload.subscription.isExpired || false,
          inGracePeriod: payload.subscription.inGracePeriod || false,
          isAfterGracePeriod: (payload.subscription.isExpired && !payload.subscription.inGracePeriod) || false,
          expiresAt: payload?.subscription?.expiresAt || '',
          gracePeriodEndsAt: payload.subscription.gracePeriodEndsAt || '',
        };
      }

      state.ui = {
        ...state.ui,
        ...payload,
        state: newState,
        buildingState: payload.state || 'ready',
        subscription: subscription,
      };
    },
    logout: (state) => {
      state = initialState;
      localStorage.deleteToken();
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.state = 'pending';
    });

    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.state = 'authenticated';
      state.token = payload?.token;
      state.profile = payload.profile;
      state.buildings = payload.buildings;

      const defaultBuilding = state.buildings?.[0] || null;

      setUIState(state.ui, defaultBuilding);
      state.ui = setUIState(state.ui, defaultBuilding);

      if (payload.token) {
        RestApi.setToken(payload.token);
        localStorage.setToken(payload.token);
      }
    });

    builder.addCase(registerUserWithInvitation.fulfilled, (state, { payload }) => {
      state.state = 'authenticated';
      state.token = payload?.token;
      state.profile = payload.profile;
      state.buildings = payload.buildings;

      const defaultBuilding = state.buildings?.[0] || null;

      setUIState(state.ui, defaultBuilding);
      state.ui = setUIState(state.ui, defaultBuilding);

      if (payload.token) {
        RestApi.setToken(payload.token);
        localStorage.setToken(payload.token);
      }
    });

    builder.addCase(getProfile.pending, (state) => {
      state.state = 'pending';
    });

    builder.addCase(getProfile.rejected, (state) => {
      state.state = 'badCredentials';
      RestApi.removeToken();
      localStorage.deleteToken();
    });

    builder.addCase(getProfile.fulfilled, (state, { payload }) => {
      state.state = 'authenticated';
      state.profile = payload.profile;
      state.buildings = payload.buildings;

      const defaultBuilding = state.buildings?.[0] || null;
      state.ui = setUIState(state.ui, defaultBuilding);
    });

    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.profile = payload.profile;
    });

    builder.addCase(registerAdmin.fulfilled, (state, { payload }) => {
      state.state = 'authenticated';
      state.token = payload?.token;
      state.profile = payload.profile;
      state.buildings = payload.buildings;

      const defaultBuilding = state.buildings?.[0] || null;
      state.ui = setUIState(state.ui, defaultBuilding);

      if (payload.token) {
        RestApi.setToken(payload.token);
        localStorage.setToken(payload.token);
      }
    });
  },
});

export const { toggleUI, logout, changeBuilding } = securitySlice.actions;
export const selectSecurity = (state: RootState): SecurityState => state.security;
export default securitySlice.reducer;
