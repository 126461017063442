import { useEffect, useState } from 'react';
import { IPoll } from '../frontend-libs/evlapp-types';

import { t } from '../common/i18n';

import {
  Accordion,
  ButtonLink,
  CardPollActive,
  CardPollCompleted,
  CardPollOncoming,
  Content,
  Header,
} from '../common/components';
import { getPolls } from './services/PollService';
import { filterPolls } from './utils/PollUtils';
import { useAppSelector, useDocumentTitle } from '../common/hooks';
import { selectSecurity } from '../common/store/securitySlice';

export default function Poll(): JSX.Element {
  useDocumentTitle(t('Poll.title'));

  const ui = useAppSelector(selectSecurity).ui;
  const isAdmin = ui.state === 'admin' ? true : undefined;
  const isMember = ui.isMember;
  const isAfterGracePeriod = ui.subscription.isAfterGracePeriod;

  const [search, setSearch] = useState<string>('');

  const [hasDraft, setHasDraft] = useState<boolean>(false);
  const [polls, setPolls] = useState<IPoll[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetch() {
      const data = await getPolls();
      setHasDraft(data.filter((item) => item.isDraft).length > 0);
      setPolls(data.filter((item) => item.isDraft !== true));
      setIsLoading(false);
    }
    fetch();
  }, []);

  const filteredPolls = filterPolls(polls, search);

  const publishPolls = filteredPolls.publish;
  const activePolls = filteredPolls.active;
  const finishPolls = filteredPolls.finish.slice(0, 2);
  const finishArchivedPolls = filteredPolls.finish.slice(2);

  const isEmpty =
    publishPolls.length === 0 &&
    activePolls.length === 0 &&
    finishPolls.length === 0 &&
    finishArchivedPolls.length === 0;

  return (
    <>
      <Header
        title={t('Poll.Header.title')}
        search={{ value: search, onChange: (e) => setSearch(e.target.value.trimStart()) }}
        actions={
          isAdmin &&
          !isLoading &&
          !isAfterGracePeriod && (
            <ButtonLink size="small" title={t(`Poll.Header.${!hasDraft ? 'createDraft' : 'updateDraft'}`)} to="add" />
          )
        }
      />
      <Content isLoading={isLoading} isEmpty={isEmpty} isEmptyText={t('Poll.emptyList')}>
        {publishPolls.length > 0 && (
          <Accordion title={t('Poll.List.OncomingPolls.title')} className="mb-3">
            <div className="List">
              {publishPolls.map((item) => {
                return <CardPollOncoming key={item.id} {...item} />;
              })}
            </div>
          </Accordion>
        )}

        {activePolls.length > 0 && (
          <Accordion title={t('Poll.List.ActivePolls.title')} className="mb-3">
            <div className="List">
              {activePolls.map((item) => {
                const canVote = isMember && item.canVote;
                const canDetail = isAdmin || item.isResultPublished;

                return (
                  <CardPollActive
                    key={item.id}
                    {...item}
                    buttonVoteLink={canVote && { to: `detail/${item.id}`, title: t('Poll.Card.vote') }}
                    buttonDetailLink={canDetail && { to: `results/${item.id}`, title: t('Poll.Card.detail') }}
                  />
                );
              })}
            </div>
          </Accordion>
        )}

        {finishPolls.length > 0 && (
          <Accordion title={t('Poll.List.CompletedPolls.title')} className="mb-3">
            <div className="List">
              {finishPolls.map((item) => {
                const canDetail = isAdmin || item.isResultPublished;

                return (
                  <CardPollCompleted
                    key={item.id}
                    {...item}
                    buttonDetailLink={canDetail && { to: `results/${item.id}`, title: t('Poll.Card.detail') }}
                  />
                );
              })}
            </div>
          </Accordion>
        )}

        {finishArchivedPolls.length > 0 && (
          <Accordion title={t('Poll.List.ArchivedPolls.title')} className="mb-3" isOpen={false}>
            <div className="List">
              {finishArchivedPolls.map((item) => {
                const canDetail = isAdmin || item.isResultPublished;

                return (
                  <CardPollCompleted
                    key={item.id}
                    {...item}
                    buttonDetailLink={canDetail && { to: `results/${item.id}`, title: t('Poll.Card.detail') }}
                  />
                );
              })}
            </div>
          </Accordion>
        )}
      </Content>
    </>
  );
}
