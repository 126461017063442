import { Outlet } from 'react-router';
import imageBackground from '../../../src/assets/images/onboarding-background.jpg';

export const OnboardingLayout: React.FC = () => {
  return (
    <div className="Onboarding">
      <div className="OnboardingSidebar">
        <img src={imageBackground} alt="" />
      </div>
      <div className="OnboardingMain">
        <Outlet />
      </div>
    </div>
  );
};
