import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import routes from './common/constants/routes';
import { selectUi } from './common/store/uiSlice';

// Layouts
import PrivateRoute from './common/components/PrivateRoute/PrivateRoute';
import { OnboardingLayout } from './common/layouts/Onboarding';

// Public pages
import Login from './Login';
import Logout from './Login/Logout';
import PasswordResetRouter from './PasswordReset';
import AdminRegistrationRouter from './Registration/Admin';
import Invitation from './Registration/Invitation';

// Private pages
import PollRouter from './Poll';
import UnitsRouter from './Units';
import BuildingRouter from './Building';
import FilesRouter from './FileStorage';
import FeedRouter from './Feed';
import Notifications from './Notifications';
import ProfileRouter from './Profile';
import Subscriptions from './Subscription';

// Dev pages
import Playground from './Playground/Playground';
import { FlashMessageContainer } from './common/components';
import { useAppSelector } from './common/hooks';
import { PageError } from './System/PageError';

function App(): JSX.Element {
  const flashes = useAppSelector(selectUi).flashes;

  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route element={<OnboardingLayout />}>
            <Route path={`${routes.invitation}:token`} element={<Invitation />} />
            <Route path={`${routes.login}`} element={<Login />} />
            <Route path={`${routes.logout}`} element={<Logout />} />
            <Route path={`${routes.passwordReset}*`} element={<PasswordResetRouter />} />
            <Route path={`${routes.registration}*`} element={<AdminRegistrationRouter />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path={`${routes.root}`} element={<Navigate to={routes.poll} />} />
            <Route path={`${routes.poll}*`} element={<PollRouter />} />
            <Route path={`${routes.feed}*`} element={<FeedRouter />} />
            <Route path={`${routes.units}*`} element={<UnitsRouter />} />
            <Route path={`${routes.building}*`} element={<BuildingRouter />} />
            <Route path={`${routes.files}*`} element={<FilesRouter />} />
            <Route path={`${routes.profile}*`} element={<ProfileRouter />} />
            <Route path={routes.notifications} element={<Notifications />} />
            <Route path={routes.subscriptionOrder} element={<Subscriptions />} />
          </Route>

          {process.env.NODE_ENV === 'development' && <Route path={`${routes.playground}*`} element={<Playground />} />}

          <Route path="*" element={<PageError code="404" />} />
        </Routes>
      </BrowserRouter>
      <FlashMessageContainer items={flashes} />
    </>
  );
}

export default App;
