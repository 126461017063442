import cx from 'classnames';
import React from 'react';
import { FormFieldProps } from '../Base';

import { FieldError } from '../Error/FieldError';
import { Label } from '../Label/Label';

export interface TextFieldProps<T> extends FormFieldProps<string> {
  type?: 'text' | 'email' | 'number' | 'password';
  placeholder?: string;
  className?: string;
  autocomplete?: string;
  maxlength?: number;
  minlength?: number;
  min?: number;
  max?: number;
  onBlur?: (e: React.ChangeEvent<T>) => void;
  onChange?: (e: React.ChangeEvent<T>) => void;
  onKeyPress?: (e: React.KeyboardEvent<T>) => void;
}

export const TextField: React.FC<TextFieldProps<HTMLInputElement>> = (props) => {
  const {
    id = props.name,
    name,
    value,
    autocomplete,
    type = 'text',
    className,
    label,
    afterLabel,
    error,
    maxlength,
    minlength,
    min,
    max,
    required,
    tooltip,
    ...rest
  } = props;

  let inputValue = value || '';

  if (min && Number(inputValue) < min) inputValue = min.toString();
  if (max && Number(inputValue) > max) inputValue = max.toString();

  return (
    <div className={cx('TextField', className)}>
      {label && (
        <Label
          classNamePrefix="TextField"
          id={id}
          label={label}
          afterLabel={afterLabel}
          required={required}
          tooltip={tooltip}
        />
      )}
      <input
        className="TextField__control"
        name={name}
        id={id}
        type={type}
        value={inputValue}
        autoComplete={autocomplete}
        maxLength={maxlength}
        minLength={minlength}
        {...rest}
      />
      <FieldError {...error} />
    </div>
  );
};
