import cx from 'classnames';
import { Icon, Text } from '../index';

interface PhoneProps {
  number: string;
  className?: string;
}

export const Phone: React.FC<PhoneProps> = (props) => {
  return (
    <a href={`tel:${props.number}`} className={cx(`ContactDetail ContactDetail--phone`, props.className)}>
      <Icon name="phone" className="ContactDetail__icon" color="Blue600" />
      <Text as="span" size="body-medium" weight="400" className="ContactDetail__label">
        {props.number}
      </Text>
    </a>
  );
};

interface EmailProps {
  email: string;
  className?: string;
}

export const Email: React.FC<EmailProps> = (props) => {
  return (
    <a href={`mailto:${props.email}`} className={cx(`ContactDetail ContactDetail--email`, props.className)}>
      <Icon name="email" className="ContactDetail__icon" color="Blue600" />
      <Text as="span" size="body-medium" weight="400" className="ContactDetail__label">
        {props.email}
      </Text>
    </a>
  );
};
