import i18n, { TOptions, StringMap } from 'i18next';
import { initReactI18next } from 'react-i18next';

import cs from './cs';

i18n.use(initReactI18next).init({
  lng: 'cs',
  debug: process.env.REACT_APP_ENV === 'dev',
  fallbackLng: 'cs',
  resources: { cs: { translation: { ...cs } } },
  interpolation: { escapeValue: false },
});

export const t = (
  key: string | TemplateStringsArray | (string | TemplateStringsArray)[],
  namespace?: string,
  options?: string | TOptions<StringMap> | undefined
): string => i18n.t(`${namespace ? `${namespace}.` : ''}${key}`, options);

export const translationExist = (t: string): boolean => i18n.exists(t);

export default i18n;
