import React from 'react';
import { Card } from '../../common/components';
import { useAppSelector } from '../../common/hooks';
import { t } from '../../common/i18n';
import { selectPoll } from '../../common/store/pollSlice';
import { formatDate } from '../../common/utils/Date/Date';

export const TabDetail: React.FC = () => {
  const namespace = 'Poll.Results.TabDetail';

  const poll = useAppSelector(selectPoll);

  let pollStatus = 'ongoing';
  if (poll.isFinished && poll.isResultPublished) pollStatus = 'published';
  if (poll.isFinished && !poll.isResultPublished) pollStatus = 'waitingForResults';
  if (!poll.isFinished && poll.isResultPublished) pollStatus = 'publishedOngoing';

  return (
    <Card className="Card--table">
      <table className="Table">
        <tbody>
          <tr>
            <th>{t('Table.status', namespace)}</th>
            <td>{t(`Table.PollState.${pollStatus}`, namespace)}</td>
          </tr>
          <tr>
            <th>{t('Table.startDate', namespace)}</th>
            <td>{formatDate(poll.startAt)}</td>
          </tr>
          <tr>
            <th>{t('Table.endDate', namespace)}</th>
            <td>{formatDate(poll.endAt)}</td>
          </tr>
          <tr>
            <th>{t('Table.evaluationDate', namespace)}</th>
            <td>{formatDate(poll.publicAt)}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};
