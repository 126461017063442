import { Button, Text } from '../../common/components';

import { t } from '../../common/i18n';
interface Step1and2Props {
  step: number;
  phone?: string;
  handleRequestCode: () => void;
  isButtonLoading: boolean;
}

export const Step1and2: React.FC<Step1and2Props> = (props) => {
  return (
    <>
      <Text size="h2" color="Neutral800" className="mb-1" align="center">
        {t('Poll.Question.Send.notice')}
      </Text>
      {props.step === 1 && (
        <>
          <Text size="body-medium" color="Neutral700" weight="500" align="center">
            {t('Poll.Question.Send.step1NoticeText')}
          </Text>
          <div className="Modal__divider"></div>
        </>
      )}
      <Text size="body-small" color="Neutral750" weight="500" align="center" className="mb-3">
        {t('Poll.Question.Send.smsNotice')}
      </Text>

      <div className="Flex FlexAlign--center FlexJustify--center mb-2">
        <Button
          variant="primary"
          wide={true}
          title={t('Poll.Question.Send.step1ConfirmButton')}
          onClick={props.handleRequestCode}
          loading={props.isButtonLoading}
          disabledDeps={['expired']}
        />
      </div>
      <Text size="body-small" color="Neutral500" weight="400" align="center">
        {t('Poll.Question.Send.smsNoticeNumber')}
        {props.phone}
      </Text>
    </>
  );
};
