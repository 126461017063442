import { TSFixMe } from '../../../frontend-libs/evlapp-types';

declare const window: TSFixMe;

const push = function (...rest: TSFixMe[]): void {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(...rest);

  if (process.env.REACT_APP_ENV === 'dev') console.log('GTM.push', ...rest);
};

export default {
  push,
};
