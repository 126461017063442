export default {
  title: 'Registrace',

  BuildingSearch: {
    heading: 'Zadejte adresu a vyhledejte Váš dům',
    Form: {
      Fields: {
        AddressId: {
          label: 'Adresa vašeho domu',
          placeholder: 'Začněte psát adresu vašeho domu',
          minLength: 'Napište minimálně čtyři znaky',
          noData: 'Na vámi zadané adrese nebyla nalezena žádná budova',
        },
        Submit: {
          title: 'Hledat dům',
        },
      },
    },
  },

  BuildingConfirm: {
    heading: 'Dům na adrese <0>{{address}}</0> je možné zaregistrovat do aplikace eVlastníci.',
    headingRegistered: 'Dům na adrese <0>{{address}}</0> je již zaregistrovaný.',
    headingNotValidType: 'Objekt na adrese <0>{{address}}</0> neodpovídá domu který lze zaregistrovat.',
    subheading: 'Zaregistrujte ho během několika minut.',
    subheadingRegistered: 'Kontaktujte svého správce který vám vytvoří přístup do aplikace.',
    subheadingNotValidType: 'Pokud si přesto myslíte že adresa vašeho domu je správná kontaktujte naší podporu',
    nextStep: 'Zaregistrovat dům',
    prevStep: 'Změnit adresu',
  },

  LegalEntity: {
    heading: 'Vyplňte údaje k vašemu SVJ na adrese {{address}}.',
    Form: {
      Fields: {
        Company: {
          label: 'Název vašeho SVJ',
          placeholder: 'Zadejte název vašeho SVJ',
          required: 'Název SVJ musí být vyplněný',
        },
        AddressId: {
          label: 'Adresa sídla SVJ',
          placeholder: 'Zadejte adresu sídla SVJ',
          required: 'Adresa sídla SVJ je povinná',
          minLength: 'Napište minimálně čtyři znaky',
          noData: 'Na vámi zadané adrese nebyla nalezena žádná budova',
        },
        CompanyId: {
          label: 'IČO',
          placeholder: 'Zadejte IČO vašeho SVJ',
          required: 'IČO musí být vyplněné',
        },
        TaxId: {
          label: 'DIČ',
          placeholder: 'Zadejte DIČ vašeho SVJ',
          required: 'DIČ musí být vyplněné',
        },
        Admin: {
          label: 'Potvrzuji, že jsem osoba oprávněná rozhodovat o záležitostech vybraného SVJ',
          required: 'Toto pole musí být zaškrtnuto',
        },
        IsPermitted: {
          label:
            'Beru na vědomí, že pro používání aplikace musí být stanovy vybraného SVJ v souladu s doporučením služby eVlastníci. Vice informací lze nalézt stránce',
          label_2: 'Stanovy',
          required: 'Toto pole musí být zaškrtnuto.',
        },

        Submit: {
          title: 'Přejít k registaci přihlašovacího účtu',
        },
      },
    },
  },

  User: {
    heading: 'Registrujete se jako správce nemovitosti {{address}}.',
    Form: {
      Fields: {
        FirstName: {
          label: 'Jméno',
          placeholder: 'Vaše jméno',
          required: 'Jméno musí být vyplněné',
        },
        LastName: {
          label: 'Příjmení',
          placeholder: 'Vaše příjmení',
          required: 'Příjmení musí být vyplněné',
        },
        Email: {
          label: 'Email',
          placeholder: 'Zadejte váš email',
        },
        Phone: {
          label: 'Telefon',
          placeholder: 'Zadejte vaše telefonní číslo, např. +420 xxx xxx xxx',
          required: 'Telefon je povinný',
        },
        Password: {
          label: 'Heslo',
          placeholder: 'Zadejte vaše heslo',
        },
        PasswordCheck: {
          label: 'Heslo znovu',
          placeholder: 'Zadejte heslo znovu',
          matches: 'Hesla se musí shodovat',
        },
        Submit: {
          title: 'Registrovat se',
        },
      },
    },
  },

  Summary: {
    heading: 'Shrnutí objednávky',
    Form: {
      title: 'Registrujete se jako správce k domu na adrese {{address}}.',
      priceSummary: 'Dům má celkem {{units}} jednotek. ',
      priceSummarySubscription: 'Cena za roční předplatné je {{priceSubscription}}. ',
      priceSummaryPoll: 'Cena za jednorázové hlasování je {{pricePoll}}. ',
      priceSummaryVAT: 'Uvedené ceny jsou s DPH.',
      fieldsTitle: 'Potřebujeme od vás pár formalit k úspěšnému dokončení registrace:',
      Fields: {
        Gdpr: {
          label: 'Beru na vědomí ',
          label_2: 'prohlášení o ochraně osobních údajů',
          required: 'K dokončení registrace od vás potřebujeme souhlas k prohlášení o ochraně osobních údajů.',
        },
        License: {
          label: 'Souhlasím s',
          label_2: 'licenční smlouvou',
          required: 'K dokončení registrace od vás potřebujeme souhlas s licenční smlouvou.',
        },
        TermsAndConditions: {
          label: 'Souhlasím s',
          label_2: 'všeobecnými obchodními podmínkami',
          required: 'K dokončení registrace od vás potřebujeme souhlas s všeobecnými obchodními podmínkami.',
        },
        Marketing: {
          label: 'Souhlasím se',
          label_2: 'zpracováním mých osobních údajů',
        },
        Submit: {
          title: 'Dokončit registraci',
        },
      },
    },
    Loading: {
      FetchingFailed: {
        headingMessage: 'Při registraci nastala chyba',
        heading: 'Nepodařilo se stáhnout data domu',
        subheading: 'Nepodařilo stáhnout data vašeho domu, prosím kontaktujte technickou podporu.',
        buttonTitle: 'Kontaktovat podporu',
        buttonAgain: 'Začít znovu',
      },
      RegistrationFailed: {
        heading: 'Nepodařilo se zaregistrovat dům',
        subheading: 'Při registraci nastala chyba, zkuste registraci znovu nebo kontaktujte naší technickou podporu.',
        buttonTitle: 'Kontaktovat podporu',
      },
      string0: '',
      string1: 'Právě pro vás připravujeme vaší budovu',
      string2: 'Shromažďujeme veškerá potřebná data',
      string3: 'Stahujeme data z registru nemovitostí',
      string4: 'Dokončujeme stahování',
    },
  },

  IncompleteData: {
    heading: 'Bohužel od vás nemáme všechny potřebné informace k registraci, začněte prosím znovu.',
    buttonTitle: 'Začít znovu',
  },
};
