import cx from 'classnames';
import { useFormik } from 'formik';

import { t, translationExist } from '../../common/i18n';
import Yup from '../../common/utils/FormValidation/index';

import { Col, Row, Text, Button, CheckboxField, Spinner, ButtonLink } from '../../common/components';

import { IncompleteData } from './IncompleteData';

import { IAdminRegistration } from './interfaces';
import { useEffect, useState } from 'react';
import { getBuildingStatus, sendFailedRegistrationEmail } from '../services/RegistrationService';
import { WEBURL } from '../../common/constants/common';
import { useAppThunkDispatch } from '../../common/hooks';
import { registerAdmin } from '../../common/store/securitySlice';
import routes from '../../common/constants/routes';
import { useNavigate } from 'react-router-dom';
import { GTM, Money } from '../../common/utils';
import { TSFixMe } from '../../frontend-libs/evlapp-types';

export const Summary: React.FC<{ state: IAdminRegistration }> = (props) => {
  const namespace = 'AdminRegistration.Summary';

  const { state } = props;

  const hasData = state?.building && state.legalEntity && state.user;
  const [isLoading, setIsLoading] = useState(true);
  const thunkDispatch = useAppThunkDispatch();
  const navigate = useNavigate();

  const [loadingStep, setLoadingStep] = useState(0);

  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState({ show: false, message: '' });
  const [isRegistrationFailed, setIsRegistrationFailed] = useState(false);
  const [summaryData, setSummaryData] = useState<TSFixMe>({});

  useEffect(() => {
    if (!isFetchingData || !hasData) return;

    getBuildingStatus(state)
      .then((res) => {
        switch (res.addressPriceInfo.status) {
          case 'exist':
            setIsLoading(false);
            setSummaryData(res);
            break;
          case 'in_process':
            setIsFetchingData(false);
            setLoadingStep((prevValue) => prevValue + 1);
            setTimeout(() => setIsFetchingData(true), 5000);
            break;

          case 'fail':
            setIsFetchingFailed({ message: '', show: true });
            break;
        }
      })
      .catch((e) => {
        let message = '';
        if (e?.violations && e.violations?.[0]) {
          message = e.violations?.[0].title;
        }

        setIsFetchingFailed({ message: message, show: true });
      });
  }, [isFetchingData, hasData, state]);

  useEffect(() => {
    if (isFetchingFailed.show === true || isRegistrationFailed) sendFailedRegistrationEmail(state);
  }, [isFetchingFailed, isRegistrationFailed, state]);

  const formik = useFormik<IAdminRegistration['agreements']>({
    initialValues: {
      gdpr: false,
      license: false,
      marketing: false,
      termsAndConditions: false,
      admin: state?.agreements?.admin || false,
    },
    onSubmit: (values) => {
      // TODO: Refactor completed data, remove legal values
      const completedData = { ...state, agreements: values, legal: values };
      thunkDispatch(registerAdmin(completedData))
        .unwrap()
        .then(() => {
          GTM.push({ 'Registration:Admin': 'complete', event: 'Registration:Admin:complete' });
          navigate(`${routes.root}`);
        })
        .catch(() => setIsRegistrationFailed(true));
    },

    validationSchema: Yup.object({
      gdpr: Yup.boolean().oneOf([true], t('Form.Fields.Gdpr.required', namespace)),
      license: Yup.boolean().oneOf([true], t('Form.Fields.License.required', namespace)),
      termsAndConditions: Yup.boolean().oneOf([true], t('Form.Fields.TermsAndConditions.required', namespace)),
    }),
  });

  if (!hasData) return <IncompleteData />;

  if (isLoading || isFetchingFailed.show || isRegistrationFailed)
    return (
      <LoadingState
        step={loadingStep}
        fetchingFailed={isFetchingFailed.show}
        fetchingFailedMessage={isFetchingFailed.message}
        registrationFailed={isRegistrationFailed}
      />
    );

  const pollPublishPrice = summaryData?.addressPriceInfo?.tokens.find(
    (item: TSFixMe) => item.tupe === 'poll-publish'
  )?.price;
  const subscriptionPrice = summaryData?.addressPriceInfo?.tokens.find(
    (item: TSFixMe) => item.tupe === 'building-subscription'
  )?.price;

  return (
    <form className="OnboardingForm" onSubmit={formik.handleSubmit}>
      <Row>
        <Col className="mb-2">
          <Text size="h1">{t('heading', namespace)}</Text>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Text size="h2" className="mb-1">
            {t('Form.title', namespace, { address: state.building.address })}
          </Text>

          <Text size="body-medium">
            {t('Form.priceSummary', namespace, {
              units: summaryData?.addressPriceInfo?.count,
            })}
            {subscriptionPrice &&
              t('Form.priceSummarySubscription', namespace, { priceSubscription: Money.format(subscriptionPrice) })}
            {pollPublishPrice && t('Form.priceSummaryPoll', namespace, { pricePoll: Money.format(pollPublishPrice) })}
            {t('Form.priceSummaryVAT', namespace)}
          </Text>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col className="mb-2">
          <Text size="body-large" className="mb-2">
            {t('Form.fieldsTitle', namespace)}
          </Text>

          <CheckboxField
            name="termsAndConditions"
            label={
              <>
                {t('Form.Fields.TermsAndConditions.label', namespace)}{' '}
                <a href={summaryData.termsAndConditionsUrl} target="_blank" rel="noreferrer">
                  {t('Form.Fields.TermsAndConditions.label_2', namespace)}
                </a>
              </>
            }
            onChange={formik.handleChange}
            error={{ touched: formik.touched.termsAndConditions, message: formik.errors.termsAndConditions }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <CheckboxField
            name="license"
            label={
              <>
                {t('Form.Fields.License.label', namespace)}{' '}
                <a href={summaryData.licenseUrl} target="_blank" rel="noreferrer">
                  {t('Form.Fields.License.label_2', namespace)}
                </a>
              </>
            }
            onChange={formik.handleChange}
            error={{ touched: formik.touched.license, message: formik.errors.license }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <CheckboxField
            name="gdpr"
            label={
              <>
                {t('Form.Fields.Gdpr.label', namespace)}{' '}
                <a href={summaryData.personalDataProtectionUrl} target="_blank" rel="noreferrer">
                  {t('Form.Fields.Gdpr.label_2', namespace)}
                </a>
              </>
            }
            onChange={formik.handleChange}
            error={{ touched: formik.touched.gdpr, message: formik.errors.gdpr }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4">
          <CheckboxField
            name="marketing"
            label={
              <>
                {t('Form.Fields.Marketing.label', namespace)}{' '}
                <a href={summaryData.gdprUrl} target="_blank" rel="noreferrer">
                  {t('Form.Fields.Marketing.label_2', namespace)}
                </a>
              </>
            }
            onChange={formik.handleChange}
            error={{ touched: formik.touched.marketing, message: formik.errors.marketing }}
          />
        </Col>
      </Row>
      <Row>
        <Col size="12">
          <Button title={t('Form.Fields.Submit.title', namespace)} type="submit" loading={formik.isSubmitting} />
        </Col>
      </Row>
    </form>
  );
};

const LoadingState: React.FC<{
  step: number;
  fetchingFailed: boolean;
  fetchingFailedMessage: string;
  registrationFailed: boolean;
}> = (props) => {
  const namespace = 'AdminRegistration.Summary';

  const { step, fetchingFailed, registrationFailed, fetchingFailedMessage } = props;

  const [stateClassName, setStateClassName] = useState('fade-out');
  const [text, setText] = useState(t(`Loading.string${step}`, namespace));

  useEffect(() => {
    if (translationExist(`${namespace}.Loading.string${step}`)) {
      const handleTransitionIn = () => {
        setStateClassName('fade-in');

        if (translationExist(`${namespace}.Loading.string${step}`)) {
          setText(t(`Loading.string${step}`, namespace));
        }
      };

      setStateClassName('fade-out');
      setTimeout(handleTransitionIn, 500);
    }
  }, [step]);

  if (fetchingFailed) {
    return (
      <div className="OnboardingForm">
        <Row>
          <Col className="FlexColumn FlexAlign--center">
            <Text size="h1" className="mb-1" align="center">
              {fetchingFailedMessage
                ? t('Loading.FetchingFailed.headingMessage', namespace)
                : t('Loading.FetchingFailed.heading', namespace)}
            </Text>
            <Text align="center" className="mb-2">
              {fetchingFailedMessage ? fetchingFailedMessage : t('Loading.FetchingFailed.subheading', namespace)}
            </Text>

            {fetchingFailedMessage ? (
              <ButtonLink
                external={true}
                to={routes.registration}
                title={t('Loading.FetchingFailed.buttonAgain', namespace)}
              />
            ) : (
              <ButtonLink
                external={true}
                to={WEBURL}
                target="_blank"
                title={t('Loading.FetchingFailed.buttonTitle', namespace)}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }

  if (registrationFailed) {
    return (
      <div className="OnboardingForm">
        <Row>
          <Col className="FlexColumn FlexAlign--center">
            <Text size="h1" className="mb-1" align="center">
              {t('Loading.RegistrationFailed.heading', namespace)}
            </Text>
            <Text align="center" className="mb-2">
              {t('Loading.RegistrationFailed.subheading', namespace)}
            </Text>
            <ButtonLink
              external={true}
              to={WEBURL}
              target="_blank"
              title={t('Loading.RegistrationFailed.buttonTitle', namespace)}
            />
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className="OnboardingForm">
      <Row>
        <Col className="FlexColumn FlexAlign--center">
          <Text as="div" size="h1" align="center" className={cx('mb-1', 'animated', stateClassName)}>
            {text}&#8203;
          </Text>
          <Spinner />
        </Col>
      </Row>
    </div>
  );
};
