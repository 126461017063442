export default {
  poll: 'Hlasování',
  feed: 'Zprávy',
  units: 'Jednotky',
  building: 'Detail domu',
  files: 'Úložiště souborů',

  BuildingSelect: {
    title: 'Vyberte dům',
    subtitle: 'Vyberte dům který chcete nastavit jako aktivní.',
  },

  Subscription: {
    inGracePeriodText: 'Předplatné pro tuto budovu vyprší',
    isExpiredText: 'Předplatné pro tuto budovu\u00a0vypršelo.',
  },
};
