import React from 'react';
import { Route, Routes } from 'react-router';

import PollList from './PollList';
import PollDetail from './Detail';
import PollQuestionDetail from './Question';
import PollResults from './Results';
import AddPoll from './Add';
import StepPollSettings from './Add/StepPollSettings';
import StepMissingContacts from './Add/StepMissingContacts';

import { Content } from '../common/components';

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route index element={<PollList />} />
      <Route path={`detail/:pollId/*`} element={<PollDetail />} />
      <Route path={`detail/:pollId/question/:questionId`} element={<PollQuestionDetail isEditable={true} />} />
      <Route path={`results/:pollId/*`} element={<PollResults />} />
      <Route path={`results/:pollId/question/:questionId`} element={<PollQuestionDetail isEditable={false} />} />
      <Route path={`add`} element={<AddPoll />} />
      <Route path={`add/poll-settings`} element={<StepPollSettings />} />
      <Route path={`add/missing-contacts`} element={<StepMissingContacts />} />

      <Route path="*" element={<Content isEmpty={true} />} />
    </Routes>
  );
};

export default Router;
