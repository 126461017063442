import { IPoll } from '../../frontend-libs/evlapp-types';
import { unifyDateFormat } from '../../common/utils/Date/Date';

interface IFilerPolls {
  publish: IPoll[];
  active: IPoll[];
  finish: IPoll[];
}

export const filterPolls = (polls: IPoll[], search?: string): IFilerPolls => {
  if (search) polls = polls.filter((i) => i.name.toLowerCase().includes(search));

  polls.forEach((item) => {
    item.startAt = unifyDateFormat(item.startAt, true);
    item.endAt = unifyDateFormat(item.endAt, true);
  });

  let publish = polls.filter((i) => i.state === 'publish');
  let active = polls.filter((i) => i.state === 'active');
  let finish = polls.filter((i) => ['finish', 'results_published'].includes(i.state));

  publish = publish.sort((a, b) => (a.startAt.getTime() > b.startAt.getTime() ? -1 : 1));
  active = active.sort((a, b) => (a.startAt.getTime() > b.startAt.getTime() ? -1 : 1));
  finish = finish.sort((a, b) => (a.endAt.getTime() > b.endAt.getTime() ? -1 : 1));

  return { publish, active, finish };
};
