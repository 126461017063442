export default {
  title: 'Úložiště souborů',
  Header: {
    title: 'Úložiště souborů',
    upload: 'Nahrát nové soubory',
  },
  Content: {
    emptyText: 'Zatím nebyly nahrány žádné soubory',
  },
  ModalUpload: {
    title: 'Nahrát nové soubory',
    subtitle: 'Na úložiště souborů můžete nahrát pouze obrázky a dokumenty.',
    submit: 'Nahrát',
    formError: 'Soubory se nepodařilo nahrát, zkuste to prosím znovu.',
    Flash: {
      success: 'Soubory byly úspěšně nahrány.',
    },

    Attachment: {
      label: 'Vybrat soubory',
      filesLabel: 'Pro dokončení nahrávání klikněte na tlačítko níže.',
    },
  },
  ModalRename: {
    title: 'Přejmenovat soubor',
    submit: 'Přejmenovat',
    Form: {
      Fields: {
        Filename: {
          label: 'Název souboru',
          placeholder: 'Zadejte nový souboru',
          required: 'Název souboru je povinný',
          max: 'Název souboru může mít maximálně {{count}} znaků.',
        },
        Submit: {
          title: 'Přejmenovat soubor',
        },
      },
    },
    Flash: {
      success: 'Soubor byl úspěšně přejmenován',
      error: 'Soubor se nepodařilo přejmenovat, zkuste to prosím znovu.',
    },
  },
  ModalDelete: {
    title: 'Opravdu chcete soubor smazat?',
    submit: 'Ano, smazat',
    Flash: {
      success: 'Soubor byl úspěšně smazán',
      error: 'Soubor se nepodařilo smazat, zkuste to prosím znovu.',
    },
  },
};
