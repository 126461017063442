import { Link } from 'react-router-dom';

import { t } from '../common/i18n';
import routes from '../common/constants/routes';
import { useAppDispatch, useAppSelector, useDocumentTitle } from '../common/hooks';
import { selectSecurity, toggleUI } from '../common/store/securitySlice';

import { CardContact, Header, Card, Icon, Text, ButtonLink, Button } from '../common/components';

const ProfileDetail: React.FC = () => {
  useDocumentTitle(t('Profile.Detail.title'));

  const dispatch = useAppDispatch();

  const ui = useAppSelector(selectSecurity).ui;
  const profile = useAppSelector(selectSecurity).profile;
  const canToggleUI = ui.isAdmin && ui.isMember;

  return (
    <>
      <Header
        title={t('Profile.Detail.Header.title')}
        customBacklink={routes.dashboard}
        actions={
          canToggleUI && (
            <Button
              title={t(`Profile.Detail.Header.Toggle.${ui.state}`)}
              size="small"
              onClick={() => dispatch(toggleUI())}
            />
          )
        }
      />
      <div className="Content">
        <CardContact
          firstName={profile?.firstName}
          lastName={profile?.lastName}
          action={
            <ButtonLink
              variant="text"
              to="/profile/edit"
              title={t('Profile.Detail.editProfile')}
              iconAfter="arrowRight"
            />
          }
        />
        {/*
        <Text size="h2" color="Neutral750" className="mt-4 mb-2">
          {t('Profile.Detail.OtherSettings.title')}
        </Text>

        <Card>
          <CheckboxField
            name="app_notifications"
            variant="switch"
            label={t('Profile.Detail.OtherSettings.appNotifications')}
            onChange={(e) => console.log(e)}
            className="Card__Switcher"
          />
          <div className="Card__divider"></div>
          <CheckboxField
            name="email_notifications"
            variant="switch"
            label={t('Profile.Detail.OtherSettings.emailNotifications')}
            onChange={(e) => console.log(e)}
            className="Card__Switcher"
          />
          <div className="Card__divider"></div>
          <CheckboxField
            name="newsletter"
            variant="switch"
            label={t('Profile.Detail.OtherSettings.agreeWithNewsletter')}
            onChange={(e) => console.log(e)}
            className="Card__Switcher"
          />
        </Card>
        */}
        <div className="Content__divider"></div>
        <Card>
          <Link to={`${routes.profile}change-password`} className="Card__link">
            <Icon name="components" color="Inherit" className="mr-2" />
            <Text size="body-medium" color="Inherit" className="mr-auto">
              {t('Profile.Detail.Links.passwordChange')}
            </Text>
            <Icon name="arrowRight" color="Inherit" />
          </Link>
          <div className="Card__divider"></div>

          {/*
          <Link to={routes.notifications} className="Card__link">
            <Icon name="notifications" color="Inherit" className="mr-2" />
            <Text size="body-medium" color="Inherit" className="mr-auto">
              {t('Profile.Detail.Links.notifications')}
            </Text>
            <Icon name="arrowRight" color="Inherit" />
          </Link>
          <div className="Card__divider"></div>
          */}

          <Link to={routes.logout} className="Card__link">
            <Icon name="logout" color="Inherit" className="mr-2" />
            <Text size="body-medium" color="Inherit" className="mr-auto">
              {t('Profile.Detail.Links.logout')}
            </Text>
            <Icon name="arrowRight" color="Inherit" />
          </Link>
        </Card>

        <div className="Content__divider"></div>
        <Text size="h3" className="mb-1">
          {t('Profile.Detail.Links.generalQuestions')}
        </Text>
        <Card className="mb-2">
          <a href="tel:+420277278000" className="Card__link">
            <Icon name="phone" color="Inherit" className="mr-2" />
            <Text size="body-medium" color="Inherit" className="mr-auto">
              +420 277 278 000 (po-pá 9 - 17hod)
            </Text>
            <Icon name="arrowRight" color="Inherit" />
          </a>
          <div className="Card__divider"></div>
          <a href="mailto:info@evlastnici.cz" className="Card__link">
            <Icon name="email" color="Inherit" className="mr-2" />
            <Text size="body-medium" color="Inherit" className="mr-auto">
              info@evlastnici.cz
            </Text>
            <Icon name="arrowRight" color="Inherit" />
          </a>
        </Card>
        <Text size="h3" className="mb-1">
          {t('Profile.Detail.Links.technicalSupport')}
        </Text>
        <Card>
          <a href="tel:+420277278001" className="Card__link">
            <Icon name="phone" color="Inherit" className="mr-2" />
            <Text size="body-medium" color="Inherit" className="mr-auto">
              +420 277 278 001
            </Text>
            <Icon name="arrowRight" color="Inherit" />
          </a>
          <div className="Card__divider"></div>
          <a href="mailto:pomoc@evlastnici.cz" className="Card__link">
            <Icon name="email" color="Inherit" className="mr-2" />
            <Text size="body-medium" color="Inherit" className="mr-auto">
              pomoc@evlastnici.cz
            </Text>
            <Icon name="arrowRight" color="Inherit" />
          </a>
        </Card>
      </div>
    </>
  );
};

export default ProfileDetail;
