import cx from 'classnames';
import React from 'react';
import { IPollQuestionAttachment } from '../../../frontend-libs/evlapp-types';

import { Icon, Text, Card } from '../index';

interface CardAttachmentsProps {
  attachments: IPollQuestionAttachment[];
  className?: string;
  AfterButton?: React.FC<IPollQuestionAttachment>;
}

export const CardAttachments: React.FC<CardAttachmentsProps> = (props) => {
  const { attachments, AfterButton } = props;

  const items = attachments.map((attachment, index) => {
    return (
      <React.Fragment key={attachment.id}>
        <div className="Card__link">
          <Text size="body-medium" color="Inherit" className="mr-auto">
            {attachment.filename}
          </Text>
          <a href={attachment.url} target="_blank" rel="noreferrer" download={true}>
            <Icon name="download" color="Inherit" />
          </a>
          {AfterButton && <AfterButton {...attachment} />}
        </div>

        {index !== attachments.length - 1 && <div className="Card__divider"></div>}
      </React.Fragment>
    );
  });

  return <Card className={cx('CardAttachments', props.className)}>{items}</Card>;
};
