import {
  IPostReqSecurityResetPasswordRequest,
  IPostReqSecurityResetPasswordReset,
  IPostResSecurityResetPasswordRequest,
  IPostResSecurityResetPasswordReset,
} from '../../frontend-libs/evlapp-types';

import { RestApi } from '../../common/services';

/**
 * @param {IPasswordReset} values - object with user email
 * @return {Promise<TSFixMe>} - TODO...
 */
export const resetPassword = async (
  values: IPostReqSecurityResetPasswordRequest
): Promise<IPostResSecurityResetPasswordRequest> => {
  return await RestApi.post<IPostReqSecurityResetPasswordRequest, IPostResSecurityResetPasswordRequest>(
    '/security/reset-password/request',
    values
  );
};

/**
 * @param {IPasswordChange} values - object with new password and authentication token
 * @return {Promise<TSFixMe>} - TODO...
 */
export const changePassword = async (
  values: IPostReqSecurityResetPasswordReset
): Promise<IPostResSecurityResetPasswordReset> => {
  return await RestApi.post<IPostReqSecurityResetPasswordReset, IPostResSecurityResetPasswordReset>(
    '/security/reset-password/reset',
    values
  );
};
