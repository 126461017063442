import { useAppSelector, useDocumentTitle } from '../common/hooks';
import routes from '../common/constants/routes';
import { t } from '../common/i18n';

import { Button, Card, Content, ContentEditableDiv, Editor, Header, Modal, Spinner, Text } from '../common/components';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { IFeed, TSFixMe } from '../frontend-libs/evlapp-types';
import { createFeed, deleteFeedById, getFeedById, updateFeed } from './services/FeedService';
import { useDispatch } from 'react-redux';
import { setFlashMessage } from '../common/store/uiSlice';
import { formatDateTime } from '../common/utils/Date/Date';
import { isDemo } from '../common/constants/common';
import { useModal } from '../common/components/Modal/useModal';
import { selectSecurity } from '../common/store/securitySlice';
import { isPaymentError } from '../common/utils';

export const FeedDetail: React.FC = () => {
  const namespace = 'Feed.Detail';
  useDocumentTitle(t('Feed.title'));

  const navigate = useNavigate();
  const { id = '' } = useParams();
  const security = useAppSelector(selectSecurity).ui;
  const isAfterGracePeriod = security.subscription.isAfterGracePeriod;

  const isAdmin = security.isAdmin;

  const [feed, setFeed] = useState<Partial<IFeed>>({ name: '', content: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);
  const [isSubmittingPublish, setIsSubmittingPublish] = useState(false);
  const [isSubmittingDelete, setIsSubmittingDelete] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isShownDelete, toggleDelete] = useModal();

  const dispatch = useDispatch();

  useEffect(() => {
    if (id === 'add') {
      setIsEditable(true);
      setIsLoading(false);
    } else {
      getFeedById(id).then((res) => {
        setFeed(res);
        setIsLoading(false);
      });
    }
  }, [id]);

  const handleSave = (type: 'publish' | 'draft') => {
    let isPublished = false;
    if (type === 'publish') isPublished = true;

    isPublished ? setIsSubmittingPublish(true) : setIsSubmittingDraft(true);

    const payload: Partial<IFeed> = { content: feed.content, name: feed.name };
    if (!feed.isPublished) payload['isPublished'] = isPublished;

    if (id === 'add') {
      createFeed(payload)
        .then(() => {
          dispatch(setFlashMessage({ message: t('Flash.Create.success', namespace), type: 'success' }));
          navigate(routes.feed);
        })
        .catch((e) => {
          if (!isPaymentError(e)) {
            dispatch(setFlashMessage({ message: t('Flash.Create.error', namespace), type: 'error' }));
          }
          setIsSubmittingPublish(false);
          setIsSubmittingDraft(false);
        });
    } else {
      updateFeed(id, payload)
        .then(() => {
          dispatch(setFlashMessage({ message: t('Flash.Update.success', namespace), type: 'success' }));
          navigate(routes.feed);
        })
        .catch((e) => {
          if (!isPaymentError(e)) {
            dispatch(setFlashMessage({ message: t('Flash.Update.error', namespace), type: 'error' }));
          }
          setIsSubmittingPublish(false);
          setIsSubmittingDraft(false);
        });
    }
  };

  const handleDelete = () => {
    if (!feed.id) return;

    setIsSubmittingDelete(true);

    deleteFeedById(feed.id)
      .then(() => {
        dispatch(setFlashMessage({ message: t('Flash.Delete.success', namespace), type: 'success' }));
        navigate(routes.feed);
      })
      .catch((e) => {
        if (!isPaymentError(e)) {
          dispatch(setFlashMessage({ message: t('Flash.Delete.error', namespace), type: 'error' }));
        }
        setIsSubmittingDelete(false);
        toggleDelete();
      });
  };

  if (isLoading) return <Spinner size="large" position="overlay" />;

  return (
    <>
      <Header
        afterTitle={
          <ContentEditableDiv
            contentEditable={isEditable}
            value={feed?.name || ''}
            onChange={(value) => setFeed((prev) => ({ ...prev, name: value }))}
            placeholder={t('Header.placeholder', namespace)}
            className="HeaderTextField"
            focusOnMount={feed.name ? false : true}
          />
        }
        secondRow={
          <div className="Flex">
            {feed.createdAt && (
              <span className="mr-2">
                <Text color="Primary500" as="span">
                  {t('Header.createdAt', namespace)}:
                </Text>{' '}
                <Text color="Neutral700" as="span">
                  {feed.createdAt && formatDateTime(feed.createdAt)}
                </Text>
              </span>
            )}

            {feed.updatedAt && feed.createdAt !== feed.updatedAt && (
              <span className="mr-2">
                <Text color="Primary500" as="span">
                  {t('Header.updatedAt', namespace)}:
                </Text>{' '}
                <Text color="Neutral700" as="span">
                  {feed.updatedAt && formatDateTime(feed.updatedAt)}
                </Text>
              </span>
            )}

            {feed.id && isAdmin && !isDemo && !isAfterGracePeriod && (
              <Text as="span" className="link" onClick={toggleDelete} color="SystemRed">
                {t('Header.delete', namespace)}
              </Text>
            )}
          </div>
        }
        customBacklink={routes.feed}
        actions={
          isAdmin &&
          (!isEditable ? (
            <>
              <Button
                title={t('Header.edit', namespace)}
                size="small"
                variant="secondary"
                onClick={() => setIsEditable(true)}
              />
              {!feed.isPublished && (
                <Button
                  title={t('Header.Actions.publish', namespace)}
                  onClick={() => handleSave('publish')}
                  disabled={feed.name?.length === 0 || isSubmittingDraft}
                  loading={isSubmittingPublish}
                  size="small"
                  disabledDeps={['demo', 'expired']}
                />
              )}
            </>
          ) : (
            <>
              {!feed.isPublished && (
                <>
                  <Button
                    title={t('Header.Actions.save', namespace)}
                    onClick={() => handleSave('draft')}
                    disabled={feed.name?.length === 0 || isSubmittingPublish}
                    loading={isSubmittingDraft}
                    size="small"
                    variant="secondary"
                    disabledDeps={['demo', 'expired']}
                  />
                </>
              )}

              <Button
                title={t(feed.isPublished ? 'Header.Actions.save' : 'Header.Actions.saveAndPublish', namespace)}
                onClick={() => handleSave('publish')}
                disabled={feed.name?.length === 0 || isSubmittingDraft}
                loading={isSubmittingPublish}
                size="small"
                disabledDeps={['demo', 'expired']}
              />
            </>
          ))
        }
      />

      <Content isLoading={isLoading}>
        <Card className="Card--content mb-3">
          <Editor
            content={feed?.content || ''}
            isEditable={isEditable}
            onChange={(e: TSFixMe) => setFeed((prev) => ({ ...prev, content: e.getHTML() }))}
          />
        </Card>
      </Content>

      <Modal isShown={isShownDelete} onHide={toggleDelete}>
        <Text size="h2" align="center" className="mb-2">
          {t('Modal.Delete.title', namespace)}
        </Text>
        <Text size="body-medium" color="Neutral700" align="center" className="mb-3">
          {t('Modal.Delete.subtitle', namespace)}
        </Text>
        <div className="Flex FlexColumn FlexAlign--center">
          <Button
            title={t('Modal.Delete.confirm', namespace)}
            wide={true}
            onClick={handleDelete}
            loading={isSubmittingDelete}
          />
        </div>
      </Modal>
    </>
  );
};
