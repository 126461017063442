import React from 'react';
import { Navigate } from 'react-router-dom';

import routes from '../common/constants/routes';
import { useAppDispatch } from '../common/hooks';
import { logout } from '../common/store/securitySlice';

export const Logout: React.FC = () => {
  const dispatch = useAppDispatch();
  dispatch(logout());

  return <Navigate to={routes.login} />;
};

export default Logout;
