import { TSFixMe } from '../../../frontend-libs/evlapp-types';
import { RestApi } from '../../../common/services';

export const getInvitationDetails = async (token: string): Promise<TSFixMe> => {
  return await RestApi.get<null, TSFixMe>(`/security/invitation/member/check/${token}`);
};

export const registerMemberWithInvitation = async (values: {
  token: string;
  email: string;
  password: string;
}): Promise<TSFixMe> => {
  return await RestApi.post<TSFixMe, TSFixMe>('security/invitation/member/register', values);
};
