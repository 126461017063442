import cx from 'classnames';
import { IPoll } from '../../../frontend-libs/evlapp-types';
import { IconNames } from '../Icon/IconNames';
import { t } from '../../i18n';
import { formatDate } from '../../utils/Date/Date';
import { Text, Icon, Card } from '../index';

interface CardPollOncomingProps extends IPoll {
  icon?: IconNames;
  name: string;
  className?: string;
}

export const CardPollOncoming: React.FC<CardPollOncomingProps> = (props) => {
  const { icon = 'horn', name, startAt, className } = props;

  return (
    <Card className={cx('CardPoll', `CardPoll--oncoming`, className)}>
      <div className="CardPoll__header">
        {icon && <Icon name={icon} className="CardPoll__icon" />}
        <Text size="h3" color="Neutral800">
          {name}
        </Text>
      </div>
      <div className="Card__divider"></div>
      <div className="CardPollDetails">
        <Text size="body-small" weight="500" color="Neutral700" className="mr-auto">
          {t('Card.Poll.Oncoming.startAt')} {formatDate(startAt)}
        </Text>
      </div>
    </Card>
  );
};
