import * as _Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { TSFixMe } from '../../../frontend-libs/evlapp-types';

import { t } from '../../i18n';
import { icoValidation } from './icoValidation';

// TODO: Add other validations:
_Yup.setLocale({
  mixed: {
    required: () => t('FormValidation.Mixed.required'),
  },
  string: { email: () => t('FormValidation.Email.format'), trim: () => t('FormValidation.String.trim') },
});

const Yup = _Yup;

export const YupEmail = Yup.string()
  .required(t('FormValidation.Email.required'))
  .email(t('FormValidation.Email.matches'));

export const YupEmailNonRequired = Yup.string().nullable().email(t('FormValidation.Email.matches'));

export const YupPassword = Yup.string()
  .required(t('FormValidation.Password.required'))
  .min(8, t('FormValidation.Password.minLength'));

export const YupPhone = Yup.string()
  .nullable()
  .required(t('FormValidation.Phone.required'))
  .test({ message: t('FormValidation.Phone.format'), test: (value) => isValidPhoneNumber(value || '') });

export const YupIco = Yup.string()
  .nullable()
  .required(t('FormValidation.Ico.required'))
  .test({ message: t('FormValidation.Ico.format'), test: (value) => icoValidation(value || '') });

export const YupPhoneNonRequired = Yup.string()
  .nullable()
  .test({
    message: t('FormValidation.Phone.format'),
    test: (value) => {
      if (!value || value.length === 0) return true;
      return isValidPhoneNumber(value || '');
    },
  });

export const YupIcoNonRequired = Yup.string()
  .nullable()
  .test({
    message: t('FormValidation.Ico.format'),
    test: (value) => {
      if (!value || value.length === 0) return true;
      return icoValidation(value || '');
    },
  });

export const YupRadioField = Yup.string().required(t('FormValidation.RadioField.required'));

export const backendViolations = (
  formik: TSFixMe,
  error: { violations: { propertyPath: string; title: string }[] }
): 'valid' | 'invalid' => {
  if (!formik || !error.violations) return 'valid';
  error.violations.forEach((field) => {
    formik
      .setFieldTouched(field.propertyPath, field.title, true)
      .then(() => formik.setFieldError(field.propertyPath, field.title));
  });

  return 'invalid';
};

export default Yup;
