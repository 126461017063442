import AdminRegistration from '../../Registration/Admin/i18n.cs';
import Building from '../../Building/i18n.cs';
import Card from '../components/Cards/i18n.cs';
import Date from '../utils/Date/i18n.cs';
import DateField from '../components/Form/DateField/i18n.cs';
import Editor from '../components/Editor/i18n.cs';
import Feed from '../../Feed/i18n.cs';
import FileField from '../components/Form/FileField/i18n.cs';
import Files from '../../FileStorage/i18n.cs';
import FormValidation from '../utils/FormValidation/i18n.cs';
import Header from '../components/Header/i18n.cs';
import Invitation from '../../Registration/Invitation/i18n.cs';
import Login from '../../Login/i18n.cs';
import PasswordField from '../components/Form/PasswordField/i18n.cs';
import PasswordReset from '../../PasswordReset/i18n.cs';
import Playground from '../../Playground/i18n.cs';
import Poll from '../../Poll/i18n.cs';
import Profile from '../../Profile/i18n.cs';
import Registration from '../../Registration/i18n.cs';
import SelectField from '../components/Form/SelectField/i18n.cs';
import Sidebar from '../components/Sidebar/i18n.cs';
import Units from '../../Units/i18n.cs';

const App = {
  title: 'eVlastníci',
  emptyState: 'Nic jsme tu nenalezli',
  demoAvailability: 'Tato funkce není dostupná v demo verzi aplikace.',
  afterGracePeriod: 'Tato funkce je dostupná pouze u placeného plánu.',
};

const Enums = {
  UnitUseType: {
    flat: 'Byt',
    studio: 'Ateliér',
    garage: 'Garáž',
    workshop: 'Provozovna/dílna',
    other: 'Jiný nebytový prostor',
    unit_under_construction: 'Rozestavěná jednotka',
    group_of_flats: 'Skupina bytů',
    group_of_non_residential: 'Skupina nebytových prostorů',
    group_of_flats_and_non_residential: 'Skupina bytů a nebytových prostorů',
  },
  UnitType: {
    flat: 'Byt',
    flat_under_construction: 'Rozestavěný byt nebo nebytový prostor',
    unit_by_law_ownership: 'Jednotka vymezená podle zákona o vlastnictví bytů',
    unit_by_civil_code: 'Jednotka vymezená podle občanského zákoníku',
  },
  ContactType: {
    committe: 'Výbor',
    controlAuthority: 'Kontrolní orgán',
    externalContact: 'Ostatní kontakty',
  },
  OwnerType: {
    missingAll: 'Chybí kontakty',
    missingPhone: 'Chybí telefon',
    missingEmail: 'Chybí email',
    missingNone: 'Vyplněné údaje',
  },
  ContactPositionsTypes: {
    chairman: 'Předseda výboru',
    deputychairman: 'Místopředseda výboru',
    member: 'Člen komise',
  },
};

export default {
  AdminRegistration,
  App,
  Building,
  Card,
  Date,
  DateField,
  Editor,
  Enums,
  Feed,
  FileField,
  Files,
  FormValidation,
  Header,
  Invitation,
  Login,
  PasswordField,
  PasswordReset,
  Playground,
  Poll,
  Profile,
  Registration,
  SelectField,
  Sidebar,
  Units,
};
