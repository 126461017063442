import cx from 'classnames';
import { IPoll } from '../../../frontend-libs/evlapp-types';
import { IconNames } from '../Icon/IconNames';
import { t } from '../../i18n';
import { Text, Icon, Card, ButtonLinkProps, ButtonLink } from '../index';
import { diffDays } from '../../utils/Date/Date';

interface CardPollCompletedProps extends IPoll {
  icon?: IconNames;
  name: string;
  className?: string;
  buttonDetailLink?: ButtonLinkProps | false;
}

export const CardPollCompleted: React.FC<CardPollCompletedProps> = (props) => {
  const { icon = 'check', name, isResultPublished, buttonDetailLink, className, endAt } = props;

  let days = 0;
  if (endAt) days = diffDays(endAt);

  return (
    <Card className={cx('CardPoll', `CardPoll--completed`, className)}>
      <div className="CardPoll__header">
        {icon && <Icon name={icon} className="CardPoll__icon" />}
        <Text size="h3" color="Neutral800">
          {name}
        </Text>
      </div>
      <div className="Card__divider"></div>
      <div className="CardPollDetails">
        <Text size="body-small" weight="500" color="Neutral700" className="mr-auto">
          {!isResultPublished
            ? t('Card.Poll.Completed.waitingForResult')
            : t(`${days >= 0 ? 'Date.daysRemains' : 'Date.daysExpired'}`, undefined, { count: Math.abs(days) })}
        </Text>

        <div className="mr-auto"></div>
        <div className="FlexColumn">
          {buttonDetailLink && <ButtonLink size="small" iconAfter="arrowRight" variant="text" {...buttonDetailLink} />}
        </div>
      </div>
    </Card>
  );
};
