export interface FieldErrorProps {
  message?: string;
  touched?: boolean;
}

export const FieldError: React.FC<FieldErrorProps> = (props) => {
  const { message, touched } = props;

  if (!message || !touched) return null;

  return (
    <div className="FieldError text-body-small">
      <span>{message}</span>
    </div>
  );
};
