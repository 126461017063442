import React from 'react';
import { Route, Routes } from 'react-router';

import ProfileEdit from './ProfileEdit';
import ProfileDetail from './ProfileDetail';
import ProfileChangePassword from './ProfileChangePassword';

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ProfileDetail />} />
      <Route path="edit" element={<ProfileEdit />} />
      <Route path="change-password" element={<ProfileChangePassword />} />
    </Routes>
  );
};

export default Router;
