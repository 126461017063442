import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { TSFixMe } from '../frontend-libs/evlapp-types';

import { t } from '../common/i18n';
import { useAppDispatch, useAppSelector, useDocumentTitle } from '../common/hooks';
import routes from '../common/constants/routes';
import {
  Button,
  Card,
  CardContact,
  Content,
  Header,
  Icon,
  Modal,
  PhoneField,
  RadioField,
  RadioFieldLabel,
  Spinner,
  Text,
  TextField,
} from '../common/components';
import {
  createNewContact,
  deleteContactById,
  getBuildingContacts,
  getContactById,
  updateContactById,
} from './services/BuildingService';
import { selectSecurity } from '../common/store/securitySlice';
import { useModal } from '../common/components/Modal/useModal';
import { getProfileName } from '../common/utils/Profile/profile';
import Yup, {
  backendViolations,
  YupEmail,
  YupIcoNonRequired,
  YupPhone,
  YupRadioField,
} from '../common/utils/FormValidation';
import { setFlashMessage } from '../common/store/uiSlice';

const getTypeByUrlSlug = (slug: string | null): string | null => {
  if (slug === 'control-authority') return 'controlAuthority';
  if (slug === 'external-contact') return 'externalContact';
  return slug;
};

const contactList = ({ data, isLoading, ...props }: TSFixMe) => {
  if (data.length === 0 || isLoading) {
    return (
      <Card className="Card--content">
        <Text as="div" className="Flex" size="body-medium" color="Neutral600" weight="400">
          {!isLoading && t('Building.Detail.emptyContactList')}
          {isLoading && (
            <>
              <Spinner size="small" className="mr-1" /> {t('Building.Detail.isLoading')}
            </>
          )}
        </Text>
      </Card>
    );
  }

  return data.map((contact: TSFixMe, i: number) => {
    const isLast = i === data.length - 1;

    let position: null | string = null;
    if (contact.committeUserType) position = t(`Enums.ContactPositionsTypes.${contact.committeUserType}`);

    const handleUpdate = () => {
      props.setSelectedContact({});
      props.setModalType('update');
      props.setSelectedContactId(contact.id);
      props.toggle();
    };

    const handleDelete = () => {
      props.setSelectedContactId(contact.id);
      props.setModalType('delete');
      props.toggle();
    };

    return (
      <CardContact
        key={contact.id}
        {...contact}
        position={position}
        beforeEnd={
          <div className="CardContactAdmin">
            <div className="CardContactAdmin__update" onClick={handleUpdate}>
              <Icon name="edit" />
            </div>
            <div className="CardContactAdmin__delete" onClick={handleDelete}>
              <Icon name="trash" />
            </div>
          </div>
        }
        className={isLast && 'mb-0'}
      />
    );
  });
};

interface IContact {
  firstName?: string;
  lastName?: string;
  company?: string;
  companyId?: string;
  email?: string;
  phone?: string;
  committeUserType?: string;
}

export const ContactDetail: React.FC = () => {
  const namespace = 'Building.ContactDetail';
  useDocumentTitle(t('title', namespace));

  const { contactType = null } = useParams();
  const type = getTypeByUrlSlug(contactType);

  const dispatch = useAppDispatch();
  const ui = useAppSelector(selectSecurity).ui;
  const isAdmin = ui.state === 'admin' ? true : undefined;

  const [isShown, toggle] = useModal();

  const [selectedContactId, setSelectedContactId] = useState<string | null>(null);
  const [selectedContact, setSelectedContact] = useState<TSFixMe>({});
  const [modalType, setModalType] = useState<'update' | 'delete'>('update');
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(true);

  const [contacts, setContacts] = useState<TSFixMe>({ data: [], isLoading: true });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleDeleteContact = () => {
    if (!selectedContactId || !contactType) return;

    deleteContactById(selectedContactId, contactType).then(() => {
      toggle();
      dispatch(setFlashMessage({ message: t('Modal.Form.Flash.successDelete', namespace), type: 'success' }));
      setContacts((prevState: TSFixMe) => {
        const data: TSFixMe[] = [];
        prevState.data.forEach((item: TSFixMe) => {
          if (item.id === selectedContactId) return;
          data.push(item);
        });
        return { ...prevState, data };
      });
    });
  };

  useEffect(() => {
    async function fetchContacts() {
      if (!contactType) return;
      const data = await getBuildingContacts(contactType);

      setContacts({ data: data, isLoading: false });
      setIsLoading(false);
    }
    fetchContacts();
  }, [contactType]);

  useEffect(() => {
    async function fetchContact() {
      if (!selectedContactId || !contactType) return;

      setModalIsLoading(true);
      const contact = await getContactById(selectedContactId, contactType);
      setSelectedContact(contact);

      setModalIsLoading(false);
    }
    fetchContact();
  }, [selectedContactId, contactType]);

  let validationSchema = Yup.object({
    email: YupEmail,
    phone: YupPhone,
  });

  if (type !== 'externalContact') {
    const generalSchema = Yup.object({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
    });

    validationSchema = validationSchema.concat(generalSchema);
  }

  if (type === 'committe') {
    const committeSchema = Yup.object({
      committeUserType: YupRadioField,
    });

    validationSchema = validationSchema.concat(committeSchema);
  }

  if (type === 'externalContact') {
    const externalContactSchema = Yup.object().shape(
      {
        firstName: Yup.string().when('company', {
          is: (val?: string) => !val,
          then: Yup.string().required(),
          otherwise: Yup.string(),
        }),

        lastName: Yup.string().when('company', {
          is: (val?: string) => !val,
          then: Yup.string().required(),
          otherwise: Yup.string(),
        }),

        company: Yup.string().when(['firstName', 'lastName'], {
          is: (val1: string, val2: string) => !val1 && !val2,
          then: Yup.string().required(),
          otherwise: Yup.string(),
        }),
        companyId: YupIcoNonRequired,
      },
      [
        ['firstName', 'lastName'],
        ['firstName', 'company'],
        ['lastName', 'company'],
      ]
    );

    validationSchema = validationSchema.concat(externalContactSchema);
  }

  const formik = useFormik<IContact>({
    enableReinitialize: true,
    initialValues: {
      firstName: selectedContact.firstName || '',
      lastName: selectedContact.lastName || '',
      company: selectedContact.company || '',
      companyId: selectedContact.companyId || '',
      email: selectedContact.email || '',
      phone: selectedContact.phone || '',
      committeUserType: selectedContact.committeUserType || '',
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!contactType) {
        dispatch(setFlashMessage({ message: '', type: 'error' }));
        return;
      }

      if (selectedContactId) {
        updateContactById(values, selectedContactId, contactType)
          .then((res) => {
            formik.setSubmitting(false);
            setSelectedContactId(null);

            setContacts((prevState: TSFixMe) => {
              const data = prevState.data.map((item: TSFixMe) => {
                if (item.id === res.id) item = res;
                return item;
              });

              return { ...prevState, data };
            });
            toggle();
            dispatch(setFlashMessage({ message: t('Modal.Form.Flash.successEdit', namespace), type: 'success' }));
          })
          .catch((error) => {
            backendViolations(formik, error);
            dispatch(setFlashMessage({ message: t('Modal.Form.Flash.errorEdit', namespace), type: 'error' }));
          })
          .finally(() => {
            formik.setSubmitting(false);
          });
      } else {
        createNewContact(values, contactType)
          .then((res) => {
            setContacts((prevState: TSFixMe) => {
              prevState.data.push(res);
              return prevState;
            });
            toggle();
            formik.resetForm();
            dispatch(setFlashMessage({ message: t('Modal.Form.Flash.successAdd', namespace), type: 'success' }));
          })
          .catch((error) => {
            backendViolations(formik, error);
            dispatch(setFlashMessage({ message: t('Modal.Form.Flash.errorAdd', namespace), type: 'error' }));
          })
          .finally(() => {
            formik.setSubmitting(false);
          });
      }
    },
  });

  return (
    <>
      <Header
        title={`${t(`Enums.ContactType.${type}`)}`}
        customBacklink={routes.building}
        actions={
          isAdmin && (
            <Button
              size="small"
              title={t('Header.primaryAction', namespace)}
              onClick={() => {
                setModalType('update');
                setSelectedContact({});
                setSelectedContactId(null);
                setModalIsLoading(false);
                toggle();
              }}
            />
          )
        }
      />
      <Content isLoading={isLoading}>
        {contactList({ ...contacts, setSelectedContactId, setModalType, toggle, setSelectedContact, setContacts })}
      </Content>

      <Modal isShown={isShown} onHide={toggle} isLoading={modalIsLoading}>
        {modalType === 'update' && (
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label={t('Modal.Form.Fields.FirstName.label', namespace)}
              placeholder={t('Modal.Form.Fields.FirstName.placeholder', namespace)}
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={{ message: formik.errors.firstName, touched: formik.touched.firstName }}
              className="mb-2"
              required={
                (type === 'externalContact' && !formik.values.company) || type !== 'externalContact' ? true : false
              }
            />
            <TextField
              label={t('Modal.Form.Fields.LastName.label', namespace)}
              placeholder={t('Modal.Form.Fields.LastName.placeholder', namespace)}
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={{ message: formik.errors.lastName, touched: formik.touched.lastName }}
              className="mb-2"
              required={
                (type === 'externalContact' && !formik.values.company) || type !== 'externalContact' ? true : false
              }
            />
            {type === 'externalContact' && (
              <>
                <TextField
                  label={t('Modal.Form.Fields.Company.label', namespace)}
                  placeholder={t('Modal.Form.Fields.Company.placeholder', namespace)}
                  name="company"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  error={{ message: formik.errors.company, touched: formik.touched.company }}
                  className="mb-2"
                  required={formik.values.firstName || formik.values.lastName ? false : true}
                />
                <TextField
                  label={t('Modal.Form.Fields.CompanyId.label', namespace)}
                  placeholder={t('Modal.Form.Fields.CompanyId.placeholder', namespace)}
                  name="companyId"
                  value={formik.values.companyId}
                  onChange={formik.handleChange}
                  error={{ message: formik.errors.companyId, touched: formik.touched.companyId }}
                  className="mb-2"
                />
              </>
            )}
            <TextField
              label={t('Modal.Form.Fields.Email.label', namespace)}
              placeholder={t('Modal.Form.Fields.Email.placeholder', namespace)}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={{ message: formik.errors.email, touched: formik.touched.email }}
              className="mb-2"
              required={true}
            />
            <PhoneField
              label={t('Modal.Form.Fields.Phone.label', namespace)}
              placeholder={t('Modal.Form.Fields.Phone.placeholder', namespace)}
              name="phone"
              value={formik.values.phone}
              onChange={(phone) => formik.setFieldValue('phone', phone === undefined ? '' : phone)}
              error={{ message: formik.errors.phone, touched: formik.touched.phone }}
              className="mb-2"
              required={true}
            />
            {type === 'committe' && (
              <>
                <RadioFieldLabel
                  label={t('Modal.Form.Fields.CommitteUserType.label', namespace)}
                  className="mt-2"
                  error={{ message: formik.errors.committeUserType, touched: formik.touched.committeUserType }}
                  required={true}
                />

                <RadioField
                  name="committeUserType"
                  value="chairman"
                  className="mb-1"
                  label={t('Modal.Form.Fields.CommitteUserType.chairman', namespace)}
                  onChange={(e) => formik.setFieldValue('committeUserType', e.target.value)}
                  checked={formik.values.committeUserType === 'chairman'}
                />

                <RadioField
                  name="committeUserType"
                  value="deputychairman"
                  className="mb-1"
                  label={t('Modal.Form.Fields.CommitteUserType.deputyChairman', namespace)}
                  onChange={(e) => formik.setFieldValue('committeUserType', e.target.value)}
                  checked={formik.values.committeUserType === 'deputychairman'}
                />

                <RadioField
                  name="committeUserType"
                  value="member"
                  className="mb-2"
                  label={t('Modal.Form.Fields.CommitteUserType.member', namespace)}
                  onChange={(e) => formik.setFieldValue('committeUserType', e.target.value)}
                  checked={formik.values.committeUserType === 'member'}
                />
              </>
            )}
            <Button
              onClick={formik.handleSubmit}
              loading={formik.isSubmitting}
              disabledDeps={['demo', 'expired']}
              title={
                selectedContactId !== null
                  ? t('Modal.Form.Fields.Submit.title', namespace)
                  : t('Modal.Form.Fields.Submit.titleCreate', namespace)
              }
            />
          </form>
        )}

        {modalType === 'delete' && (
          <>
            <Text size="h2" align="center" className="mb-3">
              {t('Modal.Delete.title', namespace)} {getProfileName(selectedContact)}?
            </Text>

            <div className="Flex FlexColumn FlexAlign--center">
              <Button
                title={t('Modal.Delete.confirm', namespace)}
                variant="primary-light"
                wide
                onClick={handleDeleteContact}
                className="mb-2"
                disabledDeps={['demo', 'expired']}
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
