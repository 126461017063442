import { TSFixMe } from '../../frontend-libs/evlapp-types';
import { useFormik } from 'formik';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  FormMessage,
  FormMessageProps,
  Header,
  Modal,
  Spinner,
  Text,
  TextField,
} from '../../common/components';
import { PhoneField } from '../../common/components/Form/PhoneField/PhoneField';
import { useModal } from '../../common/components/Modal/useModal';

import routes from '../../common/constants/routes';
import { useAppDispatch } from '../../common/hooks';
import { t } from '../../common/i18n';
import { setFlashMessage } from '../../common/store/uiSlice';
import Yup, { backendViolations, YupEmail, YupPhone } from '../../common/utils/FormValidation';
import { getProfileName } from '../../common/utils/Profile/profile';

import {
  addMissingContact,
  getMissingContacts,
  getPollDrafts,
  publishNewPoll,
  removePollDraft,
} from '../services/PollService';
import { isPaymentError } from '../../common/utils';

interface FormInterface {
  email: string;
  phone: string;
}

const CardMissingContact: React.FC<TSFixMe> = (props) => {
  const [isShown, toggle] = useModal();
  const dispatch = useAppDispatch();

  const fullName = getProfileName({ ...props });

  const [formMessage, setFormMessage] = useState<FormMessageProps>({ message: '', type: 'error' });
  const formik = useFormik<FormInterface>({
    initialValues: { phone: props?.phone || '', email: props?.email || '' },

    validationSchema: Yup.object().shape(
      {
        phone: Yup.string().when('email', {
          is: (val?: string) => !val,
          then: YupPhone,
          otherwise: Yup.string().nullable(),
        }),

        email: Yup.string().when('phone', {
          is: (val?: string) => !val,
          then: YupEmail,
          otherwise: Yup.string().nullable(),
        }),
      },
      [['phone', 'email']]
    ),
    onSubmit: (values) => {
      addMissingContact(props.id, values)
        .then(() => {
          dispatch(setFlashMessage({ message: t('Poll.MissingContacts.Form.successText'), type: 'success' }));
          props.setProfiles((prevState: TSFixMe) => {
            const profileId = props.id;
            let profileUnits: TSFixMe[] = [];

            const tempArray: TSFixMe[] = [];
            const finalProfiles: TSFixMe[] = [];

            // Find profile, set to temp variables, create new one...
            prevState.forEach((item: TSFixMe) => {
              if (item.profile.id !== profileId) return tempArray.push(item);
              profileUnits = item.units;
            });

            // Clear all profileUnits from other profiles
            tempArray.forEach((item: TSFixMe) => {
              item.units.forEach((unit: TSFixMe, i: number) => {
                profileUnits.forEach((profUnit) => {
                  if (unit.id === profUnit.id) item.units.splice(i, 1);
                });
              });
            });

            // Clear profiles with empty units
            tempArray.forEach((item: TSFixMe) => {
              if (!item.units || item.units.length === 0) return;
              finalProfiles.push(item);
            });
            return finalProfiles;
          });
        })
        .catch((error) => {
          const validations = backendViolations(formik, error);
          if (validations === 'valid') {
            setFormMessage({ message: t('Poll.MissingContacts.Form.error'), type: 'error' });
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <>
      <Card className="Card--content mb-2 Flex FlexAlign--center">
        <Text size="body-large" color="Neutral800">
          {fullName}
        </Text>
        {props.units.map((unit: TSFixMe) => {
          return (
            <div key={unit.id} className="PollMissingContacts__tag">
              <Text size="body-small" weight="400" color="Neutral700">
                {t('Units.Table.Body.unit')} {unit.label}
              </Text>
            </div>
          );
        })}

        <Button
          title={t('Poll.MissingContacts.Card.addButton')}
          iconBefore="plus"
          variant="text"
          onClick={toggle}
          className="ml-auto"
        />
      </Card>

      <Modal isShown={isShown} onHide={toggle}>
        <Text size="h2" align="center" className="mb-3">
          {t('Poll.MissingContacts.Modal.subtitle', '', { fullName })}
        </Text>
        <form onSubmit={formik.handleSubmit}>
          {formMessage.message && <FormMessage {...formMessage} variant="dark" className="mb-2" />}
          <TextField
            name="email"
            label={t('Poll.MissingContacts.Form.Fields.Email.label')}
            placeholder={t('Poll.MissingContacts.Form.Fields.Email.placeholder')}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={{ message: formik.errors.email, touched: formik.touched.email }}
            className="mb-2"
          />
          <PhoneField
            name="phone"
            label={t('Poll.MissingContacts.Form.Fields.Phone.label')}
            placeholder={t('Poll.MissingContacts.Form.Fields.Phone.placeholder')}
            value={formik.values.phone}
            onChange={(phone) => formik.setFieldValue('phone', phone)}
            error={{ message: formik.errors.phone, touched: formik.touched.phone }}
            className="mb-2"
          />

          <Button
            title={t('Poll.MissingContacts.Form.Fields.Submit.title')}
            onClick={formik.handleSubmit}
            disabledDeps={['demo', 'expired']}
            loading={formik.isSubmitting}
          />
        </form>
      </Modal>
    </>
  );
};

export const StepMissingContacts: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isShown, toggle] = useModal();
  const [draftModal, setDraftModal] = useModal();
  const [pollData, setPollData] = useState<TSFixMe>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isPublishing, setIsPublishing] = useState<boolean>(false);

  const [profiles, setProfiles] = useState<TSFixMe[]>([]);

  const handleRemovePollDraft = async () => {
    if (!pollData.id) return navigate(routes.poll);
    removePollDraft(pollData.id)
      .then(() => {
        dispatch(setFlashMessage({ message: t('Poll.MissingContacts.Flash.Remove.success'), type: 'success' }));
        navigate(routes.poll);
      })
      .catch(() => {
        dispatch(setFlashMessage({ message: t('Poll.MissingContacts.Flash.Remove.error'), type: 'error' }));
      });
  };

  const handlePublishPoll = async () => {
    setIsPublishing(true);
    publishNewPoll(pollData.id)
      .then(() => {
        dispatch(setFlashMessage({ message: t('Poll.MissingContacts.Flash.Publish.success'), type: 'success' }));
        navigate(routes.poll);
      })
      .catch((e) => {
        if (!isPaymentError(e)) {
          dispatch(setFlashMessage({ message: t('Poll.MissingContacts.Flash.Publish.error'), type: 'error' }));
        }
      })
      .finally(() => {
        setIsPublishing(false);
      });
  };

  useEffect(() => {
    async function fetch() {
      const [drafts, profiles] = await Promise.all([getPollDrafts(), getMissingContacts()]);
      if (drafts[0]) setPollData(drafts[0]);
      setProfiles(profiles);
      setIsLoading(false);
    }
    fetch();
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner size="large" position="overlay" />
      ) : (
        <>
          <Modal isShown={draftModal} onHide={setDraftModal}>
            <Text size="h2" align="center" className="mb-2">
              {t('Poll.AddPoll.Header.discardDraftModal.title')}
            </Text>
            <Text size="body-medium" color="Neutral700" align="center" className="mb-3">
              {t('Poll.AddPoll.Header.discardDraftModal.subtitle')}
            </Text>
            <div className="Flex FlexColumn FlexAlign--center">
              <Button title={t('Poll.AddPoll.Header.discardDraftModal.confirm')} onClick={handleRemovePollDraft} />
            </div>
          </Modal>
          <Header
            title={pollData.name}
            customBacklink={`${routes.poll}add/poll-settings`}
            actions={
              <Button
                size="small"
                variant="secondary"
                iconAfter="trash"
                title={t('Poll.AddPoll.Header.discardDraft')}
                onClick={setDraftModal}
              />
            }
          />

          <div className="Content">
            {profiles.length === 0 ? (
              <>
                <Text size="h2" align="center">
                  {t('Poll.MissingContacts.great')}
                </Text>
                <Text size="h3" align="center" className="mb-3">
                  {t('Poll.MissingContacts.noMissingContact')}
                </Text>
                <div className="Flex FlexJustify--center">
                  <Button title={t('Poll.MissingContacts.publishPoll')} onClick={toggle} />
                </div>
              </>
            ) : (
              <>
                <Text size="h2" className="mb-2 Flex FlexAlign--center FlexJustify--space-between">
                  {t('Poll.MissingContacts.title')}
                  <Text color="SystemRed">{t('Poll.MissingContacts.missingContactsCount', '', { profiles })}</Text>
                </Text>
                {profiles.map((x) => {
                  const { profile, units } = x;

                  return (
                    <CardMissingContact
                      {...profile}
                      units={units}
                      profiles={profiles}
                      setProfiles={setProfiles}
                      key={profile.id}
                    />
                  );
                })}
                <Button title={t('Poll.MissingContacts.publishPoll')} onClick={toggle} />
              </>
            )}

            <Modal isShown={isShown} onHide={toggle}>
              <Text size="h2" align="center" className="mb-2">
                {t('Poll.MissingContacts.Modal.title')}
              </Text>

              <Text size="body-medium" color="Neutral700" weight="500" align="center" className="mb-1">
                {t('Poll.MissingContacts.Modal.pollEditInfoText')}
              </Text>

              {profiles.length > 0 && (
                <Text size="body-small" color="Neutral750" weight="500" align="center">
                  {t('Poll.MissingContacts.Modal.infoText')}
                </Text>
              )}

              <div className="Flex FlexColumn FlexAlign--center mt-3">
                <Button
                  title={
                    profiles.length > 0
                      ? t('Poll.MissingContacts.Modal.confirm')
                      : t('Poll.MissingContacts.Modal.confirmFullfiled')
                  }
                  wide
                  onClick={handlePublishPoll}
                  loading={isPublishing}
                  disabledDeps={['demo', 'expired']}
                  className="mb-2"
                />
                {profiles.length > 0 && (
                  <Button title={t('Poll.MissingContacts.Modal.back')} variant="primary-light" wide onClick={toggle} />
                )}
              </div>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default StepMissingContacts;
