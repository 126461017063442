import { RestApi } from '../../common/services';
import {
  IPostReqSecurityAuthenticationLogin,
  IPostResSecurityAuthenticationLogin,
} from '../../frontend-libs/evlapp-types';

/**
 * @param {ILoginProfile} values - object with email and password
 * @return {Promise<ILoginProfile>} - instance of current user
 */
export const userLogin = async (
  values: IPostReqSecurityAuthenticationLogin
): Promise<IPostResSecurityAuthenticationLogin> => {
  return await RestApi.post<IPostReqSecurityAuthenticationLogin, IPostResSecurityAuthenticationLogin>(
    '/security/authentication/login',
    values
  );
};
