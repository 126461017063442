import { Content, Text } from '../common/components';

interface PageErrorProps {
  code: '404' | '500';
}

export const PageError: React.FC<PageErrorProps> = (props) => {
  if (props.code === '404') {
    return <Content isEmpty={true} className="Content--fullscreen" />;
  }

  return (
    <div className="PageError">
      <Text size="h1">{props.code} stránka nenalezena</Text>
    </div>
  );
};
