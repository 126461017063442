'use client';

import { Text } from '../Text/Text';
import { Modal } from './Modal';

import image from './ModalLockImage.jpg';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { hidePaymentModal, selectUi } from '../../store/uiSlice';
import { ButtonLink } from '../Button/ButtonLink';

export const ModalLock: React.FC = () => {
  const dispatch = useAppDispatch();
  const paymentModal = useAppSelector(selectUi).paymentModal;

  let title = 'Narazili jste na placenou funkci aplikace';
  let description = 'Tato funkce je možná pouze po zaplacení plné verze aplikace.';

  if (paymentModal?.errorType === 'pollPublish') {
    title = 'Hlasování nelze publikovat';
    description = 'Tato funkce je možná pouze po zaplacení jednorázového hlasování nebo po zakoupení předplatného.';
  }

  return (
    <Modal isShown={paymentModal?.show || false} onHide={() => dispatch(hidePaymentModal())} className="ModalLock">
      <img src={image} alt="" className="ModalLock__image mb-4" />
      <Text align="center" size="h2" color="Neutral700" className="mb-3" children={title} />
      <Text align="center" color="Neutral700" children={description} className="mb-2" />

      <ButtonLink to="/subscription" title="Objednat" onClick={() => dispatch(hidePaymentModal())} />
    </Modal>
  );
};
