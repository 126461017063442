import { useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router';

import { Button, Header, Spinner } from '../../common/components';
import routes from '../../common/constants/routes';
import { useAppSelector, useAppThunkDispatch, useDocumentTitle } from '../../common/hooks';
import { t } from '../../common/i18n';
import { getPoll, selectPoll } from '../../common/store/pollSlice';

import { TabPoll } from './TabPoll';
import { TabVoterList } from './TabVoterList';
import { TabDetail } from './TabDetail';
import { generatePollTicket, generateResults } from '../services/PollService';
import { selectSecurity } from '../../common/store/securitySlice';

export const PollResults: React.FC = () => {
  useDocumentTitle(t('Poll.title'));

  const { pollId = null } = useParams();
  const dispatch = useAppThunkDispatch();
  const poll = useAppSelector(selectPoll);
  const ui = useAppSelector(selectSecurity).ui;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const isAdmin = ui.state === 'admin' ? true : undefined;
  const canGenerateTickets = isAdmin && !poll.isFinished;
  const canGenerateResults = isAdmin && poll.isResultPublished;

  const handleGenerateTicket = () => generatePollTicket(poll.id);
  const handleGenerateResults = () => generateResults(poll.id);

  useEffect(() => {
    if (pollId === null) return;
    dispatch(getPoll(pollId))
      .unwrap()
      .then(() => setIsLoading(false));
  }, [pollId, dispatch]);

  if (isLoading) return <Spinner size="large" position="overlay" />;

  return (
    <>
      <Header
        title={poll.name || ''}
        customBacklink={`${routes.poll}`}
        tabs={[
          { label: t('Poll.Results.TabPoll.tabTitle'), to: '' },
          { label: t('Poll.Results.TabVoterList.tabTitle'), to: 'voter-list', renderCondition: 'isAdmin' },
          { label: t('Poll.Results.TabDetail.tabTitle'), to: 'detail' },
        ]}
        actions={
          <>
            {canGenerateTickets && (
              <Button
                size="small"
                title={t('Poll.Results.TabVoterList.Ticket.generateAll')}
                onClick={handleGenerateTicket}
              />
            )}
            {canGenerateResults && (
              <Button
                size="small"
                title={t('Poll.Results.TabVoterList.Ticket.generateResults')}
                onClick={handleGenerateResults}
              />
            )}
          </>
        }
      />

      <div className="Content">
        <Routes>
          <Route index element={<TabPoll />} />
          <Route path="voter-list" element={<TabVoterList />} />
          <Route path="detail" element={<TabDetail />} />
        </Routes>
      </div>
    </>
  );
};

export default PollResults;
