import cx from 'classnames';

interface CardProps {
  className?: string;
  onClick?: () => void;
}

export const Card: React.FC<CardProps> = (props) => {
  return (
    <div className={cx('Card', props.className)} onClick={props.onClick}>
      {props.children}
    </div>
  );
};
