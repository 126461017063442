import React, { FormEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import cx from 'classnames';

import { t } from '../common/i18n';
import { useAppSelector, useAppThunkDispatch, useDocumentTitle, useAppDispatch } from '../common/hooks';
import {
  getProfile,
  selectSecurity,
  updateProfile,
  updateProfileProtectedRequest,
} from '../common/store/securitySlice';

import {
  Card,
  Header,
  TextField,
  DateField,
  Avatar,
  Button,
  Text,
  Modal,
  Tooltip,
  FormMessage,
} from '../common/components';
import { getInitials } from '../common/utils/Profile/profile';
import { PhoneField } from '../common/components/Form/PhoneField/PhoneField';
import { setFlashMessage } from '../common/store/uiSlice';
import Yup, { backendViolations, YupEmail, YupPhone } from '../common/utils/FormValidation';
import { IPutReqUserProfile, TSFixMe } from '../frontend-libs/evlapp-types';
import { useModal } from '../common/components/Modal/useModal';
import { isDemo } from '../common/constants/common';
import { Link } from 'react-router-dom';
import { confirmVerificationCode } from './services/ProfileService';

const ProfileEdit: React.FC = () => {
  useDocumentTitle(t('Profile.Edit.title'));

  const data = useAppSelector(selectSecurity).profile;
  const security = useAppSelector(selectSecurity).ui;
  const isAfterGracePeriod = security.subscription.isAfterGracePeriod;

  const initials = getInitials(data?.firstName, data?.lastName);
  const [isShownModal, setIsShownModal] = useModal();
  const [token, setToken] = useState<string>('');
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [badVerificationCode, setBadVerificationCode] = useState<boolean>(false);

  const [countdown, setCountdown] = useState(0);
  const [code, setCode] = useState<string>('');
  const isDisabled = code.length !== 4 || isDemo || isAfterGracePeriod;

  const refs: TSFixMe[] = [];
  refs.push(useRef<HTMLInputElement>(null));
  refs.push(useRef<HTMLInputElement>(null));
  refs.push(useRef<HTMLInputElement>(null));
  refs.push(useRef<HTMLInputElement>(null));

  useEffect(() => {
    const timer = setTimeout(() => {
      if (countdown > 0) setCountdown(countdown - 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [countdown]);

  const handeBackspace = (e: KeyboardEvent<HTMLInputElement>, position: number) => {
    if (e.code === 'Backspace' && refs[position - 1]?.current) {
      refs[position - 1].current.value = '';
      refs[position - 1].current.focus();
    }

    let code = '';
    refs.forEach((ref) => {
      if (ref.current && ref.current.value) code += ref.current.value;
    });

    setCode(code);
  };

  const handleCode = (e: FormEvent<HTMLInputElement>, position: number) => {
    const value = e.currentTarget.value;

    const arr = value.split('');
    if (value.length > 1) {
      arr.forEach((char, index) => {
        if (refs.length <= index + position) return;

        refs[index + position].current.value = char;
        if (refs[index + position + 1]?.current) refs[index + position + 1].current.focus();
      });
    } else if (value.length === 1) {
      if (refs[position]?.current) refs[position].current.value = value[0];
      if (refs[position + 1]?.current) refs[position + 1].current.focus();
    }

    let code = '';
    refs.forEach((ref) => {
      if (ref.current && ref.current.value) code += ref.current.value;
    });

    setCode(code);
  };

  const handleRequestCode = (values: IPutReqUserProfile) => {
    if (isDemo || isAfterGracePeriod) return;

    thunkDispatch(updateProfileProtectedRequest(values))
      .unwrap()
      .then((res) => {
        setCountdown(30);
        setToken(res.token);
        if (!isShownModal) setIsShownModal();
      })
      .catch((error) => {
        const validations = backendViolations(formik, error);

        if (validations === 'valid') {
          dispatch(setFlashMessage({ message: t('Profile.Edit.Form.Flash.error'), type: 'error' }));
        }
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const handleVerificationCode = (code: string) => {
    if (isDemo || isAfterGracePeriod) return false;

    setIsButtonLoading(true);
    confirmVerificationCode({ token, code })
      .then(() => {
        thunkDispatch(getProfile()).finally(() => {
          dispatch(setFlashMessage({ message: t('Profile.Edit.Form.Flash.success'), type: 'success' }));
        });
      })
      .catch(() => {
        setIsButtonLoading(false);
        setBadVerificationCode(true);
      });
  };

  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();

  const formik = useFormik<IPutReqUserProfile>({
    initialValues: {
      phone: data?.phone || '',
      email: data?.email || '',
      birthDate: data?.birthDate,
    },

    validationSchema: Yup.object({
      phone: YupPhone,
      email: YupEmail,
    }),

    onSubmit: (values) => {
      if (data && data.phone) {
        handleRequestCode(values);
      } else {
        thunkDispatch(updateProfile(values))
          .unwrap()
          .then(() => {
            dispatch(setFlashMessage({ message: t('Profile.Edit.Form.Flash.success'), type: 'success' }));
          })
          .catch((error) => {
            const validations = backendViolations(formik, error);

            if (validations === 'valid') {
              dispatch(setFlashMessage({ message: t('Profile.Edit.Form.Flash.error'), type: 'error' }));
            }
          })
          .finally(() => {
            formik.setSubmitting(false);
          });
      }
    },
  });

  return (
    <>
      <Header title={t('Profile.Edit.Header.title')} backlink={-1} />
      <div className="Content">
        <Card className="Card--content">
          <form onSubmit={formik.handleSubmit}>
            {initials && (
              <div className="profileEditAvatar">
                <Avatar initials={initials} size="large" className="mr-3" />

                <Text size="body-medium" color="Primary500">
                  {data?.firstName} {data?.lastName}
                </Text>
              </div>
            )}
            <PhoneField
              name="phone"
              label={t('Profile.Edit.Form.Fields.Phone.label')}
              placeholder={t('Profile.Edit.Form.Fields.Phone.placeholder')}
              value={formik.values.phone}
              onChange={(phone) => formik.setFieldValue('phone', phone)}
              error={{ message: formik.errors.phone, touched: formik.touched.phone }}
              className="mb-2"
              required
            />
            <TextField
              type="email"
              name="email"
              label={t('Profile.Edit.Form.Fields.Email.label')}
              placeholder={t('Profile.Edit.Form.Fields.Email.placeholder')}
              value={formik.values.email}
              error={{ message: formik.errors.email, touched: formik.touched.email }}
              onChange={formik.handleChange}
              className="mb-2"
              required
            />
            <DateField
              name="birthday"
              label={t('Profile.Edit.Form.Fields.Birthday.label')}
              placeholder={t('Profile.Edit.Form.Fields.Birthday.placeholder')}
              value={formik.values.birthDate}
              onChange={(value) => formik.setFieldValue('birthDate', value)}
              error={{ message: formik.errors.birthDate, touched: formik.touched.birthDate }}
              className="mb-2"
            />
            <Button
              onClick={formik.handleSubmit}
              loading={formik.isSubmitting}
              disabledDeps={['demo', 'expired']}
              title={t('Profile.Edit.Form.Fields.Submit.title')}
            />
          </form>
        </Card>
      </div>
      <Modal isShown={isShownModal} onHide={setIsShownModal}>
        <>
          <Text size="h2" color="Neutral800" align="center" className="mb-1">
            {t('Profile.Edit.Verification.title')}
          </Text>

          <Text size="body-medium" color="Neutral700" weight="500" align="center" className="mb-2">
            {t('Profile.Edit.Verification.subtitle')}
          </Text>

          {badVerificationCode && (
            <FormMessage message={'Byl zadán špatný kód'} type={'error'} variant="dark" className="mb-2" />
          )}

          <div className="Modal__verification mb-2">
            <input
              type="text"
              ref={refs[0]}
              onChange={(e) => handleCode(e, 0)}
              onKeyUp={(e) => handeBackspace(e, 0)}
              className="TextField__control"
              placeholder="0"
            />
            <input
              type="text"
              ref={refs[1]}
              onChange={(e) => handleCode(e, 1)}
              onKeyUp={(e) => handeBackspace(e, 1)}
              className="TextField__control"
              placeholder="0"
            />
            <input
              type="text"
              ref={refs[2]}
              onChange={(e) => handleCode(e, 2)}
              onKeyUp={(e) => handeBackspace(e, 2)}
              className="TextField__control"
              placeholder="0"
            />
            <input
              type="text"
              ref={refs[3]}
              onChange={(e) => handleCode(e, 3)}
              onKeyUp={(e) => handeBackspace(e, 3)}
              className="TextField__control"
              placeholder="0"
            />
          </div>

          <Text size="body-medium" color="Neutral500" weight="500" align="center">
            {t('Profile.Edit.Verification.smsIsMissing')}
          </Text>
          <div className="text--center">
            <Tooltip text={(isDemo && t('App.demoAvailability')) || (isAfterGracePeriod && t('App.afterGracePeriod'))}>
              <Text
                size="body-medium"
                onClick={countdown === 0 ? () => handleRequestCode(formik.values) : undefined}
                className="text--center mb-3"
              >
                <Link to="" className={cx('text', countdown === 0 ? 'Primary500' : 'Neutral500 not-clickable')}>
                  {countdown === 0 && t('Profile.Edit.Verification.smsSendAgain')}
                  {countdown > 0 && (
                    <>
                      {t('Profile.Edit.Verification.smsSendAgainTimer')} {countdown}{' '}
                      {t('Date.waitSeconds', undefined, {
                        count: countdown,
                      })}
                    </>
                  )}
                </Link>
              </Text>
            </Tooltip>
          </div>
          <div className="Flex FlexAlign--center FlexJustify--center">
            <div>
              <Button
                variant={isDisabled ? 'primary-light' : 'primary'}
                disabled={isDisabled}
                wide={true}
                disabledDeps={['demo', 'expired']}
                title={t('Profile.Edit.Verification.confirmButton')}
                onClick={() => handleVerificationCode(code)}
                loading={isButtonLoading}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default ProfileEdit;
