import { TSFixMe } from '../../../frontend-libs/evlapp-types';
import cx from 'classnames';

interface PollChartProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  className?: string;

  yes: number;
  no: number;
  undecided: number;
}

const getOffset = (arr: TSFixMe[]) => {
  let offset = 0;

  arr = arr.sort((a, b) => a.value - b.value);
  arr.forEach((item) => {
    offset += item.value;
    item.value = offset;
  });

  return arr.sort((a, b) => b.value - a.value);
};

export const PollChart: React.FC<PollChartProps> = (props) => {
  let values = [
    { type: 'yes', value: props.yes },
    { type: 'no', value: props.no },
    { type: 'undecided', value: props.undecided },
  ].sort((a, b) => b.value - a.value);

  values = getOffset(values);

  return (
    <div className={cx('PollChart', props.className)}>
      <svg viewBox="0 0 32 32">
        <circle className="PollChart__background"></circle>
        {values.map((circle) => {
          return (
            <circle
              key={circle.type}
              className={cx('PollChart__value', `PollChart__value--${circle.type}`)}
              strokeDasharray={`${circle.value} 100`}
            ></circle>
          );
        })}
      </svg>
    </div>
  );
};
