import { IPollQuestionAttachment, TSFixMe } from '../../frontend-libs/evlapp-types';
import { unifyDateFormat } from '../../common/utils/Date/Date';

export interface File extends IPollQuestionAttachment {
  createdAt: string;
}

export const sortByDate = (items: File[]): File[] => {
  return items.sort((item) => new Date(item.createdAt).getTime() - new Date(item.createdAt).getTime());
};

export const sortByYearMonth = (items: TSFixMe): TSFixMe => {
  const result: TSFixMe = {};

  items = sortByDate(items).reverse();

  items.forEach((item: TSFixMe) => {
    const date = unifyDateFormat(item.createdAt);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    if (!result[`${year}-${month}`]) {
      result[`${year}-${month}`] = [];
    }

    result[`${year}-${month}`].push(item);
  });

  return result;
};
