import React from 'react';
import { Route, Routes } from 'react-router';

import { FeedDefault } from './FeedDefault';
import { FeedDetail } from './FeedDetail';

import { Content } from '../common/components';

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route index element={<FeedDefault />} />
      <Route path=":id" element={<FeedDetail />} />
      <Route path="*" element={<Content isEmpty={true} />} />
    </Routes>
  );
};

export default Router;
