import { TSFixMe } from '../../../../frontend-libs/evlapp-types';
import { useEffect, useRef } from 'react';

interface ContentEditableDivProps {
  contentEditable?: boolean;
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string;
  className?: string;
  focusOnMount?: boolean;
}

export const ContentEditableDiv: React.FC<ContentEditableDivProps> = (props) => {
  const { value, onChange, contentEditable = true, focusOnMount = true, ...rest } = props;

  const element = useRef<HTMLDivElement>(null);
  const state = useRef({ value, prevValue: null, key: null });

  if (state.current && state.current.prevValue !== value) {
    state.current.value = value;
    state.current.key = Date.now() as TSFixMe;
  }

  const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
    const element = e.target as HTMLDivElement;
    const value = element.innerText;
    state.current.prevValue = value as TSFixMe;
    onChange(value);
  };

  useEffect(() => {
    if (element.current && focusOnMount) element.current.focus();
  }, [element, focusOnMount]);

  return (
    <div
      {...rest}
      ref={element}
      key={state.current.key}
      className={props.className}
      placeholder={props.placeholder}
      contentEditable={contentEditable}
      dangerouslySetInnerHTML={{ __html: state.current.value || '' }}
      onKeyPress={(e) => {
        if (e.key.toLowerCase() === 'enter') e.preventDefault();
        return;
      }}
      onInput={handleInput}
    />
  );
};
