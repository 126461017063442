export default {
  Contact: {
    emptyFullName: 'Jméno není vyplněno',
  },
  Feed: {
    event: 'Událost',
    message: 'Zpráva',
    survey: 'Anketa',
    concept: 'Koncept',
    published: 'Publikováno',
  },
  Poll: {
    Active: {
      quorateText: 'Probíhající hlasování',
    },
    Completed: {
      waitingForResult: 'Zatím nevyhodnocené hlasování',
    },
    Oncoming: {
      startAt: 'Hlasování začíná',
    },
  },
  Question: {
    detailLink: 'Detail otázky',
    yes: 'Ano',
    no: 'Ne',
    undecided: 'Zdržím se',
  },
  QuestionResults: {
    yes: 'Ano',
    no: 'Ne',
    undecided: 'Zdrželo se',
    noVotes: 'Nehlasovalo',
    Decision: {
      yes: 'Usnesení přijato',
      no: 'Usnesení nepřijato',
    },
  },
};
