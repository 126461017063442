import cx from 'classnames';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';

import { Avatar } from '../Avatar/Avatar';
import { Icon } from '../Icon/Icon';
import { IconNames } from '../Icon/IconNames';
import { Text } from '../Text/Text';

import Logo from '../../../assets/images/logo.svg';
import routes from '../../constants/routes';
import { useAppSelector } from '../../hooks';
import { t } from '../../i18n';
import { selectSecurity } from '../../store/securitySlice';
import { formatDate } from '../../utils/Date/Date';
import { getInitials } from '../../utils/Profile/profile';
import { Hamburger } from '../Hamburger/Hamburger';
import { Modal } from '../Modal/Modal';
import { useModal } from '../Modal/useModal';
import { SidebarBuildingList } from './SidebarBuildingList';
import { SidebarSubscription } from './SidebarSubscription';

interface SidebarLinkProps {
  to: LinkProps['to'];
  icon: IconNames;
  iconSelected: IconNames;
  title: string;
  renderCondition?: 'isAdmin' | 'development';
}

const items: SidebarLinkProps[] = [
  {
    to: routes.poll,
    icon: 'poll',
    iconSelected: 'pollSelected',
    title: t('Sidebar.poll'),
  },
  {
    to: routes.feed,
    icon: 'feed',
    iconSelected: 'feedSelected',
    title: t('Sidebar.feed'),
  },
  {
    to: routes.units,
    icon: 'unitsDefault',
    iconSelected: 'unitsSelected',
    title: t('Sidebar.units'),
    renderCondition: 'isAdmin',
  },
  {
    to: routes.building,
    icon: 'buildingDefault',
    iconSelected: 'buildingSelected',
    title: t('Sidebar.building'),
  },
  {
    to: routes.files,
    icon: 'filesDefault',
    iconSelected: 'filesSelected',
    title: t('Sidebar.files'),
  },
  {
    to: routes.playground,
    icon: 'flag',
    iconSelected: 'flag',
    title: 'Playground',
    renderCondition: 'development',
  },
];

const handleSidebarLinkClick = () => {
  document.body.classList.remove('sidebar--is-opened');
};

const SidebarLink: React.FC<SidebarLinkProps> = (props) => {
  const { renderCondition } = props;
  const resolved = useResolvedPath(props.to);
  const match = useMatch({ path: `${resolved.pathname}/*` });

  const security = useAppSelector(selectSecurity).ui;
  const isAdmin = security.isAdmin;

  if (renderCondition === 'development' && process.env?.NODE_ENV !== 'development') return null;
  if (renderCondition === 'isAdmin' && !isAdmin) return null;

  return (
    <Link
      to={props.to}
      className={cx('SidebarNav__item', 'text-body-medium', match && 'SidebarNav__item--active')}
      onClick={handleSidebarLinkClick}
    >
      <Icon className="SidebarNav__item-icon SidebarNav__item-icon--inactive" name={props.icon}></Icon>
      <Icon className="SidebarNav__item-icon SidebarNav__item-icon--active" name={props.iconSelected}></Icon>
      <span className="SidebarNav__item-link">{props.title}</span>
    </Link>
  );
};

const NavigationItems = items.map((item) => <SidebarLink key={item.title} {...item} />);

export const Sidebar = (): JSX.Element => {
  const [isShown, toggle] = useModal();

  const profile = useAppSelector(selectSecurity).profile;
  const profileInitials = getInitials(profile?.firstName, profile?.lastName);
  const ui = useAppSelector(selectSecurity).ui;
  const buildings = useAppSelector(selectSecurity).buildings;

  const hasSubscription = ui.tokens?.filter((item) => item.type === 'building-subscription').length || 0;
  const hasPollVotes = ui.tokens?.filter((item) => item.type === 'poll-publish').length || 0;

  const showSubscriptionWarning = ui.subscription.isExpired || ui.subscription.inGracePeriod;

  return (
    <div className="Sidebar">
      <div className="Sidebar__inner">
        <div className="SidebarHeader">
          <Link className="SidebarHeader__logo" to={routes.root} onClick={handleSidebarLinkClick}>
            <img src={Logo} alt={t('App.title')} />
          </Link>
          <Hamburger action={'close'} variant="light" />
          {/*
        <Link to={routes.notifications} className="SidebarHeader__notifications">
          <Icon name="notifications" />
        </Link>
        */}
        </div>
        <div className="SidebarUnit">
          <Text as="div" className="Flex FlexAlign--center" size="h3" color="Neutral100">
            <span className="SidebarUnit__address">{ui.address}</span>
            {buildings.length > 1 && (
              <div className="SidebarUnit__switcher" onClick={toggle}>
                <Icon name="arrowDown" />
              </div>
            )}
          </Text>
        </div>
        <div className="SidebarNav">{NavigationItems}</div>
        {showSubscriptionWarning && (
          <div className="SidebarSubscription">
            <Text color="Primary500" size="body-small">
              {ui.subscription.isAfterGracePeriod && t('Sidebar.Subscription.isExpiredText')}
              {ui.subscription.inGracePeriod &&
                `${t('Sidebar.Subscription.inGracePeriodText')} ${formatDate(ui.subscription.gracePeriodEndsAt)}.`}
            </Text>
          </div>
        )}

        {ui.isAdmin && !hasSubscription && <SidebarSubscription type={hasPollVotes ? 'warning' : 'error'} />}

        <div className={cx('SidebarUser', showSubscriptionWarning && 'SidebarUser--with-warning')}>
          <Link to={routes.profile} className="SidebarUser__wrapper" onClick={handleSidebarLinkClick}>
            <Avatar initials={profileInitials} />

            <div className="SidebarUser__info ml-1">
              <Text as="div" size="body-small" weight="600" color="Neutral100">
                {profile?.firstName} {profile?.lastName}
              </Text>
              <Text as="div" size="body-small" weight="500" color="Neutral600">
                {profile?.email}
              </Text>
            </div>
          </Link>
        </div>

        <Modal isShown={isShown} onHide={toggle} size="small">
          <Text size="h2" align="center" className="mb-2">
            {t('Sidebar.BuildingSelect.title')}
          </Text>
          <Text size="body-medium" color="Neutral700" align="center" className="mb-3">
            {t('Sidebar.BuildingSelect.subtitle')}
          </Text>
          <SidebarBuildingList buildings={buildings} onHide={toggle} />
        </Modal>
      </div>
    </div>
  );
};
