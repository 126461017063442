import { Route, Routes, useLocation } from 'react-router-dom';
import { useDocumentTitle } from '../../common/hooks';
import { t } from '../../common/i18n';
import { PageError } from '../../System/PageError';

import { BuildingSearch } from './01BuildingSearch';
import { BuildingConfirm } from './02BuildingConfirm';
import { LegalEntity } from './03LegalEntity';
import { User } from './04User';
import { Summary } from './05Summary';

export const AdminRegistrationRouter: React.FC = (props) => {
  useDocumentTitle(t('AdminRegistration.title'));
  const location = useLocation();

  return (
    <Routes>
      <Route index element={<BuildingSearch />} />
      <Route path="building-confirm" element={<BuildingConfirm state={location.state} />} />
      <Route path="legal-entity" element={<LegalEntity state={location.state} />} />
      <Route path="user" element={<User state={location.state} />} />
      <Route path="summary" element={<Summary state={location.state} />} />

      <Route path="*" element={<PageError code="404" />} />
    </Routes>
  );
};

export default AdminRegistrationRouter;
