import { configureStore } from '@reduxjs/toolkit';

import pollSlice from './pollSlice';
import securitySlice from './securitySlice';
import uiSlice from './uiSlice';

export const store = configureStore({
  reducer: {
    poll: pollSlice,
    security: securitySlice,
    ui: uiSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
