import { useEffect, useState } from 'react';
import { isDemo } from '../../common/constants/common';
import { useAppSelector } from '../../common/hooks';

import { selectSecurity } from '../../common/store/securitySlice';
import { confirmVerificationCode, requestVerificationCode } from '../services/PollService';
import { Step1and2 } from './step1and2';
import { Step3 } from './step3';
import { Step4 } from './step4';
import { Step5 } from './step5';

interface QuestionSendModalProps {
  stepNumber: number;
  pollId: string;
}

export const QuestionSendModal: React.FC<QuestionSendModalProps> = (props) => {
  const user = useAppSelector(selectSecurity).profile;
  const security = useAppSelector(selectSecurity).ui;
  const isAfterGracePeriod = security.subscription.isAfterGracePeriod;
  const [token, setToken] = useState<string>('');
  const [step, setStep] = useState<number>(props.stepNumber);

  const [countdown, setCountdown] = useState(0);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [badVerificationCode, setBadVerificationCode] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (countdown > 0) setCountdown(countdown - 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleRequestCode = () => {
    if (isDemo || isAfterGracePeriod) return setStep(4);
    if (step !== 4) {
      setStep(4);
      setCountdown(30);
    } else {
      setBadVerificationCode(false);
      setCountdown(30);
    }

    requestVerificationCode(props.pollId).then((res) => {
      setToken(res.token);
      setIsButtonLoading(false);
      if (step !== 4) setStep(4);
    });
  };

  const handleVerificationCode = (code: string) => {
    if (isDemo || isAfterGracePeriod) return setStep(5);
    setIsButtonLoading(true);
    confirmVerificationCode({ token, code }, props.pollId)
      .then(() => {
        setIsButtonLoading(false);
        setStep(5);
      })
      .catch(() => {
        setBadVerificationCode(true);
        setIsButtonLoading(false);
      });
  };

  const step1 = (
    <Step1and2
      phone={user?.phone}
      step={step}
      handleRequestCode={user?.phone ? () => handleRequestCode() : () => setStep(3)}
      isButtonLoading={isButtonLoading}
    />
  );
  const step3 = <Step3 handleRequestCode={handleRequestCode} setStep={setStep} />;

  const step4 = (
    <Step4
      phone={user?.phone}
      handleRequestCode={handleRequestCode}
      handleVerificationCode={handleVerificationCode}
      isButtonLoading={isButtonLoading}
      badVerificationCode={badVerificationCode}
      countdown={countdown}
    />
  );
  const step5 = <Step5 />;

  let visiblePart;
  switch (step) {
    case 1:
      visiblePart = step1;
      break;
    case 2:
      visiblePart = step1;
      break;
    case 3:
      visiblePart = step3;
      break;
    case 4:
      visiblePart = step4;
      break;
    case 5:
      visiblePart = step5;
      break;
  }

  return <>{visiblePart}</>;
};

export default QuestionSendModal;
