import { RestApi } from '../../common/services';
import { TSFixMe } from '../../frontend-libs/evlapp-types';

import { store } from '../../common/store/store';

/**
 * @return {Promise<TSFixMe>} array with polls
 */
export const getBuildingInfo = async (): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.get<never, TSFixMe>(`/building/${buildingId}`);
};

export const checkBuildingStatus = async (): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;

  return await RestApi.get<never, TSFixMe>(`/building/${buildingId}/state`);
};

/**
 * @return {Promise<TSFixMe>} array with polls
 */
export const getBuildingCommitte = async (): Promise<TSFixMe[]> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.get<never, TSFixMe>(`/building/${buildingId}/committe`);
};

/**
 * @return {Promise<TSFixMe>} array with polls
 */
export const getBuildingControlAuthority = async (): Promise<TSFixMe[]> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.get<never, TSFixMe>(`/building/${buildingId}/control-authority`);
};

/**
 * @return {Promise<TSFixMe>} array with polls
 */
export const getBuildingExternalContacts = async (): Promise<TSFixMe[]> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.get<never, TSFixMe>(`/building/${buildingId}/external-contact`);
};

export const getBuildingContacts = async (type: string): Promise<TSFixMe[]> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.get<never, TSFixMe>(`/building/${buildingId}/${type}`);
};

export const createNewContact = async (values: TSFixMe, type: string): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.post<TSFixMe, TSFixMe>(`/building/${buildingId}/${type}`, values);
};

export const getContactById = async (id: string, type: string): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.get<never, TSFixMe>(`/building/${buildingId}/${type}/${id}`);
};

export const updateContactById = async (values: TSFixMe, id: string, type: string): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.patch<TSFixMe, TSFixMe>(`/building/${buildingId}/${type}/${id}`, values);
};

export const deleteContactById = async (id: string, type: string): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.delete<TSFixMe>(`/building/${buildingId}/${type}/${id}`);
};

export const getBuildingSubscriptionsTokens = async (): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;

  return await RestApi.get<never, TSFixMe>(`/building/${buildingId}/order-request/tokens`);
};

export const orderSubscription = async (values: TSFixMe): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;

  return await RestApi.post<TSFixMe, TSFixMe>(`/building/${buildingId}/order-request`, { ...values });
};
