import { useNavigate } from 'react-router-dom';
import { Text, Button } from '../../common/components';
import routes from '../../common/constants/routes';

import { t } from '../../common/i18n';

export const Step5: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Text size="h2" color="Neutral800" className="mb-1" align="center">
        {t('Poll.Question.Send.thankYouHeader')}
      </Text>
      <Text size="body-medium" color="Neutral700" weight="500" align="center" className="mb-3">
        {t('Poll.Question.Send.thankYouText')}
      </Text>
      <div className="Flex FlexAlign--center FlexJustify--center">
        <Button
          variant="primary"
          wide={true}
          title={t('Poll.Question.Send.thankYouButton')}
          onClick={() => navigate(routes.dashboard)}
        />
      </div>
    </>
  );
};
