import cx from 'classnames';
import { ReactElement, useState } from 'react';
import { IPollQuestionImage } from '../../../frontend-libs/evlapp-types';

import { Spinner } from '../Spinner/Spinner';

interface ImageProps extends IPollQuestionImage {
  ratio: 'standard' | 'widescreen';
  onHover?: ReactElement;
  overlayLoading?: boolean;
}

export const Image: React.FC<ImageProps> = (props) => {
  const [loading, setLoading] = useState(true);

  const isLoading = loading || props.overlayLoading;

  return (
    <div className={cx('Image', `Image--${props.ratio}`, `Image--${loading ? 'loading' : 'loaded'}`)} key={props.url}>
      <img onLoad={() => setLoading(false)} src={props.thumbnail} alt="" />

      {!isLoading && props.onHover && <div className="Image__hover">{props.onHover}</div>}

      {isLoading && (
        <div className={cx('Image__loader', props.overlayLoading && 'Image__loader--overlay')}>
          <Spinner position="overlay" />
        </div>
      )}
    </div>
  );
};
