import React from 'react';
import { Route, Routes } from 'react-router';
import { Content } from '../common/components';

import { BuildingDetail } from './BuildingDetail';
import { ContactDetail } from './ContactDetail';

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route index element={<BuildingDetail />} />
      <Route path="/contacts/:contactType" element={<ContactDetail />} />
      <Route path="*" element={<Content isEmpty={true} />} />
    </Routes>
  );
};

export default Router;
