import { TSFixMe } from '../../frontend-libs/evlapp-types';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Content, DateField, Header, Modal, Text } from '../../common/components';
import { useModal } from '../../common/components/Modal/useModal';
import { POLL_END_MIN_DAYS, POLL_PUBLIC_MIN_DAYS } from '../../common/constants/common';
import routes from '../../common/constants/routes';
import { useAppDispatch } from '../../common/hooks';
import { t } from '../../common/i18n';
import { setFlashMessage } from '../../common/store/uiSlice';
import { addDays, diffDays, formatDate } from '../../common/utils/Date/Date';
import { backendViolations } from '../../common/utils/FormValidation';
import { getPollDrafts, removePollDraft, updateNewPoll } from '../services/PollService';

const today = new Date();

export const StepPollSettings: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pollData, setPollData] = useState<TSFixMe>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [draftModal, setDraftModal] = useModal();

  const [minDates, setMinDates] = useState<TSFixMe>({
    endAt: formatDate(addDays(today, POLL_END_MIN_DAYS), true),
    publicAt: formatDate(addDays(today, POLL_END_MIN_DAYS + POLL_PUBLIC_MIN_DAYS), true),
  });

  const handleRemovePollDraft = async () => {
    if (!pollData.id) return navigate(routes.poll);
    removePollDraft(pollData.id)
      .then(() => {
        dispatch(setFlashMessage({ message: t('Poll.MissingContacts.Flash.Remove.success'), type: 'success' }));
        navigate(routes.poll);
      })
      .catch(() => {
        dispatch(setFlashMessage({ message: t('Poll.MissingContacts.Flash.Remove.error'), type: 'error' }));
      });
  };

  useEffect(() => {
    async function fetchDrafts() {
      const drafts = await getPollDrafts();
      const draft = drafts[0];
      if (draft) setPollData(draft);
      setIsLoading(false);
    }
    fetchDrafts();
  }, []);

  let startAt = pollData?.startAt;
  if (diffDays(pollData?.startAt, today) < 0) {
    startAt = formatDate(today, true);
  }

  let endAt = pollData?.endAt;
  if (diffDays(pollData?.endAt, addDays(today, POLL_END_MIN_DAYS)) < 0) {
    endAt = formatDate(addDays(today, POLL_END_MIN_DAYS), true);
  }

  let publicAt = pollData?.publicAt;
  if (diffDays(pollData?.publicAt, addDays(today, POLL_END_MIN_DAYS + POLL_PUBLIC_MIN_DAYS)) < 0) {
    publicAt = formatDate(addDays(today, POLL_END_MIN_DAYS + POLL_PUBLIC_MIN_DAYS), true);
  }

  const formik = useFormik<TSFixMe>({
    enableReinitialize: true,
    initialValues: {
      name: pollData?.name || '',
      startAt: startAt,
      endAt: endAt,
      publicAt: publicAt,
    },

    onSubmit: (values) => {
      updateNewPoll(values, pollData.id)
        .then((res) => {
          navigate(`${routes.poll}add/missing-contacts`);
        })
        .catch((error) => {
          backendViolations(formik, error);
        });
    },
  });

  const handleDateChange = (type: 'startAt' | 'endAt' | 'publicAt', date: string) => {
    if (formik.values[type] !== date) formik.setFieldValue(type, date);

    const startAt = type === 'startAt' ? date : formik.values.startAt;
    let endAt = type === 'endAt' ? date : formik.values.endAt;
    let publicAt = type === 'publicAt' ? date : formik.values.publicAt;

    if (diffDays(endAt, startAt) < POLL_END_MIN_DAYS) {
      endAt = formatDate(addDays(startAt, POLL_END_MIN_DAYS), true);
      formik.setFieldValue('endAt', endAt);
    }

    if (diffDays(publicAt, endAt) < POLL_PUBLIC_MIN_DAYS) {
      publicAt = formatDate(addDays(endAt, POLL_PUBLIC_MIN_DAYS), true);
      formik.setFieldValue('publicAt', publicAt);
    }

    setMinDates({
      endAt: formatDate(addDays(startAt, POLL_END_MIN_DAYS), true),
      publicAt: formatDate(addDays(endAt, POLL_PUBLIC_MIN_DAYS), true),
    });
  };

  return (
    <>
      <Modal isShown={draftModal} onHide={setDraftModal}>
        <Text size="h2" align="center" className="mb-2">
          {t('Poll.AddPoll.Header.discardDraftModal.title')}
        </Text>
        <Text size="body-medium" color="Neutral700" align="center" className="mb-3">
          {t('Poll.AddPoll.Header.discardDraftModal.subtitle')}
        </Text>
        <div className="Flex FlexColumn FlexAlign--center">
          <Button title={t('Poll.AddPoll.Header.discardDraftModal.confirm')} onClick={handleRemovePollDraft} />
        </div>
      </Modal>
      <Header
        title={pollData.name}
        customBacklink={`${routes.poll}add`}
        actions={
          <Button
            size="small"
            variant="secondary"
            iconAfter="trash"
            title={t('Poll.AddPoll.Header.discardDraft')}
            onClick={setDraftModal}
          />
        }
      />
      <Content isLoading={isLoading}>
        <Text size="h2" className="mb-2">
          {t('Poll.PollSettings.title')}
        </Text>
        <Card className="Card--content">
          <form onSubmit={formik.handleSubmit}>
            <DateField
              label={t('Poll.PollSettings.Form.Fields.StartAt.label')}
              placeholder={t('Poll.PollSettings.Form.Fields.StartAt.placeholder')}
              name="startAt"
              onChange={(date) => handleDateChange('startAt', date)}
              value={formik.values.startAt}
              minDate="today"
              className="mb-3"
            />
            <div className="Card__divider mb-3"></div>
            <DateField
              label={t('Poll.PollSettings.Form.Fields.EndAt.label')}
              placeholder={t('Poll.PollSettings.Form.Fields.EndAt.placeholder')}
              name="endAt"
              onChange={(date) => handleDateChange('endAt', date)}
              value={formik.values.endAt}
              minDate={minDates.endAt}
              className="mb-3"
            />
            <div className="Card__divider mb-3"></div>
            <DateField
              label={t('Poll.PollSettings.Form.Fields.PublicAt.label')}
              placeholder={t('Poll.PollSettings.Form.Fields.PublicAt.placeholder')}
              name="publicAt"
              onChange={(date) => handleDateChange('publicAt', date)}
              value={formik.values.publicAt}
              minDate={minDates.publicAt}
              className="mb-3"
            />
            <Button
              onClick={formik.handleSubmit}
              loading={formik.isSubmitting}
              title={t('Poll.PollSettings.Form.Fields.Submit.label')}
            />
          </form>
        </Card>
      </Content>
    </>
  );
};

export default StepPollSettings;
