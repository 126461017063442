import { Link, LinkProps } from 'react-router-dom';
import { Icon } from '..';

import { ButtonProps } from './Button';
import { buttonClassNames } from './util';

export interface ButtonLinkProps extends Omit<LinkProps, 'title'>, Omit<ButtonProps, 'onClick' | 'type'> {
  external?: boolean;
}

export const ButtonLink: React.FC<ButtonLinkProps> = (props) => {
  const {
    variant = 'primary',
    wide = false,
    rounded = false,
    size = 'default',
    disabled = false,
    loading = false,
    className,
    iconBefore,
    iconAfter,
    to,
    external = false,
    ...rest
  } = props;

  const classNames = buttonClassNames({ variant, wide, size, rounded, disabled, loading, className });

  if (external) {
    return (
      <a className={classNames} href={to.toString()} {...rest}>
        {iconBefore && <Icon name={iconBefore} className="Button__icon Button__icon--before" />}
        <span className="Button__label">{props.title}</span>
        {iconAfter && <Icon name={iconAfter} className="Button__icon Button__icon--after" />}
      </a>
    );
  }

  return (
    <Link className={classNames} to={!disabled ? to : '#'} {...rest} title={undefined}>
      {iconBefore && <Icon name={iconBefore} className="Button__icon Button__icon--before" />}
      <span className="Button__label">{props.title}</span>
      {iconAfter && <Icon name={iconAfter} className="Button__icon Button__icon--after" />}
    </Link>
  );
};
