import cx from 'classnames';
import { IPollQuestionAttachment, IPollQuestionImage, TSFixMe } from '../../frontend-libs/evlapp-types';
import { useFormik } from 'formik';

import { t } from '../../common/i18n';
import {
  Button,
  Card,
  Editor,
  FileField,
  Icon,
  Image,
  RadioField,
  RadioFieldLabel,
  Spinner,
  Text,
  TextAreaField,
  TextField,
} from '../../common/components';

import { createNewQuestion, deleteAttachment, deleteNewQuestion, updateNewQuestion } from '../services/PollService';
import React, { useRef, useState } from 'react';
import Yup, { backendViolations } from '../../common/utils/FormValidation';
import { setFlashMessage } from '../../common/store/uiSlice';
import { useAppDispatch } from '../../common/hooks';
import { Label } from '../../common/components/Form/Label/Label';

export const Question: React.FC<TSFixMe> = (props) => {
  const { listIndex, pollId, pollData, setPollData } = props;

  const question = pollData.questions[listIndex];

  const [isDeletingAttachment, setIsDeletingAttachment] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const updatePollData = (values: TSFixMe, method: 'update' | 'delete') => {
    if (method === 'update') {
      setPollData((prevState: TSFixMe) => {
        const questions = prevState.questions;
        questions[listIndex] = values;
        return { ...prevState, questions: questions };
      });
    }

    if (method === 'delete') {
      dispatch(setFlashMessage({ message: t('Poll.AddQuestion.deleteText'), type: 'success' }));
      setPollData((prevState: TSFixMe) => {
        const arr: TSFixMe[] = [];
        prevState.questions.forEach((item: TSFixMe, index: number) => {
          if (index !== listIndex) arr.push(item);
        });
        return { ...prevState, questions: arr };
      });
    }
  };

  const formik = useFormik<TSFixMe>({
    initialValues: {
      name: props.name || '',
      description: props.description || '',
      descriptionExtra: props.descriptionExtra || '',
      attachments: [],
      evaluateDefinition: {
        percentEvaluate: props?.evaluateDefinition?.percentEvaluate || '50',
        evaluateType: props?.evaluateDefinition?.evaluateType || 'half',
      },
    },

    validationSchema: Yup.object({
      name: Yup.string().required(),
      description: Yup.string().required(),
    }),

    onSubmit: (values) => {
      // First: Convert values to formData format
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('description', values.description);
      if (values.descriptionExtra && values.descriptionExtra !== '<p></p>') {
        formData.append('descriptionExtra', values.descriptionExtra);
      }

      for (let i = 0; i < values.attachments.length; i++) {
        formData.append(`attachments[${i}]`, values.attachments[i]);
      }
      formData.append('evaluateDefinition[evaluateType]', values.evaluateDefinition.evaluateType);
      if (values.evaluateDefinition.evaluateType === 'custom') {
        let percentEvaluate = values.evaluateDefinition.percentEvaluate;

        if (percentEvaluate < 0) percentEvaluate = 0;
        if (percentEvaluate > 100) percentEvaluate = 100;
        formData.append('evaluateDefinition[percentEvaluate]', percentEvaluate);
      }

      if (!question.id) {
        createNewQuestion(formData, pollId)
          .then((res: TSFixMe) => {
            updatePollData(res, 'update');
            if (fileInputRef.current) {
              formik.setFieldValue('attachments', []);
              fileInputRef.current.value = '';
            }

            dispatch(
              setFlashMessage({ message: t('Poll.AddQuestion.Form.Fields.Submit.successText'), type: 'success' })
            );
          })
          .catch((error) => {
            dispatch(setFlashMessage({ message: t('Poll.AddQuestion.Form.Fields.Submit.errorText'), type: 'error' }));
            backendViolations(formik, error);
          })
          .finally(() => formik.setSubmitting(false));
      } else {
        updateNewQuestion(formData, question.id, pollId)
          .then((res: TSFixMe) => {
            updatePollData(res, 'update');
            if (fileInputRef.current) {
              formik.setFieldValue('attachments', []);
              fileInputRef.current.value = '';
            }

            dispatch(
              setFlashMessage({ message: t('Poll.AddQuestion.Form.Fields.Submit.successText'), type: 'success' })
            );
          })
          .catch((error) => {
            dispatch(setFlashMessage({ message: t('Poll.AddQuestion.Form.Fields.Submit.errorText'), type: 'error' }));
            backendViolations(formik, error);
          })
          .finally(() => formik.setSubmitting(false));
      }
    },
  });

  const handleQuestionRemove = () => {
    if (!question.id) {
      updatePollData(null, 'delete');
    } else {
      deleteNewQuestion(question.id, pollId).finally(() => updatePollData(null, 'delete'));
    }
  };

  const handleAttachmentDelete = (id: string, type: 'images' | 'attachments') => {
    setIsDeletingAttachment(id);
    deleteAttachment(question.id, pollId, id)
      .then(() => {
        setPollData((prevState: TSFixMe) => {
          const items = prevState.questions[listIndex][type].filter((item: TSFixMe) => item.id !== id);

          return { ...prevState, ...(prevState.questions[listIndex][type] = items) };
        });
      })
      .catch(() => {
        dispatch(
          setFlashMessage({ message: t('Poll.AddQuestion.Form.Fields.Attachments.Delete.error'), type: 'error' })
        );
      });
  };

  return (
    <div className="Question PollAddQuestion">
      <div className="CardQuestionLabel">
        <Text as="span" className="text-uppercase CardQuestionLabel__title" color="Neutral700">
          {`${t('Poll.AddQuestion.label')} ${listIndex + 1}`}
        </Text>
        <div className="PollAddQuestion__remove" onClick={handleQuestionRemove}>
          <Icon name="trash" />
        </div>
      </div>
      <Card className="Card--content">
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id={`name_id${listIndex}`}
            name="name"
            label={t('Poll.AddQuestion.Form.Fields.Name.label')}
            placeholder={t('Poll.AddQuestion.Form.Fields.Name.placeholder')}
            value={formik.values.name}
            onChange={formik.handleChange}
            className="mb-2"
            required={true}
            onBlur={(event) => formik.setFieldValue(event.target.name, event.target.value.trim())}
            error={{ message: formik.errors.name as string, touched: formik.touched.name as boolean }}
          />

          <TextAreaField
            id={`description_id${listIndex}`}
            name="description"
            label={t('Poll.AddQuestion.Form.Fields.Description.label')}
            placeholder={t('Poll.AddQuestion.Form.Fields.Description.placeholder')}
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={(event) => formik.setFieldValue(event.target.name, event.target.value.trim())}
            className="mb-2"
            required={true}
            error={{ message: formik.errors.description as string, touched: formik.touched.description as boolean }}
          />

          <Editor
            content={formik.values.descriptionExtra}
            // onChange={formik.handleChange}
            onChange={(e: TSFixMe) => formik.setFieldValue('descriptionExtra', e.getHTML())}
            className="mb-2"
            variant="bordered"
            label={t('Poll.AddQuestion.Form.Fields.DescriptionExtra.label')}
            error={{
              message: formik.errors.descriptionExtra as string,
              touched: formik.touched.descriptionExtra as boolean,
            }}
          />

          {question.images && question.images.length > 0 && (
            <>
              <Label label={t('Poll.AddQuestion.Form.Fields.Gallery.title')} classNamePrefix="TextField" />
              <Card className={cx('CardGallery', props.className)}>
                {question.images.map((image: IPollQuestionImage | TSFixMe) => {
                  return (
                    <Image
                      key={image.id}
                      ratio="standard"
                      {...image}
                      overlayLoading={isDeletingAttachment === image.id}
                      onHover={
                        <div className="Image__delete" onClick={() => handleAttachmentDelete(image.id, 'images')}>
                          <Icon name="trash" color="SystemRed" className="Image__delete-icon" />
                        </div>
                      }
                    />
                  );
                })}
              </Card>
            </>
          )}

          {question.attachments && question.attachments.length > 0 && (
            <>
              <Label label={t('Poll.AddQuestion.Form.Fields.Attachments.title')} classNamePrefix="TextField" />
              <Card className="CardAttachments">
                {question.attachments.map((attachment: IPollQuestionAttachment, index: number) => {
                  return (
                    <React.Fragment key={attachment.id}>
                      <span
                        className={cx('Card__link', isDeletingAttachment !== attachment.id && 'Card__link--loading')}
                      >
                        <Text size="body-medium" color="Inherit" className="mr-auto">
                          {attachment.filename}
                        </Text>
                        {isDeletingAttachment !== attachment.id ? (
                          <Icon
                            name="trash"
                            color="Inherit"
                            onClick={() => handleAttachmentDelete(attachment.id, 'attachments')}
                          />
                        ) : (
                          <Spinner size="small" />
                        )}
                      </span>

                      {index !== question.attachments.length - 1 && <div className="Card__divider"></div>}
                    </React.Fragment>
                  );
                })}
              </Card>
            </>
          )}
          <FileField
            name="attachments"
            label={t('Poll.AddQuestion.Form.Fields.Attachments.label')}
            multiple={true}
            reference={fileInputRef}
            formikValue={formik.values.attachments}
            onChange={(event) => {
              if (event && event.currentTarget && event.currentTarget.files) {
                formik.setFieldValue('attachments', event.currentTarget.files);
              }
            }}
          />

          <RadioFieldLabel label={t('Poll.AddQuestion.Form.Fields.EvaluateType.label')} className="mt-2" />

          <RadioField
            id={`evaluateType_id${listIndex}`}
            name="evaluateType"
            value="half"
            className="mb-1"
            label={t('Poll.AddQuestion.Form.Fields.EvaluateType.Half.label')}
            onChange={(e) => formik.setFieldValue('evaluateDefinition.evaluateType', e.target.value)}
            checked={formik.values.evaluateDefinition?.evaluateType === 'half'}
          />

          <RadioField
            id={`evaluateType_id${listIndex}`}
            name="evaluateType"
            value="custom"
            className="mb-2"
            label={t('Poll.AddQuestion.Form.Fields.EvaluateType.CustomPercentage.label')}
            onChange={(e) => formik.setFieldValue('evaluateDefinition.evaluateType', e.target.value)}
            checked={formik.values.evaluateDefinition?.evaluateType === 'custom'}
            afterLabel={
              <>
                <TextField
                  id={`percentEvaluate_id${listIndex}`}
                  type="number"
                  className="PollAddQuestion__percentage"
                  value={formik.values.evaluateDefinition.percentEvaluate}
                  onChange={formik.handleChange}
                  name="evaluateDefinition.percentEvaluate"
                  min={1}
                  max={100}
                />
                <span className="RadioField__label ml-1">%</span>
              </>
            }
          />

          <Button
            onClick={formik.handleSubmit}
            loading={formik.isSubmitting}
            title={t('Poll.AddQuestion.Form.Fields.Submit.label')}
          />
        </form>
      </Card>
    </div>
  );
};
