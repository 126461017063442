export default {
  title: 'Obnova hesla',
  PasswordReset: {
    Form: {
      title: 'Obnovte si heslo',
      error: 'Nepodařilo se odeslat email pro obnovu hesla, zkontrolujte vámi zadaný email.',
      success: 'Email pro obnovu hesla byl odeslán, prosím zkontrolujte vaši emailovou schránku.',

      Fields: {
        Email: {
          label: 'Email',
          placeholder: 'Zadejte váš email',
        },
        Submit: {
          title: 'Zaslat žádost obnovy hesla',
        },
      },
    },
  },

  PasswordChange: {
    Form: {
      title: 'Zvolte si nové heslo',
      error: 'Heslo se nepodařilo obnovit, zkontrolujte vámi zadané údaje.',
      success: 'Heslo bylo obnoveno. Prosím, pokračujte na přihlášení.',

      Fields: {
        Password: {
          label: 'Heslo',
          placeholder: 'Zadejte nové heslo',
        },
        PasswordCheck: {
          label: 'Heslo znovu',
          placeholder: 'Zadejte nové heslo znovu',
          matches: 'Hesla se musí shodovat',
        },
        Submit: {
          title: 'Změnit heslo',
        },
      },
    },
  },

  dividerText: 'Nebo',
  toLogin: 'Zpět na přihlášení',
};
