import { TSFixMe } from '../../frontend-libs/evlapp-types';

import { store } from '../../common/store/store';
import { RestApi } from '../../common/services';
import { AxiosRequestConfig } from 'axios';
import { downloadFile } from '../../common/utils';

export interface IFile {
  id: string;
  filename: string;
  createdAt: string;
  extension: string;
}

export const getFiles = async (): Promise<IFile[]> => {
  const buildingId = store.getState().security.ui.buildingId;

  return await RestApi.get<null, IFile[]>(`/building/${buildingId}/file-storage`);
};

export const uploadFiles = async (values: TSFixMe): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;

  return await RestApi.post<TSFixMe, TSFixMe>(`/building/${buildingId}/file-storage/file`, values, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const renameFile = async (values: { id: string; filename: string }): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;

  return await RestApi.patch(`/building/${buildingId}/file-storage/file/${values.id}`, values);
};

export const deleteFile = async (id: string): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;

  return await RestApi.delete(`/building/${buildingId}/file-storage/file/${id}`);
};

export const downloadStorageFile = async (file: IFile): Promise<void> => {
  const buildingId = store.getState().security.ui.buildingId;

  const url = `/building/${buildingId}/file-storage/file/${file.id}/download`;

  const config: AxiosRequestConfig = { method: 'GET', responseType: 'blob', url: url };

  return await RestApi.axios(config).then((res) => {
    downloadFile({ data: res.data, filename: `${file.filename}.${file.extension}`, mime: res.headers['content-type'] });
  });
};
