import { useState } from 'react';
import { useFormik } from 'formik';

import routes from '../../common/constants/routes';
import { t } from '../../common/i18n';
import {
  Row,
  Col,
  Button,
  ButtonLink,
  Text,
  FormMessage,
  PasswordField,
  FormMessageProps,
} from '../../common/components';
import Yup, { YupPassword } from '../../common/utils/FormValidation';
import { useAppThunkDispatch } from '../../common/hooks';
import { passwordChange } from '../../common/store/securitySlice';
import { useParams } from 'react-router';

interface IPasswordChangeForm {
  password: string;
  passwordCheck: string;
}

export const PasswordChangeForm: React.FC = (props) => {
  const { token = '' } = useParams();

  const [formMessage, setFormMessage] = useState<FormMessageProps>({ message: '', type: 'error' });
  const [isPaswordChanged, setIsPaswordChanged] = useState(false);

  const thunkDispatch = useAppThunkDispatch();

  const formik = useFormik<IPasswordChangeForm>({
    initialValues: { password: '', passwordCheck: '' },
    validate: () => setFormMessage({}),
    validationSchema: Yup.object({
      password: YupPassword,
      passwordCheck: Yup.string()
        .required()
        .oneOf([Yup.ref('password'), null], t('PasswordReset.PasswordChange.Form.Fields.PasswordCheck.matches')),
    }),
    onSubmit: (values) => {
      thunkDispatch(passwordChange({ password: values.password, token: token }))
        .unwrap()
        .then(() => {
          formik.setSubmitting(false);
          setIsPaswordChanged(true);
          setFormMessage({ message: t('PasswordReset.PasswordChange.Form.success'), type: 'success' });
        })
        .catch(() => {
          formik.setSubmitting(false);
          setFormMessage({ message: t('PasswordReset.PasswordChange.Form.error'), type: 'error' });
        });
    },
  });

  return (
    <form className="OnboardingForm" onSubmit={formik.handleSubmit}>
      <Row>
        <Col className="mb-2">
          <Text size="h1" color="Neutral800">
            {t('PasswordReset.PasswordChange.Form.title')}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>{formMessage.message && <FormMessage {...formMessage} />}</Col>
      </Row>
      {!isPaswordChanged && (
        <>
          <Row>
            <Col size="12" className="mb-2 mt-2">
              <PasswordField
                name="password"
                autocomplete="new-password"
                placeholder={t('PasswordReset.PasswordChange.Form.Fields.Password.placeholder')}
                label={t('PasswordReset.PasswordChange.Form.Fields.Password.label')}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={{ message: formik.errors.password, touched: formik.touched.password }}
              />
            </Col>
          </Row>

          <Row>
            <Col size="12" className="mb-2">
              <PasswordField
                name="passwordCheck"
                autocomplete="new-password"
                placeholder={t('PasswordReset.PasswordChange.Form.Fields.PasswordCheck.placeholder')}
                label={t('PasswordReset.PasswordChange.Form.Fields.PasswordCheck.label')}
                value={formik.values.passwordCheck}
                onChange={formik.handleChange}
                error={{ message: formik.errors.passwordCheck, touched: formik.touched.passwordCheck }}
              />
            </Col>
          </Row>

          <Row>
            <Col size="12" className="mb-2">
              <Button
                type="submit"
                title={t('PasswordReset.PasswordChange.Form.Fields.Submit.title')}
                loading={formik.isSubmitting}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <div className="OnboardingDivider">
                <Text size="body-xs" as="span" color="Neutral600">
                  {t('PasswordReset.dividerText')}
                </Text>
              </div>
            </Col>
          </Row>
        </>
      )}

      <Row className={isPaswordChanged ? 'mt-2' : ''}>
        <Col className="mb-2">
          <ButtonLink variant="secondary" to={{ pathname: routes.login }} title={t('PasswordReset.toLogin')} />
        </Col>
      </Row>
    </form>
  );
};
