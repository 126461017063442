import { useState, useEffect } from 'react';

import { useAppSelector, useDocumentTitle } from '../common/hooks';
import { t } from '../common/i18n';

import { Header, Content, ButtonLink } from '../common/components';

import { getFeeds } from './services/FeedService';
import { CardFeed } from '../common/components/Cards/CardFeed';
import { IGetResFeed } from '../frontend-libs/evlapp-types';
import { selectSecurity } from '../common/store/securitySlice';
import routes from '../common/constants/routes';

export const FeedDefault: React.FC = () => {
  const namespace = 'Feed.Default';
  useDocumentTitle(t('Feed.title'));

  const security = useAppSelector(selectSecurity).ui;
  const isAdmin = security.isAdmin;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [feed, setFeed] = useState<IGetResFeed>([]);

  useEffect(() => {
    async function fetchFeed() {
      const data = await getFeeds();
      setFeed(data);
      setIsLoading(false);
    }
    fetchFeed();
  }, []);

  return (
    <>
      <Header
        title={t('Header.title', namespace)}
        customBacklink={routes.poll}
        actions={isAdmin && <ButtonLink title={t('Header.add', namespace)} to="add" size="small" />}
      />

      <Content isLoading={isLoading} isEmpty={feed.length === 0} isEmptyText={t('Content.emptyText', namespace)}>
        <div className="List">
          {feed.map((item) => (
            <CardFeed key={item.id} {...item} detail={item.id} />
          ))}
        </div>
      </Content>
    </>
  );
};
