import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { Sidebar } from '../index';

import { useAppSelector, useAppThunkDispatch } from '../../hooks';
import { getProfile, selectSecurity } from '../../store/securitySlice';
import { FullPageSpinner, PageSpinner } from '../Spinner/Spinner';
import routes from '../../constants/routes';
import { useEffect, useState } from 'react';
import { checkBuildingStatus } from '../../../Building/services/BuildingService';
import { TSFixMe } from '../../../frontend-libs/evlapp-types';
import { Text } from '../Text/Text';
import { Col } from '../Layout/Col';
import { Row } from '../Layout/Row';
import { ButtonLink } from '../Button/ButtonLink';
import { WEBURL } from '../../constants/common';
import { t } from '../../i18n';

import { ModalLock } from '../Modal/ModalLock';

export const PrivateRoute: React.FC = (props) => {
  const location = useLocation();
  const dispatch = useAppThunkDispatch();
  const security = useAppSelector(selectSecurity);

  const [loadingStep, setLoadingStep] = useState(0);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);

  const buildingState = security.ui.buildingState;
  const buildingId = security.ui.buildingId;

  useEffect(() => {
    if (!isFetchingData || security.ui.buildingState === 'ready' || !security.ui.buildingId) return;

    checkBuildingStatus()
      .then((res) => {
        switch (res.state) {
          case 'ready':
            dispatch(getProfile());
            break;

          case 'initialization':
          case 'loading':
            setIsFetchingData(false);
            setLoadingStep((prevValue) => prevValue + 1);
            setTimeout(() => setIsFetchingData(true), 5000);
            break;

          case 'error':
            setIsFetchingFailed(true);
            break;
        }
      })
      .catch(() => setIsFetchingFailed(true));
  }, [dispatch, isFetchingData, security.ui.buildingState, security.ui.buildingId]);

  if (security.state === 'authenticated') {
    if ((buildingId && buildingState === 'initialization') || (buildingId && buildingState === 'loading')) {
      return <LoadingState step={loadingStep} />;
    }

    if (buildingId && buildingState === 'error') {
      return <LoadingState fetchingFailed={isFetchingFailed} />;
    }

    if (buildingState === 'ready') {
      return (
        <div className="Page">
          <Sidebar />
          <div className="Main">
            <Outlet />
          </div>

          <ModalLock />
        </div>
      );
    }
  }

  if (security.token && security.state === 'notAuthenticated') {
    dispatch(getProfile());
  }

  if (security.state === 'pending') {
    return <PageSpinner />;
  }

  if (security.state === 'badCredentials' || !security.token) {
    return <Navigate to={routes.login} state={{ from: location }} />;
  }

  return null;
};

const LoadingState: React.FC<TSFixMe> = (props) => {
  const namespace = 'Building.Status';

  const { step, fetchingFailed } = props;

  if (fetchingFailed) {
    return (
      <div className="OnboardingForm Flex FlexAlign--center" style={{ minHeight: '100vh' }}>
        <Row>
          <Col className="FlexColumn FlexAlign--center">
            <Text size="h1" className="mb-1" align="center">
              {t('FetchingFailed.heading', namespace)}
            </Text>
            <Text align="center" className="mb-2">
              {t('FetchingFailed.subheading', namespace)}
            </Text>
            <ButtonLink
              external={true}
              to={WEBURL}
              target="_blank"
              title={t('FetchingFailed.buttonTitle', namespace)}
            />
          </Col>
        </Row>
      </div>
    );
  }

  return <FullPageSpinner namespace="Building.Status.Loading" step={step} />;
};

export default PrivateRoute;
