export default {
  Mixed: {
    required: 'Toto pole je povinné',
  },
  String: {
    trim: 'Toto pole nesmí začínat a končit mezerou',
  },
  Email: {
    required: 'Email musí být vyplněný',
    matches: 'Email musí být zadán ve správném formátu',
    duplicity: 'Zadaný email je již zaregistrovaný',
  },

  Password: {
    required: 'Heslo musí být vyplněné',
    minLength: 'Heslo musí být dlouhé minimálně 8 znaků',
  },

  Phone: {
    required: 'Telefon musí být vyplňený',
    format: 'Telefon musí být ve formátu +420 xxx xxx xxx',
  },

  Ico: {
    required: 'IČO musí být vyplněné',
    format: 'IČO musí být ve správném formátu',
  },

  RadioField: {
    required: 'Vyberte jednu z možností',
  },
};
