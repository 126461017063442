import { RestApi } from '../../common/services';
import {
  IGetResBuildingProfileDetail,
  IGetResPollDetail,
  IPatchReqBuildingUpdateProfileContacts,
  IPoll,
  TSFixMe,
} from '../../frontend-libs/evlapp-types';

import { store } from '../../common/store/store';
import { getProfileName } from '../../common/utils/Profile/profile';
import { downloadFile, objectToFormData } from '../../common/utils';
import { AxiosRequestConfig } from 'axios';

export const getPolls = async (): Promise<IPoll[]> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.get<never, IPoll[]>(`/building/${buildingId}/poll`);
};

/**
 * @param {string} id Poll Id
 * @return {Promise<IPollDetail>} Poll detail
 */
export const getPollById = async (id: string): Promise<IGetResPollDetail> => {
  const buildingId = store.getState().security.ui.buildingId;
  const res = await RestApi.get<string, IGetResPollDetail>(`/building/${buildingId}/poll/${id}`);
  return res;
};

export const sendPollQuestionVote = async (
  answer: 'yes' | 'no' | 'undecided',
  questionId: string,
  pollId: string
): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  const profileId = store.getState().security.profile?.id;

  const values = { answer, voterId: profileId };
  return await RestApi.post<TSFixMe, TSFixMe>(
    `/building/${buildingId}/poll/${pollId}/question/${questionId}/vote`,
    values
  );
};

export const requestVerificationCode = async (pollId: string): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.post<TSFixMe, TSFixMe>(`/building/${buildingId}/poll/${pollId}/request-confirmation`);
};

export const confirmVerificationCode = async (
  values: { token: string; code: string },
  pollId: string
): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.post<TSFixMe, TSFixMe>(`/building/${buildingId}/poll/${pollId}/confirmation`, values);
};

export const getPollDrafts = async (): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.get<null, TSFixMe>(`/building/${buildingId}/poll/drafts`);
};

export const createNewPoll = async (values: TSFixMe): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.post<{ name: string }, TSFixMe>(`/building/${buildingId}/poll`, values);
};

export const updateNewPoll = async (values: TSFixMe, pollId: string): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.patch<TSFixMe, TSFixMe>(`/building/${buildingId}/poll/${pollId}`, values);
};

export const publishNewPoll = async (pollId: string): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.post<TSFixMe, TSFixMe>(`/building/${buildingId}/poll/${pollId}/publish`);
};

/**
 *
 * @param {TSFixMe} pollId Name of the new poll
 * @return {Promise<TSFixMe>}
 */
export const removePollDraft = async (pollId: string): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.delete<TSFixMe>(`/building/${buildingId}/poll/${pollId}`);
};

export const createNewQuestion = async (values: TSFixMe, pollId: string): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.post<TSFixMe, TSFixMe>(`/building/${buildingId}/poll/${pollId}/question`, values, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateNewQuestion = async (values: TSFixMe, questionId: string, pollId: string): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.post<TSFixMe, TSFixMe>(`/building/${buildingId}/poll/${pollId}/question/${questionId}`, values);
};

export const deleteNewQuestion = async (questionId: string, pollId: string): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.delete<TSFixMe>(`/building/${buildingId}/poll/${pollId}/question/${questionId}`);
};

export const deleteAttachment = async (questionId: string, pollId: string, attachmentId: string): Promise<void> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.delete(
    `/building/${buildingId}/poll/${pollId}/question/${questionId}/question-file/${attachmentId}`
  );
};

export const getMissingContacts = async (): Promise<TSFixMe> => {
  const empty: TSFixMe[] = [];
  const buildingId = store.getState().security.ui.buildingId;
  const contacts = await RestApi.get<TSFixMe, TSFixMe>(`/building/${buildingId}/profiles`);

  contacts.forEach((contact: TSFixMe) => {
    const { profile, units } = contact;
    if (profile.email || profile.phone) return;
    empty.push({ profile, units });
  });

  return empty.sort((a, b) => {
    const fullnameA = getProfileName(a.profile, true);
    const fullnameB = getProfileName(b.profile, true);

    return fullnameA.localeCompare(fullnameB);
  });
};

export const addMissingContact = async (
  profileId: string,
  values: IPatchReqBuildingUpdateProfileContacts
): Promise<IGetResBuildingProfileDetail> => {
  const buildingId = store.getState().security.ui.buildingId;

  // TODO: null values
  if (values.email === '') values.email = null;
  if (values.phone === '') values.phone = null;

  return await RestApi.patch<IPatchReqBuildingUpdateProfileContacts, never>(
    `/building/${buildingId}/profile/${profileId}/contacts`,
    values
  );
};

export const generatePollTicket = async (pollId: string, unitId?: string): Promise<void> => {
  const buildingId = store.getState().security.ui.buildingId;

  let url = `/building/${buildingId}/poll/${pollId}/ticket/generate`;
  if (unitId) url += `/unit/${unitId}`;

  let filename = `eVlastnici-hlasovaci-listek`;
  if (unitId) filename += `-${unitId}`;

  const config: AxiosRequestConfig = { method: 'GET', responseType: 'blob', url: url };

  return await RestApi.axios(config).then((res) => {
    downloadFile({ data: res.data, filename: filename, mime: res.headers['content-type'] });
  });
};

export const generateResults = async (pollId: string): Promise<void> => {
  const buildingId = store.getState().security.ui.buildingId;

  const url = `/building/${buildingId}/poll/${pollId}/export/results`;
  const filename = `eVlastnici-vysledky-hlasovani-${pollId}`;

  const config: AxiosRequestConfig = { method: 'GET', responseType: 'blob', url: url };

  return await RestApi.axios(config).then((res) => {
    downloadFile({ data: res.data, filename: filename, mime: res.headers['content-type'] });
  });
};

export const pollAdminVote = async (pollId: string, unitId: string, values: TSFixMe): Promise<void> => {
  const buildingId = store.getState().security.ui.buildingId;

  delete values.unitId;
  values = objectToFormData(values);

  return await RestApi.post(`/building/${buildingId}/poll/${pollId}/unit/${unitId}/vote`, values, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
