import { useFormik } from 'formik';

import { t } from '../../common/i18n';
import Yup, { YupEmail, YupPassword, YupPhone } from '../../common/utils/FormValidation/index';

import { Col, Row, Text, TextField, Button, PasswordField, PhoneField } from '../../common/components';

import { IncompleteData } from './IncompleteData';

import { useNavigate } from 'react-router';
import { IAdminRegistration } from './interfaces';
import routes from '../../common/constants/routes';
import { checkEmailAvailable } from '../services/RegistrationService';

export const User: React.FC<{ state: IAdminRegistration }> = (props) => {
  const namespace = 'AdminRegistration.User';

  const { state } = props;

  const hasData = state?.building && state.legalEntity;
  const address = state?.building?.address;

  const navigate = useNavigate();

  const formik = useFormik<IAdminRegistration['user'] & { passwordCheck: string }>({
    initialValues: { firstName: '', lastName: '', email: '', phone: '', password: '', passwordCheck: '' },
    onSubmit: async (values) => {
      const isEmailAvailable = await checkEmailAvailable(values.email);

      if (isEmailAvailable === 'exists') {
        formik.setFieldError('email', t('FormValidation.Email.duplicity'));
      } else {
        const { passwordCheck, ...rest } = values;
        return navigate(`${routes.registration}summary/`, { state: { ...state, user: rest } });
      }
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required(t('Form.Fields.FirstName.required', namespace)),
      lastName: Yup.string().required(t('Form.Fields.LastName.required', namespace)),
      email: YupEmail,
      phone: YupPhone,
      password: YupPassword,
      passwordCheck: Yup.string()
        .required()
        .oneOf([Yup.ref('password'), null], t('Profile.ChangePassword.Form.Fields.NewPasswordCheck.matches')),
    }),
  });

  if (!hasData) return <IncompleteData />;

  return (
    <form className="OnboardingForm" onSubmit={formik.handleSubmit}>
      <Row>
        <Col className="mb-2">
          <Text size="h1">{t('heading', namespace, { address })}</Text>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col size="6" className="mb-2">
          <TextField
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            placeholder={t('Form.Fields.FirstName.placeholder', namespace)}
            label={t('Form.Fields.FirstName.label', namespace)}
            error={{ touched: formik.touched.firstName, message: formik.errors.firstName }}
          />
        </Col>
        <Col size="6" className="mb-2">
          <TextField
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            placeholder={t('Form.Fields.LastName.placeholder', namespace)}
            label={t('Form.Fields.LastName.label', namespace)}
            error={{ touched: formik.touched.lastName, message: formik.errors.lastName }}
          />
        </Col>
      </Row>

      <Row>
        <Col size="6" className="mb-2">
          <TextField
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder={t('Form.Fields.Email.placeholder', namespace)}
            label={t('Form.Fields.Email.label', namespace)}
            error={{ touched: formik.touched.email, message: formik.errors.email }}
          />
        </Col>
        <Col size="6" className="mb-2">
          <PhoneField
            name="phone"
            value={formik.values.phone}
            onChange={(phone) => formik.setFieldValue('phone', phone)}
            placeholder={t('Form.Fields.Phone.placeholder', namespace)}
            label={t('Form.Fields.Phone.label', namespace)}
            error={{ touched: formik.touched.phone, message: formik.errors.phone }}
          />
        </Col>
      </Row>

      <Row>
        <Col size="6" className="mb-2">
          <PasswordField
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            placeholder={t('Form.Fields.Password.placeholder', namespace)}
            label={t('Form.Fields.Password.label', namespace)}
            error={{ touched: formik.touched.password, message: formik.errors.password }}
          />
        </Col>
        <Col size="6" className="mb-2">
          <PasswordField
            name="passwordCheck"
            value={formik.values.passwordCheck}
            onChange={formik.handleChange}
            placeholder={t('Form.Fields.PasswordCheck.placeholder', namespace)}
            label={t('Form.Fields.PasswordCheck.label', namespace)}
            error={{ touched: formik.touched.passwordCheck, message: formik.errors.passwordCheck }}
          />
        </Col>
      </Row>

      <Row>
        <Col size="12" className="mb-2">
          <Button title={t('Form.Fields.Submit.title', namespace)} type="submit" loading={formik.isSubmitting} />
        </Col>
      </Row>
    </form>
  );
};
