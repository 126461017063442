import React from 'react';
import { TSFixMe } from '../../frontend-libs/evlapp-types';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { selectPoll, setQuestionChoice } from '../../common/store/pollSlice';

import { Button, CardQuestion, Modal, Text } from '../../common/components';
import { t } from '../../common/i18n';

import { sendPollQuestionVote } from '../services/PollService';

import { useModal } from '../../common/components/Modal/useModal';
import QuestionSendModal from '../QuestionSendModal';

export const TabDetail: React.FC = () => {
  const namespace = 'Poll.Detail.TabDetail';

  const dispatch = useAppDispatch();
  const poll = useAppSelector(selectPoll);
  const [isShownModal, setIsShownModal] = useModal();
  const answeredAll = poll.questions.find((question: TSFixMe) => !question.answer) ? false : true;

  const sendPollQuestion = async (questionId: string, answer: 'yes' | 'no' | 'undecided') => {
    try {
      await sendPollQuestionVote(answer, questionId, poll.id);
      return dispatch(setQuestionChoice({ questionId, answer }));
    } catch {}
  };

  return (
    <>
      <Text size="h2" color="Neutral800" className="mb-3">
        {t('title', namespace)}
      </Text>
      {poll?.questions?.map((question: TSFixMe, index: number) => {
        return (
          <CardQuestion
            key={question.id}
            title={question.name}
            label={`${t('Question.label', namespace)} ${index + 1}`}
            detailLink={`question/${question.id}`}
            answer={question.answer}
            onYes={() => sendPollQuestion(question.id, 'yes')}
            onNo={() => sendPollQuestion(question.id, 'no')}
            onUndecided={() => sendPollQuestion(question.id, 'undecided')}
            isEditable={true}
          />
        );
      })}
      <br />
      <Button variant="primary" title={t('Poll.Question.Send.buttonSend')} onClick={setIsShownModal} size="default" />
      <Modal isShown={isShownModal} onHide={setIsShownModal}>
        <QuestionSendModal stepNumber={answeredAll ? 2 : 1} pollId={poll.id} />
      </Modal>
    </>
  );
};
