import cx from 'classnames';

interface HamburgerProps {
  action: 'open' | 'close';
  className?: string;
  variant?: 'default' | 'light';
}

export const Hamburger: React.FC<HamburgerProps> = (props) => {
  const { variant = 'default' } = props;

  const openedClassName = 'sidebar--is-opened';

  const handleToggle = () => {
    if (props.action === 'open') {
      document.body.classList.add(openedClassName);
    }

    if (props.action === 'close') {
      document.body.classList.remove(openedClassName);
    }
  };

  return (
    <div
      className={cx(
        `Hamburger`,
        `Hamburger--${variant}`,
        props.action === 'close' && 'Hamburger--opened',
        props.className
      )}
      onClick={handleToggle}
    >
      <div className="Hamburger-box">
        <div className="Hamburger-inner"></div>
      </div>
    </div>
  );
};
