const oneDay = 1000 * 3600 * 24;

export const unifyDateFormat = (date: Date | string, onlyDate = true): Date => {
  if (typeof date === 'string') date = date.replaceAll('-', '/');

  date = new Date(date);
  if (onlyDate) date.setHours(0, 0, 0, 0);

  return date;
};

export const diffDays = (to: Date | string, from: Date | string = new Date()): number => {
  to = unifyDateFormat(to, true);
  from = unifyDateFormat(from, true);

  return Math.ceil((to.getTime() - from.getTime()) / oneDay);
};

export const formatDate = (date: Date | string, apiFormat?: boolean): string => {
  date = unifyDateFormat(date);

  if (apiFormat) return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  return new Intl.DateTimeFormat('cs-CZ', { dateStyle: 'long' }).format(date);
};

export const formatDateTime = (date: Date | string): string => {
  date = unifyDateFormat(date, false);

  return new Intl.DateTimeFormat('cs-CZ', { dateStyle: 'long', timeStyle: 'short' }).format(date);
};

export const formatDateMonthYear = (date: Date | string): string => {
  date = unifyDateFormat(date);

  return new Intl.DateTimeFormat('cs-CZ', { month: 'long', year: 'numeric' }).format(date);
};

export const addDays = (date: Date | string, days: number): Date => {
  date = new Date(date);

  date.setDate(date.getDate() + days);
  return date;
};
