import cx from 'classnames';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import { GroupBase } from 'react-select';
import { t } from '../../../i18n';

import { FormFieldProps } from '../Base';
import { Label } from '../Label/Label';
import { FieldError } from '../Error/FieldError';

interface SelectOption {
  value: string;
  label: string;
}

interface Props<T> extends AsyncProps<T, false, GroupBase<T>>, Omit<FormFieldProps<SelectOption>, 'name' | 'value'> {}

export const AsyncSelectField = <T extends unknown>(props: Props<T>): JSX.Element => {
  const { id, name, label, placeholder, className, error, required, ...rest } = props;

  return (
    <div className={cx('SelectField', className)}>
      {label && <Label classNamePrefix="SelectField" id={id} label={label} required={required} />}
      <AsyncSelect
        id={id}
        name={name}
        classNamePrefix="SelectField"
        menuPlacement="auto"
        placeholder={props.placeholder || t('SelectField.placeholder')}
        loadingMessage={() => t('SelectField.loadingMessage')}
        noOptionsMessage={() => t('SelectField.noOptionsMessage')}
        {...rest}
      />
      <FieldError {...error} />
    </div>
  );
};
