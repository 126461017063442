import React from 'react';
import { Route, Routes } from 'react-router';

import { FilesDefault } from './FilesDefault';

import { Content } from '../common/components';

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route index element={<FilesDefault />} />
      <Route path="*" element={<Content isEmpty={true} />} />
    </Routes>
  );
};

export default Router;
