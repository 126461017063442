import cx from 'classnames';
import { FormFieldProps } from '../Base';

import Input from 'react-phone-number-input/input';
import { E164Number } from 'libphonenumber-js/types';

import { FieldError } from '../Error/FieldError';
import { Label } from '../Label/Label';

export interface PhoneFieldProps extends FormFieldProps<E164Number> {
  placeholder?: string;
  className?: string;
  onChange: (v: E164Number) => void;
}

export const PhoneField: React.FC<PhoneFieldProps> = (props) => {
  const { id = props.name, name, value, className, label, afterLabel, error, ...rest } = props;

  const inputValue = value || '';

  return (
    <div className={cx('TextField PhoneField', className)}>
      {label && <Label classNamePrefix="TextField" id={id} label={label} required={props.required} />}

      <Input className="TextField__control" name={name} id={id} defaultCountry="CZ" value={inputValue} {...rest} />
      <FieldError {...error} touched={true} />
    </div>
  );
};
