import { TSFixMe } from '../frontend-libs/evlapp-types';
import { useFormik } from 'formik';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Button, CardUserList, FileField, FormMessage, Header, Modal, Spinner, Text } from '../common/components';
import { useModal } from '../common/components/Modal/useModal';
import { Colors } from '../common/constants/Colors';
import routes from '../common/constants/routes';
import { useAppDispatch, useAppSelector, useDocumentTitle } from '../common/hooks';
import { t } from '../common/i18n';
import { selectSecurity } from '../common/store/securitySlice';
import { setElementHeight } from '../common/utils/ElementHeight/elementHeight';
import { exportBuildingOwners, getUnits, importBuildingOwners } from './services/UnitService';
import Yup from '../common/utils/FormValidation';
import { setFlashMessage } from '../common/store/uiSlice';
import { IconNames } from '../common/components/Icon/IconNames';

const MAXOWNERS = 2;

const ownersListCount = (owners: TSFixMe[]) => {
  if (MAXOWNERS < owners.length) {
    const count = owners.length - MAXOWNERS;

    return (
      <Text size="body-medium" weight="400" color="Neutral750" className="UserList__count">
        {t('ownersCount', 'Units.Table', { count: count })}
      </Text>
    );
  }

  return null;
};

const ownersList = (owners: TSFixMe[]) => {
  return owners
    .filter((_, index) => index < MAXOWNERS)
    .map((owner) => {
      const { profile } = owner;

      let ownerName = '';
      if (profile.firstName) ownerName = profile.firstName;
      if (profile.lastName) ownerName += ` ${profile.lastName}`;
      if (profile.company) ownerName = profile.company;

      let profileContacts = 'missingAll';
      if (profile.email && profile.phone) profileContacts = 'missingNone';
      if (profile.email && !profile.phone) profileContacts = 'missingPhone';
      if (!profile.email && profile.phone) profileContacts = 'missingEmail';

      let textColor: Colors = 'SystemRed';
      if (profileContacts === 'missingNone') textColor = 'SystemGreen';
      if (profileContacts === 'missingPhone' || profileContacts === 'missingEmail') textColor = 'SystemOrange';

      return (
        <CardUserList
          key={profile.id}
          icon="profile"
          to={`profile/${profile.id}`}
          title={ownerName}
          text={t(`Enums.OwnerType.${profileContacts}`)}
          textColor={textColor}
        />
      );
    });
};

const violationsArray = (violations: TSFixMe) => {
  const textArray: string[] = [];

  violations.forEach((entry: TSFixMe) => {
    const duplicates: string[] = entry?.parameters?.duplicateFields || [];
    const badFormat: string[] = entry?.parameters?.badFormat || [];
    const row = entry?.parameters?.row;

    if (!row) return;

    let duplicatesText = '';
    duplicates.forEach((e: string, i) => {
      if (i === 0) duplicatesText = `duplikovaná hodnota ${t(`Units.Default.ModalImport.BadFormats.${e}`)}`;
      if (i > 0) duplicatesText += `, ${t(`Units.Default.ModalImport.BadFormats.${e}`)}`;
    });

    let badFormats = '';
    badFormat.forEach((e: string, i) => {
      if (i === 0) badFormats = `, špatný formát ${t(`Units.Default.ModalImport.BadFormats.${e}`)}`;
      if (i > 0) badFormats += `, ${t(`Units.Default.ModalImport.BadFormats.${e}`)}`;
    });

    textArray.push(`Na řádku ${row} se vyskytla chyba: ${duplicatesText}${badFormats}.`);
  });

  return textArray;
};

export const UnitsDefault: React.FC = () => {
  useDocumentTitle(t('Units.title'));
  const namespace = 'Units';

  const dispatch = useAppDispatch();
  const security = useAppSelector(selectSecurity).ui;
  const isAdmin = security.isAdmin;

  const [units, setUnits] = useState<TSFixMe[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [violations, setViolations] = useState<TSFixMe>([]);

  const [isShown, toggle] = useModal();
  const [formError, setFormError] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const headerRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    toggle();
    setViolations([]);
    setFormError(false);
  };

  const violationsText = violationsArray(violations).map((row, i) => {
    return (
      <Text key={row} size="body-small" weight="500" as="div" className={cx('text--left', i > 0 && 'mt-1')}>
        {row}
      </Text>
    );
  });

  useEffect(() => {
    const handleHeight = () => {
      setElementHeight(headerRef.current, '--unit-list-height');
    };

    handleHeight();
    window.addEventListener('resize', handleHeight);
    return () => window.removeEventListener('resize', handleHeight);
  }, [headerRef, isLoading]);

  useEffect(() => {
    async function fetchUnits() {
      const data = await getUnits();
      setUnits(data);
      setIsLoading(false);
    }
    fetchUnits();
  }, []);

  const formik = useFormik<TSFixMe>({
    initialValues: { file: null },
    validationSchema: Yup.object({ file: Yup.mixed().required() }),
    onSubmit: (values) => {
      const formData = new FormData();

      for (let i = 0; i < values.file.length; i++) {
        formData.append(`file`, values.file[i]);
      }

      importBuildingOwners(formData)
        .then(() => {
          async function fetchUnits() {
            const data = await getUnits();
            setUnits(data);
            setIsLoading(false);

            dispatch(
              setFlashMessage({ message: t('Default.ModalImport.Flash.importSuccess', namespace), type: 'success' })
            );
            handleToggle();
          }
          fetchUnits();
        })
        .catch((error) => {
          setViolations(error.violations);
          setFormError(true);
        })
        .finally(() => {
          formik.setSubmitting(false);
          if (fileInputRef?.current) fileInputRef.current.value = '';
          formik.setFieldValue('file', null);
          formik.setFieldTouched('file', false);
        });
    },
  });

  return (
    <>
      <Header
        title={t('Units.title')}
        customBacklink={routes.poll}
        actions={
          isAdmin && (
            <>
              <Button
                title={t('Default.Header.exportContactsAction', namespace)}
                size="small"
                onClick={exportBuildingOwners}
              />
              <Button title={t('Default.Header.importContactsAction', namespace)} size="small" onClick={toggle} />
            </>
          )
        }
      />
      <div className="Content">
        {isLoading ? (
          <Spinner size="large" position="overlay" />
        ) : (
          <div className="UserList UserList--top">
            <div className="UserList__header" ref={headerRef}>
              <div className="UserList__row">
                <div className="UserList__cell UserList__cell--small">
                  <Text size="body-small" color="Neutral600" weight="600" className="text-uppercase">
                    {t('Units.Table.Header.unit')}
                  </Text>
                </div>
                <div className="UserList__cell">
                  <Text size="body-small" color="Neutral600" weight="600" className="text-uppercase">
                    {t('Units.Table.Header.owner')}
                  </Text>
                </div>
              </div>
            </div>
            <div className="UserList__content">
              {units.map((x, i) => {
                let iconName: IconNames = 'key';

                if (x.useType === 'flat') {
                  iconName = 'home';
                }

                if (x.useType === 'garage') {
                  iconName = 'garage';
                }

                return (
                  <div className="UserList__row" key={i}>
                    <div className="UserList__cell UserList__cell--unit UserList__cell--small">
                      <CardUserList
                        icon={iconName}
                        to={`unit/${x.id}`}
                        title={`${t('Units.Table.Body.unit')} ${x.label}`}
                        text={`${t(`Enums.UnitUseType.${x.useType}`)}`}
                        className="CardUserList--unit"
                      />
                    </div>
                    <div className="UserList__cell">
                      {ownersList(x.owners)}

                      {ownersListCount(x.owners)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <Modal isShown={isShown} onHide={handleToggle}>
        <Text size="h2" align="center" className="mb-2">
          {t('Default.ModalImport.title', namespace)}
        </Text>
        <Text size="body-medium" color="Neutral700" align="center" className="mb-1">
          {t('Default.ModalImport.subtitle_1', namespace)} {t('Default.ModalImport.subtitle_2', namespace)}
        </Text>
        <Text size="body-small" color="Neutral700" align="center" className="mb-1">
          {t('Default.ModalImport.subtitle_3', namespace)}
        </Text>
        {formError && <FormMessage message={t('Default.ModalImport.formError', namespace)} variant="dark" />}
        {formError && (
          <FormMessage variant="dark" className="mt-2">
            {violationsText}
          </FormMessage>
        )}
        <FileField
          name="file"
          label={t('Default.ModalImport.Attachment.label', namespace)}
          filesLabel={`${namespace}.Default.ModalImport.Attachment.filesLabel`}
          multiple={false}
          reference={fileInputRef}
          formikValue={formik.values.file}
          className="mt-2 mb-4"
          error={{ message: formik.errors.file as string, touched: formik.touched.file as boolean }}
          onChange={(event) => {
            if (event && event.currentTarget && event.currentTarget.files) {
              formik.setFieldValue('file', event.currentTarget.files);
            }
          }}
        />

        <div className="Flex FlexAlign--center FlexJustify--center mb-2">
          <Button
            disabled={!formik.values.file}
            onClick={formik.handleSubmit}
            title={t('Default.ModalImport.submit', namespace)}
            loading={formik.isSubmitting}
            disabledDeps={['demo', 'expired']}
            wide={true}
          />
        </div>
      </Modal>
    </>
  );
};
