import { TSFixMe } from '../../frontend-libs/evlapp-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FlashMessageProps } from '../components';
import { FLASH_MESSAGE_TIMEOUT } from '../constants/common';
import { uniqId } from '../utils/uniqId';
import type { RootState } from './store';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface uiState extends TSFixMe {
  flashes: FlashMessageProps[];
  paymentModal: {
    show: boolean;
    errorType: string;
  };
}

const initialState: uiState = {
  flashes: [],
  paymentModal: {
    show: false,
    errorType: '',
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setFlashMessage: (state, action: PayloadAction<FlashMessageProps>) => {
      state.flashes.push({
        ...action.payload,
        timeout: action.payload.timeout || FLASH_MESSAGE_TIMEOUT,
        id: action.payload.id || `${uniqId('msg')}`,
      });
    },

    removeFlashMessage: (state, action: PayloadAction<{ id: string }>) => {
      state.flashes = state.flashes.filter((item) => item.id !== action.payload.id);
    },

    showPaymentModal: (state, action: PayloadAction<{ errorType: string }>) => {
      state.paymentModal = { ...action.payload, show: true };
    },

    hidePaymentModal: (state) => {
      state.paymentModal = { ...state.paymentModal, show: false };
    },
  },
});

export const { setFlashMessage, removeFlashMessage, showPaymentModal, hidePaymentModal } = uiSlice.actions;
export const selectUi = (state: RootState): Partial<uiState> => state.ui;
export default uiSlice.reducer;
