import React from 'react';
import cx from 'classnames';

import { Link, LinkProps } from 'react-router-dom';
import { Icon, Text } from '..';
import { Colors } from '../../constants/Colors';
import { IconNames } from '../Icon/IconNames';

interface CardUserListProps {
  to: LinkProps['to'];
  title: string;
  text: string;
  textColor?: Colors;
  className?: string;
  variant?: 'default' | 'bordered';
  afterText?: JSX.Element;
  icon?: IconNames;
}

export const CardUserList: React.FC<CardUserListProps> = (props) => {
  const { to, title, text, textColor = 'Neutral600', variant = 'default', afterText } = props;

  return (
    <div className={cx('Card', 'CardUserList', `CardUserList--${variant}`, props.className)}>
      {props.icon && <Icon name={props.icon} className="CardUserList__typeIcon" color="Neutral500" />}
      <div className="CardUserList__content">
        <Text as="div" size="h3" color="Neutral750" className="CardUserList__title">
          {title}
        </Text>
        <Text as="div" size="body-small" color={textColor} className="CardUserList__text">
          {text}
        </Text>
        {afterText}
      </div>
      {to && (
        <Link to={to} className="CardUserList__link">
          <Icon name="arrowRight" className="CardUserList__icon" />
        </Link>
      )}
    </div>
  );
};
