import cx from 'classnames';
import { Text } from '..';

import { Card } from './Card';

interface CardMessageProps {
  date?: string;
  title?: string;
  className?: string;

  onClick?: () => void;
}

export const CardMessage: React.FC<CardMessageProps> = (props) => {
  return (
    <Card className={cx('CardMessage', props.className)} onClick={props.onClick}>
      {props.date && (
        <>
          <Text size="body-small" color="Neutral600" className="CardMessage__date">
            {props.date}
          </Text>
          <div className="Card__divider"></div>
        </>
      )}
      <div className="CardMessage__content">
        {props.title && (
          <Text size="body-large" weight="500" color="Neutral750" className="CardMessage__title">
            {props.title}
          </Text>
        )}
        {props.children}
      </div>
    </Card>
  );
};
