import { TSFixMe } from '../../frontend-libs/evlapp-types';

export { default as GTM } from './GTM/gtm';
export { default as Money } from './Money/Money';
export { default as downloadFile } from './DownloadFile/DownloadFile';

export const objectToFormData = (obj: TSFixMe, rootName?: string, ignoreList?: TSFixMe[]): TSFixMe => {
  const formData = new FormData();

  function appendFormData(data: TSFixMe, root: TSFixMe) {
    if (!ignore(root)) {
      root = root || '';
      if (data instanceof File) {
        formData.append(root, data);
      } else if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          appendFormData(data[i], root + '[' + i + ']');
        }
      } else if (typeof data === 'object' && data) {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            if (root === '') {
              appendFormData(data[key], key);
            } else {
              appendFormData(data[key], root + '[' + key + ']');
            }
          }
        }
      } else {
        if (data !== null && typeof data !== 'undefined') {
          formData.append(root, data);
        }
      }
    }
  }

  function ignore(root: TSFixMe) {
    return Array.isArray(ignoreList) && ignoreList.some((x) => x === root);
  }

  appendFormData(obj, rootName);

  return formData;
};

export const isPaymentError = (e: TSFixMe) => {
  return e && e.status && e.status === 402 ? true : false;
};
