import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { Button } from '../../Button/Button';
import { Text } from '../../Text/Text';
import { t } from 'i18next';
import { TSFixMe } from '../../../../frontend-libs/evlapp-types';
import { FieldError, FieldErrorProps } from '../Error/FieldError';

export interface FileFieldProps {
  name: string;
  label: string;
  filesLabel?: string;
  multiple?: boolean;
  className?: string;
  formikValue?: TSFixMe;
  reference: React.RefObject<HTMLInputElement>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: FieldErrorProps;
}

export const FileField: React.FC<FileFieldProps> = (props) => {
  const {
    name,
    label,
    multiple = true,
    className,
    reference,
    formikValue = 0,
    filesLabel = 'FileField.filesAction',
    error,
  } = props;

  const [filesCount, setFilesCount] = useState(0);

  useEffect(() => {
    if (!formikValue || formikValue.length === 0) setFilesCount(0);
  }, [formikValue]);

  return (
    <div className={cx('FileField', className)}>
      <input
        name={name}
        ref={reference}
        type="file"
        className={cx('FileField__input')}
        multiple={multiple}
        onChange={(event) => {
          setFilesCount(event.target.files?.length || 0);
          props.onChange(event);
        }}
      />
      <Button type="button" variant="text" iconBefore="upload" title={label} className={cx('FileField__button')} />
      {filesCount > 0 && (
        <Text size="body-small" weight="500" color="Neutral600">
          {t(`${filesLabel}`, undefined, { count: filesCount })}
        </Text>
      )}
      <FieldError {...error} />
    </div>
  );
};
