export const getInitials = (firstName?: string, lastName?: string): string => {
  let initials = '';
  if (firstName?.[0]) initials = firstName[0];
  if (lastName?.[0]) initials += lastName[0];
  return initials;
};

type IgetProfileName = { firstName?: string; lastName?: string; company?: string };
export const getProfileName = ({ firstName, lastName, company }: IgetProfileName, sortByLastName?: boolean): string => {
  let name = '';

  if (sortByLastName) {
    if (lastName) name = lastName;
    if (firstName) name += ' ' + firstName;
  } else {
    if (firstName) name = firstName;
    if (lastName) name += ' ' + lastName;
  }

  if (company && name !== '') name += ', ' + company;
  if (company && name === '') name = company;

  return name;
};

/**
 * @param {string} firstName
 * @param {string} lastName
 * @return {string}
 * @deprecated since version 2.0
 */
export const getFullName = (firstName?: string, lastName?: string): string => {
  return getProfileName({ firstName, lastName });
};
