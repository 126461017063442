import { t } from '../common/i18n';

import {
  Header,
  Text,
  CardMessage,
  RadioField,
  Content,
  TextField,
  Button,
  Modal,
  ButtonLink,
} from '../common/components';
import { useDocumentTitle } from '../common/hooks';
import { useEffect, useState } from 'react';
import { getBuildingSubscriptionsTokens, orderSubscription } from '../Building/services/BuildingService';
import { TSFixMe } from '../frontend-libs/evlapp-types';
import { Money } from '../common/utils';
import { useNavigate } from 'react-router-dom';

export const Notifications: React.FC = () => {
  useDocumentTitle(t('Profile.title'));

  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState<'building-subscription' | 'poll-publish'>('building-subscription');

  const [isLoading, setIsLoading] = useState(true);
  const [isOrdered, setIsOrdered] = useState(false);
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [tokenPriceSubscription, setTokenPriceSubscription] = useState<TSFixMe>(undefined);
  const [tokenPricePollVote, setTokenPricePollVote] = useState<TSFixMe>(undefined);

  const [count, setCount] = useState(1);

  const handleOrder = async () => {
    setIsOrderLoading(true);

    let note = '';
    if (selectedType === 'poll-publish') {
      note = count.toString();
    }

    const data = await orderSubscription({ token: selectedType, context: [], note });
    setIsOrdered(true);
    setIsOrderLoading(false);
  };

  useEffect(() => {
    async function fetch() {
      const data = await getBuildingSubscriptionsTokens();

      if (data?.tokens) {
        const pricePoll = data.tokens.find((item: TSFixMe) => item.type === 'poll-publish');
        if (pricePoll) setTokenPricePollVote(pricePoll);

        const priceSubscription = data.tokens.find((item: TSFixMe) => item.type === 'building-subscription');
        if (priceSubscription) setTokenPriceSubscription(priceSubscription);

        setIsLoading(false);
      }
    }
    fetch();
  }, []);

  return (
    <>
      <Header title="Objednávkový formulář" backlink={-1} />
      <Content isLoading={isLoading} className="Content">
        <div className="CardWrapper">
          <CardMessage className="CardSubscription">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="mt-2 mb-1">
              <RadioField
                name="subscriptionType"
                value="subscription"
                checked={selectedType === 'building-subscription'}
                onChange={(e) => setSelectedType('building-subscription')}
              />
            </div>
            <Text children="Kompletní služby" size="h2" align="center" color="Neutral700" className="mb-auto" />
            <div className="Divider mt-3" />
            <Text color="SystemOrange" weight="600" align="center">
              {Money.format(tokenPriceSubscription?.price)}{' '}
              <Text as="span" size="body-medium" color="Neutral700" weight="400">
                {' '}
                /za rok
              </Text>
            </Text>
          </CardMessage>
          <CardMessage className="CardSubscription">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="mt-2 mb-2">
              <RadioField
                name="subscriptionType"
                checked={selectedType === 'poll-publish'}
                value="pollVote"
                onChange={(e) => setSelectedType('poll-publish')}
              />
            </div>

            <Text
              children={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <TextField
                    name="number"
                    type="number"
                    min={1}
                    max={5}
                    value={`${count}`}
                    onChange={(e) => setCount(Number(e.target.value))}
                    className="CardinputMin"
                  />
                  Jednorázové hlasování
                </div>
              }
              size="h2"
              align="center"
              color="Neutral700"
              className="mb-auto"
            />
            <div className="Divider mt-3" />
            <Text color="SystemOrange" weight="600" align="center">
              {Money.format(tokenPricePollVote?.price)}{' '}
              <Text as="span" size="body-medium" color="Neutral700" weight="400">
                {' '}
                /za hlasování
              </Text>
            </Text>
          </CardMessage>
        </div>

        <CardMessage date="Rekapitulace" className="mt-4 mb-2">
          <Text size="body-medium" color="Neutral700" weight="500" className="mt-2 mb-2">
            {selectedType === 'building-subscription' ? 'Kompletní služby' : 'Jednorázové hlasování'}
          </Text>
          <Text size="body-medium" color="Neutral700" weight="500" className="mb-2">
            Platba bankovním převodem{' '}
            <Text as="span" size="body-medium" color="Neutral600" weight="500">
              (údaje pro platbu obdržíte emailem)
            </Text>
          </Text>
          <div className="Divider"></div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="mb-1">
            <Text size="body-medium" color="Neutral700" weight="700" className="mt-0 mb-0">
              Celková cena za vybrané služby
            </Text>
            <Text size="body-medium" color="Neutral700" weight="700" className="mt-0 mb-0">
              {Money.format(
                selectedType === 'building-subscription'
                  ? tokenPriceSubscription?.price
                  : tokenPricePollVote?.price * count
              )}
            </Text>
          </div>
        </CardMessage>

        <Button title="Objednat služby" onClick={handleOrder} loading={isOrderLoading} />

        <Modal isShown={isOrdered} onHide={() => navigate('/', { replace: false })}>
          <Text size="h2" align="center" className="mb-2">
            Objednávkový formulář byl úspěšně odeslán
          </Text>
          <Text align="center" className="mb-3">
            Nyní se postaráme o zpracování objednávky a v dohledné době vám pošleme fakturu za vybrané služby na váš
            email.
          </Text>
          <div style={{ display: 'flex', justifyContent: 'center' }} className="mt-2">
            <ButtonLink to="/" title="Přejít do aplikace" />
          </div>
        </Modal>
      </Content>
    </>
  );
};

export default Notifications;
