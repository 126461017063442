import { createAsyncThunk as asyncThunk, createSlice } from '@reduxjs/toolkit';

import type { RootState } from './store';

import { getPollById } from '../../Poll/services/PollService';
import { TSFixMe } from '../../frontend-libs/evlapp-types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PollState extends TSFixMe {}

const initialState: Partial<PollState> = {};

export const getPoll = asyncThunk('poll/getById', async (id: string) => {
  return getPollById(id);
});

export const pollSlice = createSlice({
  name: 'poll',
  initialState,
  reducers: {
    setQuestionChoice: (state, { payload }) => {
      const question = state.questions?.find((question: TSFixMe) => question.id === payload.questionId);
      if (question) question.answer = payload.answer;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getPoll.fulfilled, (state, { payload }) => {
      return { ...payload };
    });
  },
});

export const { setQuestionChoice } = pollSlice.actions;
export const selectPoll = (state: RootState): Partial<PollState> => state.poll;
export default pollSlice.reducer;
