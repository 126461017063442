export const icoValidation = (x: string): boolean => {
  let a = 0;
  if (x.length === 0) return true;
  if (x.length !== 8) return false;
  const b = x.split('');
  let c = 0;
  for (let i = 0; i < 7; i++) a += parseInt(b[i]) * (8 - i);
  a = a % 11;
  c = 11 - a;
  if (a === 1) c = 0;
  if (a === 0) c = 1;
  if (a === 10) c = 1;
  if (parseInt(b[7]) !== c) return false;
  return true;
};
