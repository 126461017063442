import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { t } from '../../common/i18n';
import routes from '../../common/constants/routes';
import { ButtonLink, Col, Content, Row, Text } from '../../common/components';
import { IAdminRegistration } from './interfaces';
import { IncompleteData } from './IncompleteData';
import { useEffect, useState } from 'react';
import { getAddressRegisteredStatus } from '../services/RegistrationService';

// import { IAdminRegistration } from '../Registration.d';

// import { IncompleteData } from './IncompleteData';

export const BuildingConfirm: React.FC<{ state: IAdminRegistration }> = (props) => {
  const namespace = 'AdminRegistration.BuildingConfirm';

  const { state } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [isRegistered, setIsRegistered] = useState(false);

  useEffect(() => {
    async function fetch() {
      const status = await getAddressRegisteredStatus(state?.building?.addressId || '');
      setIsRegistered(status.isRegistered);
      setIsLoading(false);
    }
    fetch();
  }, [state.building.addressId]);

  const address = state?.building?.address;
  const isValidType = state?.building?.type === 'other' ? false : true;

  // Return page with incomplete date
  if (!address) return <IncompleteData />;

  return (
    <Content isLoading={isLoading}>
      <div className="OnboardingForm">
        <Row>
          <Col className="mb-4">
            <Text size="h1" className="mb-4">
              {isValidType ? (
                <Trans i18nKey={`${namespace}.${!isRegistered ? 'heading' : 'headingRegistered'}`}>
                  <span className="text SystemOrange">{{ address }}</span>
                </Trans>
              ) : (
                <Trans i18nKey={`${namespace}.headingNotValidType`}>
                  <span className="text SystemOrange">{{ address }}</span>
                </Trans>
              )}
            </Text>
            <Text size="body-large" weight="500" color="Neutral700">
              {isValidType
                ? t(`${isRegistered ? 'subheadingRegistered' : 'subheading'}`, namespace)
                : t(`subheadingNotValidType`, namespace)}
            </Text>
          </Col>
        </Row>

        <Row>
          <Col className="mb-2">
            <ButtonLink
              title={t(`${isRegistered || !isValidType ? 'prevStep' : 'nextStep'}`, namespace)}
              to={{ pathname: `${routes.registration}${isRegistered || !isValidType ? '' : 'legal-entity/'}` }}
              state={state}
            />
          </Col>
        </Row>

        {!isRegistered && isValidType && (
          <Row>
            <Col>
              <div className="ml-auto mr-auto">
                <Link className="text-body-large Primary500 Weight500" to={{ pathname: routes.registration }}>
                  {t('prevStep', namespace)}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Content>
  );
};
