import React, { forwardRef } from 'react';
import { Icon, Tooltip } from '..';
import { isDemo } from '../../constants/common';
import { useAppSelector } from '../../hooks';
import { t } from '../../i18n';
import { selectSecurity } from '../../store/securitySlice';
import { IconNames } from '../Icon/IconNames';
import { Spinner } from '../Spinner/Spinner';
import { buttonClassNames } from './util';

export interface ButtonProps {
  title: string;

  size?: 'default' | 'small';
  variant?: 'primary' | 'primary-light' | 'secondary' | 'secondary-outline' | 'text';
  wide?: boolean;
  rounded?: boolean;

  disabled?: boolean;
  disabledDeps?: string[];
  loading?: boolean;
  type?: 'submit' | 'button';
  iconBefore?: IconNames;
  iconAfter?: IconNames;
  className?: string;

  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = forwardRef((props, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const security = useAppSelector(selectSecurity).ui;
  const isAfterGracePeriod = security.subscription.isAfterGracePeriod;

  const {
    title,
    variant = 'primary',
    wide = false,
    rounded = false,
    size = 'default',
    disabled = false,
    loading = false,
    className,
    type = 'button',
    iconBefore,
    iconAfter,
    disabledDeps,
    ...rest
  } = props;

  let isDisabled = false;
  let disabledText: string | boolean = false;
  if (disabledDeps?.includes('demo') && isDemo) {
    disabledText = t('App.demoAvailability');
    isDisabled = true;
  }

  if (disabledDeps?.includes('expired') && isAfterGracePeriod) {
    disabledText = t('App.afterGracePeriod');
    isDisabled = true;
  }

  const classNames = buttonClassNames({ variant, wide, size, rounded, isDisabled, disabled, loading, className });

  return (
    <Tooltip text={disabledText}>
      <button className={classNames} type={type} ref={ref} disabled={isDisabled || disabled || loading} {...rest}>
        {iconBefore && <Icon name={iconBefore} className="Button__icon Button__icon--before" />}
        <span className="Button__label">{title}</span>
        {iconAfter && <Icon name={iconAfter} className="Button__icon Button__icon--after" />}

        {loading && (
          <div className="Button__spinner">
            <Spinner size="small" />
          </div>
        )}
      </button>
    </Tooltip>
  );
});
