import cx from 'classnames';

import { TSFixMe } from '../../../frontend-libs/evlapp-types';
import { useNavigate } from 'react-router-dom';

import routes from '../../constants/routes';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { changeBuilding, selectSecurity } from '../../store/securitySlice';

import { Text } from '../Text/Text';

interface SidebarBuildingListProps {
  buildings: TSFixMe[];
  onHide: () => void;
}

export const SidebarBuildingList: React.FC<SidebarBuildingListProps> = (props: SidebarBuildingListProps) => {
  const { buildings, onHide } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ui = useAppSelector(selectSecurity).ui;

  const handleBuildingChange = (building: TSFixMe) => {
    dispatch(changeBuilding(building));
    navigate(routes.root);
    onHide();
  };

  const list = buildings.map((building: TSFixMe) => {
    const isActive = building.buildingId === ui.buildingId;

    return (
      <div
        key={building.buildingId}
        className={cx('SidebarUnit__building', isActive && 'SidebarUnit__building--active')}
      >
        <Text color="Inherit" onClick={() => handleBuildingChange(building)}>
          {building.address}
        </Text>
      </div>
    );
  });

  return <>{list}</>;
};
