import { useState } from 'react';
import { AsyncSelectField, ButtonLink, Col, Row, Text } from '../../common/components';
import routes from '../../common/constants/routes';
import { t } from '../../common/i18n';
import { getBuildingOptions } from '../utils/AddressAutocomplete';
import { IAdminRegistration, IAdminBuildingSearch } from './interfaces';

export const BuildingSearch: React.FC = () => {
  const namespace = 'AdminRegistration.BuildingSearch';

  const [buildingAddress, setBuildingAddress] = useState<IAdminRegistration['building']>({});

  return (
    <div className="OnboardingForm">
      <Row>
        <Col className="mb-4">
          <Text size="h1">{t('heading', namespace)}</Text>
        </Col>
      </Row>
      <Row>
        <Col className="mb-4">
          <AsyncSelectField
            label={t('Form.Fields.AddressId.label', namespace)}
            placeholder={t('Form.Fields.AddressId.placeholder', namespace)}
            cacheOptions
            loadOptions={getBuildingOptions}
            onChange={(v: IAdminBuildingSearch | null) => {
              if (v) setBuildingAddress(v);
            }}
            getOptionLabel={(option) => option.address || ''}
            getOptionValue={(option) => option.addressId || ''}
            noOptionsMessage={(v) => {
              const { inputValue } = v;
              if (inputValue.length > 3) return t('Form.Fields.AddressId.noData', namespace);
              return t('Form.Fields.AddressId.minLength', namespace);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonLink
            title={t('Form.Fields.Submit.title', namespace)}
            disabled={!buildingAddress.addressId}
            to={{ pathname: `${routes.registration}building-confirm/` }}
            state={{ building: buildingAddress }}
          />
        </Col>
      </Row>
    </div>
  );
};
