import cx from 'classnames';
import { FormFieldProps } from '../Base';
import { FieldError } from '../Error/FieldError';

interface CheckboxFieldProps<T> extends FormFieldProps<string> {
  onChange: (e: React.ChangeEvent<T>) => void;
  className?: string;
  variant?: 'checkbox' | 'switch';
}

export const CheckboxField: React.FC<CheckboxFieldProps<HTMLInputElement>> = (props) => {
  const { variant = 'checkbox' } = props;
  const id = props.id || props.name;

  return (
    <div className={cx('CheckboxField', `CheckboxField--${variant}`, props.className)}>
      <label className="CheckboxField__inner" htmlFor={id}>
        <input id={id} name={props.name} type="checkbox" className="CheckboxField__input" onChange={props.onChange} />
        <div className="CheckboxField__control"></div>
        {props.label && <span className="CheckboxField__label">{props.label}</span>}
      </label>
      <FieldError {...props.error} />
    </div>
  );
};
