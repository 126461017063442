import { TSFixMe } from '../../frontend-libs/evlapp-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Text } from '../../common/components';
import { t } from '../../common/i18n';
import { setFlashMessage } from '../../common/store/uiSlice';
import { deleteFile } from '../services/FilesService';
import { isPaymentError } from '../../common/utils';

interface ModalDeleteProps {
  id: string;
  isShown: boolean;
  onHide: TSFixMe;
  handleFetchFiles: TSFixMe;
}

export const ModalDelete: React.FC<ModalDeleteProps> = (props) => {
  const namespace = 'Files.ModalDelete';

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    if (!props.id) props.onHide();

    setIsLoading(true);

    deleteFile(props.id)
      .then(() => {
        props.handleFetchFiles().then(() => {
          dispatch(setFlashMessage({ message: t('Flash.success', namespace), type: 'success' }));
          setIsLoading(false);
          props.onHide();
        });
      })
      .catch((e) => {
        if (!isPaymentError(e)) {
          dispatch(setFlashMessage({ message: t('Flash.error', namespace), type: 'error' }));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal isShown={props.isShown} onHide={props.onHide}>
      <Text size="h2" align="center" className="mb-4">
        {t('title', namespace)}
      </Text>

      <div className="Flex FlexColumn FlexAlign--center">
        <Button
          title={t('submit', namespace)}
          onClick={handleDelete}
          loading={isLoading}
          wide
          disabledDeps={['demo', 'expired']}
        />
      </div>
    </Modal>
  );
};
