import { TSFixMe } from '../../../frontend-libs/evlapp-types';
import cx from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { LinkProps } from 'react-router-dom';
import { t } from '../../i18n';
import { IconNames } from '../Icon/IconNames';
import { Text, Icon, Button } from '../index';

import { Card } from './Card';

interface CardQuestionProps {
  title: string;
  label?: string;
  detailLink?: LinkProps['to'];
  icon?: IconNames;
  className?: string;
  answer?: 'yes' | 'no' | 'undecided';
  onYes?: () => void;
  onNo?: () => void;
  onUndecided?: () => void;
  isEditable?: boolean;
}

export const CardQuestion: React.FC<CardQuestionProps> = (props) => {
  const { answer, isEditable } = props;
  const hasSubHeader = props.label || props.detailLink;

  const [isLoading, setIsLoading] = useState<CardQuestionProps['answer'] | null>(null);

  const handleVote = (callback: TSFixMe, type: CardQuestionProps['answer']) => {
    if (!isEditable) return;
    setIsLoading(type);
    callback().then(() => setIsLoading(null));
  };

  return (
    <div className="Question">
      {hasSubHeader && (
        <div className="CardQuestionLabel">
          {props.label && (
            <Text as="span" className="text-uppercase CardQuestionLabel__title" color="Neutral700">
              {props.label}
            </Text>
          )}
          {props.detailLink && (
            <Link className="CardQuestionLabel__link" to={props.detailLink}>
              {t('Card.Question.detailLink')} <Icon name="info" />
            </Link>
          )}
        </div>
      )}
      <Card className={cx('CardQuestion', props.className)}>
        <div className="CardQuestion__header mb-1">
          <Text as="span" size="h3">
            {props.title}
          </Text>
        </div>
        <div className="CardQuestionAnswers">
          <div className="CardQuestionAnswer">
            <Button
              variant="primary-light"
              className={cx('Button--question-yes', answer === 'yes' && 'is-active')}
              title={t('Card.Question.yes')}
              onClick={() => handleVote(props.onYes, 'yes')}
              loading={isLoading === 'yes'}
              size="small"
              disabled={!isEditable}
              disabledDeps={['expired']}
            />
          </div>
          <div className="CardQuestionAnswer">
            <Button
              variant="primary-light"
              className={cx('Button--question-no', answer === 'no' && 'is-active')}
              title={t('Card.Question.no')}
              onClick={() => handleVote(props.onNo, 'no')}
              loading={isLoading === 'no'}
              size="small"
              disabled={!isEditable}
              disabledDeps={['expired']}
            />
          </div>
          <div className="CardQuestionAnswer">
            <Button
              variant="primary-light"
              className={cx('Button--question-undecided', answer === 'undecided' && 'is-active')}
              title={t('Card.Question.undecided')}
              onClick={() => handleVote(props.onUndecided, 'undecided')}
              loading={isLoading === 'undecided'}
              size="small"
              disabled={!isEditable}
              disabledDeps={['expired']}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
