import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { useEditor, EditorContent } from '@tiptap/react';
import { Editor as CoreEditor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import { Icon } from '../Icon/Icon';
import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';
import { t } from '../../i18n';
import { Modal } from '../Modal/Modal';
import { TextField } from '../Form/TextField/TextField';
import { useModal } from '../Modal/useModal';
import { Button } from '../Button/Button';
import { Text } from '../Text/Text';
import { LabelBaseProps } from '../Form/Base';
import { Label } from '../Form/Label/Label';
import { FieldError, FieldErrorProps } from '../Form/Error/FieldError';

interface EditorProps extends LabelBaseProps {
  content: string;
  isEditable?: boolean;
  onChange?: (editor: CoreEditor | null) => void;
  className?: string;
  required?: boolean;
  error?: FieldErrorProps;
  variant?: 'default' | 'bordered';
}

const MenuBar = (props: { editor: CoreEditor }) => {
  const { editor } = props;

  const [isShown, toggle] = useModal();
  const [linkValue, setLinkValue] = useState('');

  const toggleLinkModal = () => {
    const previousUrl = editor.getAttributes('link').href;
    setLinkValue(previousUrl);
    toggle();
  };

  const setLink = useCallback(() => {
    if (linkValue === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
    } else {
      editor.chain().focus().extendMarkRange('link').setLink({ href: linkValue, target: '_blank' }).run();
    }

    toggle();
  }, [editor, toggle, linkValue]);

  if (!editor) return null;

  return (
    <>
      <div className="Editor__menubar">
        <Icon
          name="editor-bold"
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={cx('Editor__menubar-item', editor.isActive('bold') && 'Editor__menubar-item--active')}
        />

        <Icon
          name="editor-italic"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={cx('Editor__menubar-item', editor.isActive('italic') && 'Editor__menubar-item--active')}
        />
        <Icon
          name="editor-link"
          onClick={toggleLinkModal}
          className={cx('Editor__menubar-item', editor.isActive('link') && 'Editor__menubar-item--active')}
        />

        {/*
        <Icon
          name="editor-paragraph"
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={cx(
            'Editor__menubar-item',
            editor.isActive('heading', { level: 1 }) && 'Editor__menubar-item--active'
          )}
        />
      */}

        <Icon
          name="editor-h1"
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={cx(
            'Editor__menubar-item',
            editor.isActive('heading', { level: 1 }) && 'Editor__menubar-item--active'
          )}
        />

        <Icon
          name="editor-h2"
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={cx(
            'Editor__menubar-item',
            editor.isActive('heading', { level: 2 }) && 'Editor__menubar-item--active'
          )}
        />

        <Icon
          name="editor-h3"
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={cx(
            'Editor__menubar-item',
            editor.isActive('heading', { level: 3 }) && 'Editor__menubar-item--active'
          )}
        />

        <Icon
          name="editor-h4"
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={cx(
            'Editor__menubar-item',
            editor.isActive('heading', { level: 4 }) && 'Editor__menubar-item--active'
          )}
        />

        <Icon
          name="editor-list-ul"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={cx('Editor__menubar-item', editor.isActive('bulletList') && 'Editor__menubar-item--active')}
        />

        <Icon
          name="editor-list-ol"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={cx('Editor__menubar-item', editor.isActive('orderedList') && 'Editor__menubar-item--active')}
        />

        {/*
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={editor.isActive('strike') ? 'Editor__menubar-item--active')}
      >
        strike
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        className={editor.isActive('code') ? 'Editor__menubar-item--active')}
      >
        code
      </button>
      <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>clear marks</button>
      <button onClick={() => editor.chain().focus().clearNodes().run()}>clear nodes</button>
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={editor.isActive('paragraph') ? 'Editor__menubar-item--active')}
      >
        paragraph
      </button>

      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive('codeBlock') ? 'Editor__menubar-item--active')}
      >
        code block
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'Editor__menubar-item--active')}
      >
        blockquote
      </button>
      <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>horizontal rule</button>
      <button onClick={() => editor.chain().focus().setHardBreak().run()}>hard break</button>
      <button onClick={() => editor.chain().focus().undo().run()}>undo</button>
      <button onClick={() => editor.chain().focus().redo().run()}>redo</button>
      */}
      </div>
      <Modal isShown={isShown} onHide={toggle}>
        <Text size="h2" align="center" className="mb-3">
          {t('Editor.Link.title')}
        </Text>
        <TextField
          label={t('Editor.Link.label')}
          name="link"
          value={linkValue}
          onChange={(e) => setLinkValue(e.target.value)}
          className="mb-2"
        />
        <div className="Flex FlexAlign--center FlexJustify--center">
          <Button onClick={setLink} title={t('Editor.Link.submit')} />
        </div>
      </Modal>
    </>
  );
};

export const Editor: React.FC<EditorProps> = (props) => {
  const { isEditable = true, label, afterLabel, required, tooltip, error, variant = 'default' } = props;
  const [showMenuBar, setMenuBar] = useState(isEditable);

  const editor = useEditor({
    editable: isEditable,
    content: props.content,
    extensions: [
      StarterKit.configure({
        strike: false,
        blockquote: false,
        codeBlock: false,
        code: false,
        dropcursor: false,
        gapcursor: false,
      }),
      Link.configure({
        openOnClick: false,
      }),
      Placeholder.configure({
        placeholder: t('Editor.placeholder'),
      }),
    ],
    onUpdate({ editor }) {
      if (props.onChange) props.onChange(editor);
    },
  });

  useEffect(() => {
    if (!editor) return undefined;

    setMenuBar(isEditable);
    editor.setEditable(isEditable);
  }, [editor, isEditable]);

  if (!editor) return null;

  return (
    <>
      {label && (
        <Label
          classNamePrefix="TextField"
          label={label}
          afterLabel={afterLabel}
          required={required}
          tooltip={tooltip}
        />
      )}
      <div className={cx('Editor', `Editor--${variant}`, props.className)}>
        {showMenuBar && <MenuBar editor={editor} />}
        <EditorContent editor={editor} className="Editor__content" />
      </div>

      <FieldError {...error} />
    </>
  );
};
