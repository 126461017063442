import cx from 'classnames';

import { Card } from './Card';
import { Avatar, Text, Phone, Email } from '../index';
import { getFullName, getInitials } from '../../utils/Profile/profile';
import { t } from '../../i18n';

interface CardContactProps {
  firstName?: string;
  lastName?: string;
  company?: string;
  position?: string;
  phone?: string;
  email?: string;
  action?: JSX.Element;
  afterStart?: JSX.Element;
  beforeEnd?: JSX.Element;
  className?: string;
}

export const CardContact: React.FC<CardContactProps> = (props) => {
  const { firstName, lastName, position, phone, email } = props;

  const fullName = getFullName(firstName, lastName) || props.company;
  const initials = getInitials(firstName, lastName) || getInitials(props.company);

  return (
    <Card className={cx('CardContact', props.className)}>
      {props.afterStart}
      {initials && (
        <div className="CardContactAvatar">
          <Avatar initials={initials} size="large" />
        </div>
      )}

      <div className="CardContactContent">
        <div className="CardContactDetails">
          <Text size="body-medium" weight="600" color="Neutral700" className="CardContactName">
            {fullName ? fullName : t('Card.Contact.emptyFullName')}
          </Text>

          {position && (
            <Text size="body-small" weight="500" color="Neutral600" className="CardContactPosition">
              {position}
            </Text>
          )}
        </div>
        <div className="CardContactActions">
          {phone && <Phone number={phone} />}
          {email && <Email email={email} />}
          {props.action}
        </div>
      </div>
      {props.beforeEnd}
    </Card>
  );
};
