import { TSFixMe, IPutReqUserProfile } from '../../frontend-libs/evlapp-types';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Text, Button, PhoneField, FormMessageProps, FormMessage } from '../../common/components';

import { useAppSelector, useAppThunkDispatch } from '../../common/hooks';

import { t } from '../../common/i18n';
import { selectSecurity, updateProfile } from '../../common/store/securitySlice';
import Yup, { backendViolations, YupPhone } from '../../common/utils/FormValidation';

interface Step3Props {
  handleRequestCode: () => void;
  setStep: TSFixMe;
}

export const Step3: React.FC<Step3Props> = (props) => {
  const data = useAppSelector(selectSecurity).profile;

  const thunkDispatch = useAppThunkDispatch();
  const [formMessage, setFormMessage] = useState<FormMessageProps>({ message: '', type: 'error' });

  const formik = useFormik<IPutReqUserProfile>({
    initialValues: {
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      phone: data?.phone || '',
      email: data?.email || '',
      birthDate: data?.birthDate,
    },

    validationSchema: Yup.object({ phone: YupPhone }),

    onSubmit: (values) => {
      thunkDispatch(updateProfile(values))
        .then(props.handleRequestCode)
        .catch((error) => {
          backendViolations(formik, error);
          setFormMessage({ message: t('Poll.Question.Send.StepPhone.error'), type: 'error' });
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <>
      <Text size="h2" color="Neutral800" className="mb-1" align="center">
        {t('Poll.Question.Send.StepPhone.title')}
      </Text>
      <Text size="body-medium" color="Neutral700" weight="500" align="center" className="mb-3">
        {t('Poll.Question.Send.StepPhone.subtitle')}
      </Text>
      <form onSubmit={formik.handleSubmit}>
        {formMessage.message && <FormMessage {...formMessage} className="mb-2" variant="dark" />}
        <PhoneField
          name="phone"
          label={t('Profile.Edit.Form.Fields.Phone.label')}
          placeholder={t('Profile.Edit.Form.Fields.Phone.placeholder')}
          value={formik.values.phone}
          onChange={(phone) => formik.setFieldValue('phone', phone)}
          error={{ message: formik.errors.phone, touched: formik.touched.phone }}
          className="mb-2"
        />
      </form>
      <div className="Flex FlexAlign--center FlexJustify--center">
        <Button
          variant="primary"
          wide={true}
          title={t('Poll.Question.Send.StepPhone.submit')}
          onClick={formik.handleSubmit}
          loading={formik.isSubmitting}
        />
      </div>
    </>
  );
};
