import React from 'react';

import cx from 'classnames';
import { Icon, Text } from '..';

export interface FlashMessageProps {
  id?: string;
  message?: string;
  action?: React.ReactNode;
  type?: 'success' | 'error' | 'warning';
  placement?: 'default' | 'content';
  timeout?: number;
  className?: string;
  handleOnClose?: () => void;
}

export const FlashMessage: React.FC<FlashMessageProps> = (props) => {
  const { message, type = 'error', placement = 'default', action = null, className } = props;

  return (
    <div className={cx('FlashMessage', `FlashMessage--${placement}`, `FlashMessage--${type}`, className)}>
      <div className="FlashMessage__inner">
        <Text size="body-medium" weight="600" className="FlashMessage__message" color="Inherit">
          {message}
        </Text>
        {action === null ? (
          <Icon className="FlashMessage__close" name="times" color="Inherit" onClick={props.handleOnClose} />
        ) : (
          action
        )}
      </div>
    </div>
  );
};
