import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import { Icon, Spinner } from '../index';

export interface ModalProps {
  isShown: boolean;
  onHide: () => void;
  enableOutsideClose?: boolean;
  closeButton?: boolean;
  isLoading?: boolean;
  size?: 'default' | 'small' | 'large';

  className?: string;
}

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    isShown,
    onHide,
    closeButton = true,
    enableOutsideClose = false,
    isLoading = false,
    size = 'default',
    className,
  } = props;

  const ref = useRef(null);
  const [fade, setFade] = useState<'in' | 'out' | null>(null);

  useEffect(() => {
    setTimeout(() => setFade(isShown ? 'in' : 'out'), 0);
    if (!isShown) setTimeout(() => setFade(null), 150);
  }, [isShown]);

  if (!isShown && !fade) return null;

  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!ref?.current) return;
    if (enableOutsideClose && e.target === ref.current && fade === 'in') onHide();
  };

  const modal = (
    <div
      className={cx('Modal', `Modal--${fade}`, `Modal--${size}`, isLoading && `Modal--loading`, className)}
      onClick={(e) => handleClickOutside(e)}
      ref={ref}
    >
      <div className="Modal__inner">
        {isLoading && <Spinner position="overlay" />}
        {closeButton && <Icon name="times" className="Modal__close" onClick={onHide} />}
        {!isLoading && <div className="Modal__content">{props.children}</div>}
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.querySelector('#overlay') as Element);
};

export const ModalDivider: React.FC = () => {
  return <hr className="Modal__divider" />;
};
