export const WEBURL = 'https://www.evlastnici.cz';
export const FLASH_MESSAGE_TIMEOUT = 3500;

export const POLL_END_MIN_DAYS = 15;
export const POLL_PUBLIC_MIN_DAYS = 1;

export const isDemo = process.env.REACT_APP_DEMO ? true : false;

export const demoUserCredentials = { email: 'jaroslav.kasparek@evlastnici.cz ', password: 'demo' };
export const demoAdminCredentials = { email: 'demo@evlastnici.cz', password: 'demo' };
