import cx from 'classnames';
import React from 'react';
import { FormFieldProps } from '../Base';

import { FieldError } from '../Error/FieldError';
import { Label } from '../Label/Label';

export interface TextAreaFieldProps<T> extends FormFieldProps<string> {
  placeholder?: string;
  className?: string;
  autocomplete?: string;
  maxlength?: number;
  minlength?: number;
  onBlur?: (e: React.ChangeEvent<T>) => void;
  onChange?: (e: React.ChangeEvent<T>) => void;
  onKeyPress?: (e: React.KeyboardEvent<T>) => void;
}

export const TextAreaField: React.FC<TextAreaFieldProps<HTMLTextAreaElement>> = (props) => {
  const {
    id = props.name,
    name,
    value,
    autocomplete,
    className,
    label,
    afterLabel,
    error,
    maxlength,
    minlength,
    ...rest
  } = props;

  const inputValue = value || '';

  return (
    <div className={cx('TextField', 'TextField--textarea', className)}>
      {label && (
        <Label classNamePrefix="TextField" id={id} label={label} afterLabel={afterLabel} required={props.required} />
      )}
      <textarea
        className="TextField__control"
        name={name}
        id={id}
        value={inputValue}
        autoComplete={autocomplete}
        maxLength={maxlength}
        minLength={minlength}
        {...rest}
      />
      <FieldError {...error} />
    </div>
  );
};
