export default {
  Detail: {
    title: 'Můj profil',
    Header: {
      title: 'Můj profil',
      Toggle: {
        member: 'Přepnout na správcový účet',
        admin: 'Přepnout na uživatelský účet',
      },
    },
    editProfile: 'Upravit profil',
    OtherSettings: {
      title: 'Ostatní nastavení',
      appNotifications: 'Notifikace aplikace',
      emailNotifications: 'Emailové notifikace',
      agreeWithNewsletter: 'Souhlasím s odběrem novinek',
    },
    Links: {
      passwordChange: 'Změnit heslo',
      notifications: 'Notifikace',
      logout: 'Odhlásit se',
      generalQuestions: 'Obecné dotazy',
      technicalSupport: 'Technická podpora',
    },
  },
  Edit: {
    title: 'Upravit profil',
    Header: { title: 'Upravit profil' },
    Form: {
      Flash: {
        success: 'Úprava profilu proběhla úspěšně.',
        error: 'Úprava profilu neproběhla úspěšně, zkuste to prosím znovu.',
      },
      Fields: {
        Avatar: {
          label: 'Změnit profilovou fotku',
        },
        FirstName: {
          label: 'Jméno',
          placeholder: 'Vaše jméno',
          required: 'Jméno musí být vyplněné',
        },
        LastName: {
          label: 'Příjmení',
          placeholder: 'Vaše příjmení',
          required: 'Příjmení musí být vyplněné',
        },
        Email: {
          label: 'Email',
          placeholder: 'Zadejte váš email',
        },
        Phone: {
          label: 'Telefon',
          placeholder: 'Zadejte vaše telefonní číslo, např. +420 xxx xxx xxx',
          required: 'Telefon je povinný',
        },
        Birthday: {
          label: 'Datum narození',
          placeholder: 'Zadejte vaše datum narození',
        },
        Password: {
          label: 'Nové heslo',
          placeholder: 'Zadejte vaše heslo',
        },
        PasswordCheck: {
          label: 'Nové heslo znovu',
          placeholder: 'Zadejte nové heslo znovu',
          matches: 'Hesla se musí shodovat',
        },
        Submit: {
          title: 'Uložit změny',
        },
      },
    },
    Verification: {
      title: 'Potvrďte úpravy',
      subtitle: 'Zadejte verifikační kód který vám dorazil na email nebo sms zprávou.',
      smsIsMissing: 'Kód vám nedorazil',
      smsSendAgain: 'Odeslat kód znovu',
      smsSendAgainTimer: 'Kód bude možné znovu odeslat za',
      confirmButton: 'Potvrdit úpravy',
    },
  },

  ChangePassword: {
    title: 'Změna hesla',
    Header: { title: 'Změna hesla' },
    Form: {
      success: 'Heslo bylo změněno',
      error: 'Heslo se nepodařilo změnit, prosím zkontrolujte vámi zadané údaje.',
      Fields: {
        CurrentPassword: {
          label: 'Aktuální heslo',
          placeholder: 'Zadejte své aktuální heslo',
        },
        NewPassword: {
          label: 'Nové heslo',
          placeholder: 'Zadejte své nové heslo',
        },
        NewPasswordCheck: {
          label: 'Nové heslo znovu',
          placeholder: 'Zadejte své nové heslo pro kontrolu',
          matches: 'Hesla se musí shodovat',
        },
        Submit: {
          title: 'Změnit heslo',
        },
      },
    },
  },
};
