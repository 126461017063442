export default {
  title: 'Detail domu',

  demoCategory: 'Dům',
  demoType: 'Společenství vlastníků jednotek',

  BuildingInfo: {
    isLoading: 'Probíhá náčítání informací o budově…',
    category: 'Kategorie',
    type: 'Typ',
    admins: 'Administrátor',
  },

  Detail: {
    committeMembers: 'Členové výboru',
    controlAuthority: 'Kontrolní orgán',
    externalContacts: 'Ostatní kontakty',
    emptyContactList: 'V této kategorii nejsou žádní členové.',
    isLoading: 'Probíhá načítání kontaktů…',
    updateContacts: 'Upravit kontakty',
  },

  ContactDetail: {
    title: 'Kontakty budovy',
    Header: {
      primaryAction: 'Vytvořit nový kontakt',
    },
    Modal: {
      Form: {
        Flash: {
          successAdd: 'Kontakt byl úspěšně vytvořen.',
          successEdit: 'Kontakt byl úspěšně upraven.',
          successDelete: 'Kontakt byl úspěšně smazán.',
          errorAdd: 'Kontakt se nepodařilo vytvořit.',
          errorEdit: 'Kontakt se nepodařilo upravit.',
        },
        Fields: {
          FirstName: {
            label: 'Jméno',
            placeholder: 'Zadejte jméno kontaktu',
            required: 'Jméno musí být vyplněné',
          },
          LastName: {
            label: 'Příjmení',
            placeholder: 'Zadejte příjmení kontaktu',
            required: 'Příjmení musí být vyplněné',
          },
          Company: {
            label: 'Společnost',
            placeholder: 'Zadejte název společnosti kontaktu',
            required: 'Název společnosti můsí být vyplněn',
          },
          CompanyId: {
            label: 'IČO SpolečnostI',
            placeholder: 'Zadejte IČO společnosti kontaktu',
            required: 'IČO společnosti můsí být vyplněn',
          },
          Email: {
            label: 'Email',
            placeholder: 'Zadejte email kontaktu',
          },
          Phone: {
            label: 'Telefon',
            placeholder: 'Zadejte telefonní číslo kontaktu, např. +420 xxx xxx xxx',
            required: 'Telefon je povinný',
          },
          CommitteUserType: {
            label: 'Vyberte funkci',
            chairman: 'Předseda výboru',
            deputyChairman: 'Místopředseda výboru',
            member: 'Člen komise',
          },
          Submit: {
            title: 'Uložit změny',
            titleCreate: 'Vytvořit',
          },
        },
      },
      Delete: {
        title: 'Opravdu chcete smazat kontakt',
        confirm: 'Ano, smazat',
      },
    },
  },

  Status: {
    FetchingFailed: {
      heading: 'Nepodařilo se načíst budovu',
      subheading: 'Nepodařilo se načíst data k vaší budově, prosím kontaktujte technickou podporu',
      buttonTitle: 'Kontaktovat podporu',
    },
    Loading: {
      string1: 'Právě pro vás připravujeme vaší budovu',
      string2: 'Shromažďujeme veškerá potřebná data',
      string3: 'Stahujeme data z katastru nemovitostí',
      string4: 'Dokončujeme stahování',
    },
  },
};
