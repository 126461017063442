import { useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router';

import { Header, Spinner } from '../../common/components';
import routes from '../../common/constants/routes';
import { useAppSelector, useAppThunkDispatch, useDocumentTitle } from '../../common/hooks';
import { t } from '../../common/i18n';
import { getPoll, selectPoll } from '../../common/store/pollSlice';

// import { TabInfo } from './TabInfo';
import { TabDetail } from './TabDetail';

export const PollDetail: React.FC = () => {
  useDocumentTitle(t('Poll.title'));

  const { pollId = null } = useParams();
  const dispatch = useAppThunkDispatch();
  const poll = useAppSelector(selectPoll);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (pollId === null) return;
    dispatch(getPoll(pollId))
      .unwrap()
      .then(() => setIsLoading(false));
  }, [pollId, dispatch]);

  if (isLoading) return <Spinner size="large" position="overlay" />;

  return (
    <>
      <Header
        title={poll.name || ''}
        customBacklink={`${routes.poll}`}
        tabs={[
          { label: 'Hlasování', to: '' },
          /* { label: 'Popis hlasování a přílohy', to: 'info' }, */
        ]}
      />

      <div className="Content">
        <Routes>
          <Route index element={<TabDetail />} />
          {/* <Route path="info" element={<TabInfo />} /> */}
        </Routes>
      </div>
    </>
  );
};

export default PollDetail;
