import { useLayoutEffect, useState } from 'react';
import { ThunkDispatch, Action } from '@reduxjs/toolkit';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../store/store';
import { t } from '../i18n';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();

export const useDocumentTitle = (title: string): void => {
  const [documentTitle] = useState(title);

  useLayoutEffect(() => {
    const title = documentTitle ? `${t('App.title')} - ${documentTitle}` : `${t('App.title')}`;
    document.title = title;
  }, [documentTitle]);
};
