import { ButtonLink, Card, CardContact, Content, Header, Spinner, Text } from '../common/components';
import routes from '../common/constants/routes';
import { useAppSelector, useDocumentTitle } from '../common/hooks';
import { t } from '../common/i18n';

import { TSFixMe } from '../frontend-libs/evlapp-types';

import { useEffect, useState } from 'react';
import {
  getBuildingCommitte,
  getBuildingControlAuthority,
  getBuildingExternalContacts,
  getBuildingInfo,
} from './services/BuildingService';
import { getProfileName } from '../common/utils/Profile/profile';
import { selectSecurity } from '../common/store/securitySlice';

const buildingAddresses = (data: TSFixMe[]) => {
  const addresses: string[] = [];

  data.forEach((building) => {
    if (building.name) addresses.push(building.name);
  });
  return addresses.join(', ');
};

const adminsList = (data: TSFixMe[]) => {
  const admins: string[] = [];

  data.forEach((admin) => {
    if (!admin.profile) return;

    const fullName = getProfileName({ ...admin.profile }) || admin.profile.email;
    return admins.push(fullName);
  });
  return admins.join(', ');
};

const contactList = ({ data, isLoading }: TSFixMe) => {
  if (data.length === 0 || isLoading) {
    return (
      <Card className="Card--content">
        <Text as="div" className="Flex" size="body-medium" color="Neutral600" weight="400">
          {!isLoading && t('Building.Detail.emptyContactList')}
          {isLoading && (
            <>
              <Spinner size="small" className="mr-1" /> {t('Building.Detail.isLoading')}
            </>
          )}
        </Text>
      </Card>
    );
  }

  return data.map((contact: TSFixMe, i: number) => {
    const isLast = i === data.length - 1;

    let position: null | string = null;
    if (contact.committeUserType) position = t(`Enums.ContactPositionsTypes.${contact.committeUserType}`);

    return <CardContact key={contact.id} {...contact} position={position} className={isLast && 'mb-0'} />;
  });
};

const unitList = (units: TSFixMe[]) => {
  return units.map((unit, i) => {
    const isLast = i === units.length - 1;
    const { useType, label } = unit;

    return (
      <div key={unit.id} className={`Flex FlexAlign--center FlexJustify--space-between ${!isLast ? 'mb-2' : ''} `}>
        <Text size="h3" color="Neutral750">
          {t('Units.Table.Body.unit')} {label}
        </Text>
        <Text size="body-medium" color="Neutral700" weight="500">
          {t(`Enums.UnitUseType.${useType}`)}
        </Text>
      </div>
    );
  });
};

export const BuildingDetail: React.FC = () => {
  useDocumentTitle(t('Building.title'));

  const security = useAppSelector(selectSecurity).ui;
  const isAdmin = security.isAdmin;

  const [buildingInfo, setBuildingInfo] = useState<TSFixMe>({
    isLoading: true,
    data: { admins: [], addresses: [], units: [] },
  });
  const [committe, setCommitte] = useState<TSFixMe>({ isLoading: true, data: [] });
  const [controlAuthority, setControlAuthority] = useState<TSFixMe>({ isLoading: true, data: [] });
  const [externalContacts, setExternalContacts] = useState<TSFixMe>({ isLoading: true, data: [] });

  // TODO: check with Ondrej Bel. slow loading
  const [isLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetch() {
      const committe = await getBuildingCommitte();
      setCommitte({ isLoading: false, data: committe });
    }
    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      const controlAuthority = await getBuildingControlAuthority();
      setControlAuthority({ isLoading: false, data: controlAuthority });
    }
    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      const externalContacts = await getBuildingExternalContacts();
      setExternalContacts({ isLoading: false, data: externalContacts });
    }
    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      const buildingInfo = await getBuildingInfo();
      setBuildingInfo({ isLoading: false, data: buildingInfo });
    }
    fetch();
  }, []);

  return (
    <>
      <Header title={t('Building.title')} customBacklink={routes.poll} />
      <Content isLoading={isLoading}>
        <Card className="Card--content ">
          {buildingInfo.isLoading ? (
            <Text as="div" className="Flex" size="body-medium" color="Neutral600" weight="400">
              {buildingInfo.isLoading && (
                <>
                  <Spinner size="small" className="mr-1" /> {t('Building.BuildingInfo.isLoading')}
                </>
              )}
            </Text>
          ) : (
            <div className="BuildingInfoCard">
              <Text size="h2" color="Neutral800" className="BuildingInfo__addresses mb-2">
                {buildingAddresses(buildingInfo.data.addresses)}
              </Text>
              <table className="Table BuildingInfo__table">
                <tbody>
                  <tr>
                    <th>{t('Building.BuildingInfo.category')}</th>
                    <td>{t('Building.demoCategory')}</td>
                  </tr>
                  <tr>
                    <th>{t('Building.BuildingInfo.type')}</th>
                    <td>{t('Building.demoType')}</td>
                  </tr>
                  <tr>
                    <th>{t('Building.BuildingInfo.admins')}</th>
                    <td>{adminsList(buildingInfo.data.admins)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {unitList(buildingInfo.data.units)}
        </Card>

        <Text size="h2" color="Neutral800" className="Flex FlexAlign--center FlexJustify--space-between mt-3 mb-1">
          {t('Building.Detail.committeMembers')}
          {isAdmin && (
            <ButtonLink
              title={t('Building.Detail.updateContacts')}
              to="contacts/committe"
              variant="text"
              iconAfter="arrowRightSmall"
            />
          )}
        </Text>

        {contactList(committe)}

        <Text size="h2" color="Neutral800" className="Flex FlexAlign--center FlexJustify--space-between mt-3 mb-1">
          {t('Building.Detail.controlAuthority')}

          {isAdmin && (
            <ButtonLink
              title={t('Building.Detail.updateContacts')}
              to="contacts/control-authority"
              variant="text"
              iconAfter="arrowRightSmall"
            />
          )}
        </Text>

        {contactList(controlAuthority)}

        <Text size="h2" color="Neutral800" className="Flex FlexAlign--center FlexJustify--space-between mt-3 mb-1">
          {t('Building.Detail.externalContacts')}

          {isAdmin && (
            <ButtonLink
              title={t('Building.Detail.updateContacts')}
              to="contacts/external-contact"
              variant="text"
              iconAfter="arrowRightSmall"
            />
          )}
        </Text>
        {contactList(externalContacts)}
      </Content>
    </>
  );
};
