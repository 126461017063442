import { TSFixMe } from '../../frontend-libs/evlapp-types';
import { RestApi } from '../../common/services';
import { IAdminRegistration } from '../Admin/interfaces';

export const checkEmailAvailable = async (email?: string): Promise<'exists' | 'available'> => {
  if (!email) return 'exists';
  const res = await RestApi.post<{ email: string }, TSFixMe>(`/public/user/profile/email-exists`, { email: email });
  return res.exists !== true ? 'available' : 'exists';
};

export const sendFailedRegistrationEmail = async (values: TSFixMe): Promise<void> => {
  return await RestApi.post<TSFixMe, void>(`/public/register/save-fallback-contact`, {
    fallbackContactData: { ...values, user: { ...values.user, password: '' } },
  });
};

/**
 * @param {IAdminRegistration} values - values from Manager registration process
 * @return {Promise<TSFixMe>}
 */
export const registerAdmin = async (values: IAdminRegistration): Promise<TSFixMe> => {
  return await RestApi.post<IAdminRegistration, TSFixMe>('/security/register/admin', values);
};

export const getBuildingsByAddress = async (value: string): Promise<TSFixMe> => {
  const res = await RestApi.post<{ q: string }, TSFixMe>(`/public/address-autocomplete`, { q: value });

  return res.hits;
};

export const getAddressRegisteredStatus = async (addressId: string): Promise<{ isRegistered: boolean }> => {
  return await RestApi.get(`public/address/is-registered/${addressId}`);
};

type IAdminBuildingInfo = IAdminRegistration;

export const getBuildingStatus = (values: IAdminBuildingInfo): Promise<TSFixMe> => {
  // if (values.user.password) values.user.password = '';
  return RestApi.post<TSFixMe, TSFixMe>(`/public/registration/summary`, {
    ...values,
    addressId: values.building.addressId,
  });
};
