import { TSFixMe } from '../../frontend-libs/evlapp-types';
import cx from 'classnames';

import { FormEvent, KeyboardEvent, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, FormMessage, Text, Tooltip } from '../../common/components';
import { isDemo } from '../../common/constants/common';

import { useAppSelector } from '../../common/hooks';
import { t } from '../../common/i18n';
import { selectSecurity } from '../../common/store/securitySlice';

interface Step4Props {
  handleVerificationCode: (code: string) => void;
  handleRequestCode: () => void;
  phone?: string;
  isButtonLoading: boolean;
  badVerificationCode: boolean;
  countdown: number;
}

export const Step4: React.FC<Step4Props> = (props) => {
  const security = useAppSelector(selectSecurity).ui;
  const isAfterGracePeriod = security.subscription.isAfterGracePeriod;

  const { countdown } = props;
  const [code, setCode] = useState<string>('');
  const isDisabled = code.length !== 4 || isDemo || isAfterGracePeriod;

  const refs: TSFixMe[] = [];
  refs.push(useRef<HTMLInputElement>(null));
  refs.push(useRef<HTMLInputElement>(null));
  refs.push(useRef<HTMLInputElement>(null));
  refs.push(useRef<HTMLInputElement>(null));

  const handeBackspace = (e: KeyboardEvent<HTMLInputElement>, position: number) => {
    if (e.code === 'Backspace' && refs[position - 1]?.current) {
      refs[position - 1].current.value = '';
      refs[position - 1].current.focus();
    }

    let code = '';
    refs.forEach((ref) => {
      if (ref.current && ref.current.value) code += ref.current.value;
    });

    setCode(code);
  };

  const handleCode = (e: FormEvent<HTMLInputElement>, position: number) => {
    const value = e.currentTarget.value;

    const arr = value.split('');
    if (value.length > 1) {
      arr.forEach((char, index) => {
        if (refs.length <= index + position) return;

        refs[index + position].current.value = char;
        if (refs[index + position + 1]?.current) refs[index + position + 1].current.focus();
      });
    } else if (value.length === 1) {
      if (refs[position]?.current) refs[position].current.value = value[0];
      if (refs[position + 1]?.current) refs[position + 1].current.focus();
    }

    let code = '';
    refs.forEach((ref) => {
      if (ref.current && ref.current.value) code += ref.current.value;
    });

    setCode(code);
  };

  return (
    <>
      <Text size="h2" color="Neutral800" align="center" className="mb-1">
        {t('Poll.Question.Send.enterCode')}
      </Text>

      <Text size="body-medium" color="Neutral700" weight="500" align="center" className="mb-2">
        {t('Poll.Question.Send.smsNoticeCode')}
        {props.phone}
      </Text>
      {props.badVerificationCode && (
        <FormMessage message={'Byl zadán špatný kód'} type={'error'} variant="dark" className="mb-2" />
      )}

      <div className="Modal__verification mb-2">
        <input
          type="text"
          ref={refs[0]}
          onChange={(e) => handleCode(e, 0)}
          onKeyUp={(e) => handeBackspace(e, 0)}
          className="TextField__control"
          placeholder="0"
        />
        <input
          type="text"
          ref={refs[1]}
          onChange={(e) => handleCode(e, 1)}
          onKeyUp={(e) => handeBackspace(e, 1)}
          className="TextField__control"
          placeholder="0"
        />
        <input
          type="text"
          ref={refs[2]}
          onChange={(e) => handleCode(e, 2)}
          onKeyUp={(e) => handeBackspace(e, 2)}
          className="TextField__control"
          placeholder="0"
        />
        <input
          type="text"
          ref={refs[3]}
          onChange={(e) => handleCode(e, 3)}
          onKeyUp={(e) => handeBackspace(e, 3)}
          className="TextField__control"
          placeholder="0"
        />
      </div>

      <Text size="body-medium" color="Neutral500" weight="500" align="center">
        {t('Poll.Question.Send.smsIsMissing')}
      </Text>
      <div className="text--center">
        <Tooltip text={(isDemo && t('App.demoAvailability')) || (isAfterGracePeriod && t('App.afterGracePeriod'))}>
          <Text
            size="body-medium"
            onClick={countdown === 0 ? () => props.handleRequestCode() : undefined}
            className="text--center mb-3"
          >
            <Link to="" className={cx('text', countdown === 0 ? 'Primary500' : 'Neutral500 not-clickable')}>
              {countdown === 0 && t('Poll.Question.Send.smsSendAgain')}
              {countdown > 0 && (
                <>
                  {t('Poll.Question.Send.smsSendAgainTimer')} {countdown}{' '}
                  {t('Date.waitSeconds', undefined, {
                    count: countdown,
                  })}
                </>
              )}
            </Link>
          </Text>
        </Tooltip>
      </div>
      <div className="Flex FlexAlign--center FlexJustify--center">
        <div>
          <Button
            variant={isDisabled ? 'primary-light' : 'primary'}
            wide={true}
            disabledDeps={['demo', 'expired']}
            title={t('Poll.Question.Send.step3ConfirmButton')}
            onClick={() => props.handleVerificationCode(code)}
            loading={props.isButtonLoading}
          />
        </div>
      </div>
    </>
  );
};
