// Root
const root = '/';

// Public routes
const invitation = '/i/';
const login = '/login/';
const logout = '/logout/';
const passwordReset = '/password-reset/';
const registration = '/registration/';

// Authenticated routes
const building = '/building/';
const dashboard = '/';
const feed = '/feed/';
const files = '/file-storage/';
const notifications = '/notifications/';
const poll = '/poll/';
const profile = '/profile/';
const units = '/units/';
const subscriptionOrder = '/subscription/';

// Dev routes
const mockApiExample = '/mock/';
const playground = '/playground/';

export default {
  building: building,
  dashboard: dashboard,
  feed: feed,
  files: files,
  invitation: invitation,
  login: login,
  logout: logout,
  mockApiExample: mockApiExample,
  notifications: notifications,
  passwordReset: passwordReset,
  playground: playground,
  poll: poll,
  profile: profile,
  registration: registration,
  root: root,
  units: units,
  subscriptionOrder: subscriptionOrder,
};
