import { Text } from '../../index';
import { Tooltip } from '../../Tooltip/Tooltip';
import { LabelBaseProps } from '../Base';

interface LabelProps extends LabelBaseProps {
  id?: string;
  classNamePrefix?: string;
  required?: boolean;
}

export const Label: React.FC<LabelProps> = (props) => {
  const { classNamePrefix = 'Label', required, tooltip } = props;

  return (
    <label className={`${classNamePrefix}__label`} htmlFor={props.id}>
      {props.label}
      {required && (
        <Text className={`${classNamePrefix}__label-required`} color="SystemRed" as="span">
          *
        </Text>
      )}
      {tooltip && (
        <Tooltip text={tooltip} wrapper={false}>
          <div className="Label__tooltip">?</div>
        </Tooltip>
      )}
      {props.afterLabel}
    </label>
  );
};
