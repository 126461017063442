import cx from 'classnames';

interface ColProps {
  id?: string;
  className?: string;
  size?: string | number;
}

export const Col: React.FC<ColProps> = (props) => {
  const { size = '12' } = props;

  return (
    <div id={props.id} className={cx('col', `col-${size}`, props.className)}>
      {props.children}
    </div>
  );
};
