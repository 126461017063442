export default {
  Manager: {
    title: 'Registrace správce budovy',

    addressAutocompleteLength: 'Pro vyhledání zadejte alespoň tři znaky',
    addressAutocompleteError: 'Při vyhledávání došlo k chybě, prosím zkuste to znovu',

    BuildingSearch: {
      heading: 'Zadejte adresu a vyhledejte Váš dům',
      Form: {
        Search: {
          label: 'Adresa',
          placeholder: 'Zadejte adresu domu',
        },
        Submit: {
          title: 'Hledat dům',
        },
      },
    },

    BuildingConfirm: {
      heading: 'Dům na adrese <0>{{address}}</0> ještě není součástí rodiny e-vlastníků.',
      headingRegistered: 'Dům na adrese <0>{{address}}</0> je již zaregistrovaný.',
      subheading: 'Zaregistrujte ho během několika minut.',
      subheadingRegistered: 'Kontaktujte svého správce který vám vytvoří přístup do aplikace.',
      nextStep: 'Zaregistrovat dům',
      prevStep: 'Změnit adresu',
    },

    LegalEntity: {
      heading: 'Vyplňte údaje k vašemu SVJ na adrese {{address}}.',
      Form: {
        Fields: {
          Name: {
            label: 'Název vašeho SVJ',
            placeholder: 'Zadejte název vašeho SVJ',
            required: 'Název musí být vyplněný',
          },
          AddressId: {
            label: 'Adresa sídla SVJ',
            placeholder: 'Zadejte adresu sídla SVJ',
            required: 'Adresa je povinná',
          },
          CompanyId: {
            label: 'IČO',
            placeholder: 'Zadejte IČO vašeho SVJ',
            required: 'IČO musí být vyplněné',
          },
          TaxId: {
            label: 'DIČ',
            placeholder: 'Zadejte DIČ vašeho SVJ',
            required: 'DIČ musí být vyplněné',
          },
          IsAdmin: {
            label: 'Prohlašujete že jste správce registrovaného domu',
            required: 'Pokud se chcete zaregistrovat musíte být správce domu?',
          },
          AgreeWithTermsAndConditions: {
            label: 'Souhlasíte s podmínkami použití',
            required: 'Musíte souhlasit s podmínkami použití',
          },
          Submit: {
            title: 'Přejít k registaci správce',
          },
        },
      },
    },

    User: {
      heading: 'Registrujte se jako správce nemovitosti {{address}}.',
      Form: {
        error: 'Registrace neproběhla úspěšně - proto je obecná chyba (teď nedokážu validovat chybové hlášky…))',
        Fields: {
          FirstName: {
            label: 'Jméno',
            placeholder: 'Vaše jméno',
            required: 'Jméno musí být vyplněné',
          },
          LastName: {
            label: 'Příjmení',
            placeholder: 'Vaše příjmení',
            required: 'Příjmení musí být vyplněné',
          },
          Email: {
            label: 'Email',
            placeholder: 'Zadejte váš email',
          },
          Phone: {
            label: 'Telefon',
            placeholder: 'Zadejte vaše telefonní číslo, např. +420 xxx xxx xxx',
            required: 'Telefon je povinný',
          },
          Password: {
            label: 'Heslo',
            placeholder: 'Zadejte vaše heslo',
          },
          Submit: {
            title: 'Registrovat se',
          },
        },
      },
    },
  },

  EmailConfirm: {
    heading: 'Gratulujeme! Registrace proběhla úspěšně.',
    subheading: 'Na vaší emailovou adresu jsme vám poslali shruntí registrace.',
    nextStep: 'Pokračovat do aplikace',
  },

  IncompleteData: {
    heading: 'K registraci od vás potřebujeme všechny údaje, prosím začnětě registraci znovu.',
    continue: 'Přejít k registraci',
  },
};
