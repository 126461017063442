import { TSFixMe } from '../../../frontend-libs/evlapp-types';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import localStorage from '../localStorage/localStorage';
import { store } from '../../store/store';
import { showPaymentModal } from '../../store/uiSlice';

const setToken = (token: string): void => {
  axios.defaults.headers.common['Authorization'] = `${token}`;
};

const removeToken = (): void => {
  axios.defaults.headers.common['Authorization'] = '';
  delete axios.defaults.headers.common['Authorization'];
};

const handleError = (
  err: AxiosError & { status: string | number; data: { violations: TSFixMe[]; errors: { violations: TSFixMe[] } } }
) => {
  if (err.status === 402) {
    let errorType = 'other';
    if (err.config.url?.includes('poll/') && err.config.url?.endsWith('/publish')) {
      errorType = 'pollPublish';
    }

    store.dispatch(showPaymentModal({ errorType }));
  }

  return {
    code: err.code,
    stack: err.stack,
    message: err.message,
    status: err.status,
    violations: err?.data?.violations || err?.data?.errors?.violations,
  };
};

const getRequest = async <Values, Response>(url: string, values?: Values | TSFixMe): Promise<Response> => {
  try {
    const res: AxiosResponse<Response> = await axios.get(url, values);
    return res.data;
  } catch (error) {
    throw handleError(error as never);
  }
};

const postRequest = async <Values, Response>(
  url: string,
  values?: Values,
  config?: AxiosRequestConfig<Values>
): Promise<Response> => {
  try {
    const res: AxiosResponse<Response> = await axios.post(url, values, config);
    return res.data;
  } catch (error) {
    throw handleError(error as never);
  }
};

const putRequest = async <Values, Response>(url: string, values?: Values): Promise<Response> => {
  try {
    const res: AxiosResponse<Response> = await axios.put(url, values);
    return res.data;
  } catch (error) {
    throw handleError(error as never);
  }
};

const patchRequest = async <Values, Response>(url: string, values?: Values): Promise<Response> => {
  try {
    const res: AxiosResponse<Response> = await axios.patch(url, values);
    return res.data;
  } catch (error) {
    throw handleError(error as never);
  }
};

const deleteRequest = async <Response>(url: string): Promise<Response> => {
  try {
    const res: AxiosResponse<Response> = await axios.delete(url);
    return res.data;
  } catch (error) {
    throw handleError(error as never);
  }
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const token = localStorage.getToken();
if (token) axios.defaults.headers.common['Authorization'] = `${token}`;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) return Promise.reject(error.response);
    return Promise.reject(error.toJSON());
  }
);

export default {
  axios: axios,
  delete: deleteRequest,
  get: getRequest,
  patch: patchRequest,
  post: postRequest,
  put: putRequest,
  removeToken: removeToken,
  setToken: setToken,
  baseUrl: process.env.REACT_APP_API_URL,
};
