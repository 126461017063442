import cx from 'classnames';

interface AvatarProps extends React.HTMLAttributes<HTMLElement> {
  initials: string;
  imageUrl?: string;
  size?: 'default' | 'large';
  className?: string;
}

export const Avatar = (props: AvatarProps): JSX.Element => {
  const { initials, imageUrl, size = 'default', className, ...rest } = props;

  return (
    <div className={cx('Avatar', `Avatar--${size}`, className)} {...rest}>
      <div className="Avatar__initials text-body-small Blue600 Weight600">{initials}</div>
      {imageUrl && <img className="Avatar__image" src={imageUrl} alt={initials} />}
    </div>
  );
};
