import { TSFixMe } from '../../frontend-libs/evlapp-types';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, Modal, Text, TextField } from '../../common/components';
import { t } from '../../common/i18n';
import { setFlashMessage } from '../../common/store/uiSlice';
import Yup from '../../common/utils/FormValidation';
import { renameFile } from '../services/FilesService';
import { isPaymentError } from '../../common/utils';

interface ModalRenameProps {
  file: TSFixMe;
  isShown: boolean;
  onHide: TSFixMe;
  handleFetchFiles: TSFixMe;
}

export const ModalRename: React.FC<ModalRenameProps> = (props) => {
  const namespace = 'Files.ModalRename';

  const dispatch = useDispatch();
  const formik = useFormik<{ id: string; filename: string }>({
    initialValues: { id: props?.file?.id || '', filename: props?.file?.filename || '' },
    enableReinitialize: true,
    validationSchema: Yup.object({
      filename: Yup.string()
        .required(t('Form.Fields.Filename.required', namespace))
        .max(90, (n) => t('Form.Fields.Filename.max', namespace, { count: n.max })),
    }),
    onSubmit: (values) => {
      renameFile(values)
        .then(() => {
          props.handleFetchFiles().then(() => {
            dispatch(setFlashMessage({ message: t('Flash.success', namespace), type: 'success' }));
            props.onHide();
          });
        })
        .catch((e) => {
          if (!isPaymentError(e)) {
            dispatch(setFlashMessage({ message: t('Flash.error', namespace), type: 'error' }));
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <Modal isShown={props.isShown} onHide={props.onHide}>
      <Text size="h2" align="center" className="mb-4">
        {t('title', namespace)}
      </Text>
      <TextField
        label={t('Form.Fields.Filename.label', namespace)}
        placeholder={t('Form.Fields.Filename.placeholder', namespace)}
        name="filename"
        value={formik.values.filename}
        onChange={formik.handleChange}
        error={{ message: formik.errors.filename, touched: formik.touched.filename }}
        className="mb-4"
      />
      <div className="Flex FlexColumn FlexAlign--center">
        <Button
          onClick={formik.handleSubmit}
          loading={formik.isSubmitting}
          title={t('Form.Fields.Submit.title', namespace)}
          disabledDeps={['demo', 'expired']}
          wide
        />
      </div>
    </Modal>
  );
};
