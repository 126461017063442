import cx from 'classnames';
import { FormFieldProps } from '../Base';
import { FieldError } from '../Error/FieldError';
import { Text } from '../../Text/Text';
import { useRef } from 'react';

interface RadioFieldProps<T> extends FormFieldProps<string> {
  onChange: (e: React.ChangeEvent<T>) => void;
  label?: string;
  className?: string;
  value: string;
  checked?: boolean;
  description?: string;
}

export const RadioField: React.FC<RadioFieldProps<HTMLInputElement>> = (props) => {
  let id = props.id || props.name;
  id += '_' + props.value;

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={cx('RadioField', props.className)}>
      <label className="RadioField__inner" htmlFor={id}>
        <input
          id={id}
          name={props.name}
          value={props.value}
          type="radio"
          className="RadioField__input"
          onChange={props.onChange}
          defaultChecked={props.checked}
          ref={inputRef}
          disabled={props.disabled}
        />
        <div className="RadioField__control"></div>
        {props.label && <span className="RadioField__label">{props.label}</span>}

        {props.afterLabel}
      </label>
      {props.description && (
        <Text
          className="RadioField__description"
          size="body-small"
          weight="400"
          color="Neutral700"
          children={props.description}
          onClick={() => inputRef.current?.click()}
        />
      )}
      <FieldError {...props.error} />
    </div>
  );
};
