import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useFormik } from 'formik';

import { IPostReqSecurityAuthenticationLogin } from '../frontend-libs/evlapp-types';

import { t } from '../common/i18n';
import routes from '../common/constants/routes';
import { useAppDispatch, useAppThunkDispatch, useDocumentTitle } from '../common/hooks';
import { loginUser, logout } from '../common/store/securitySlice';
import { getSearchParam } from '../common/utils/queryParams';
import Yup, { YupEmail } from '../common/utils/FormValidation';

import { Row, Col, Button, ButtonLink, TextField, Text, PasswordField, FormMessage } from '../common/components';
import { demoAdminCredentials, demoUserCredentials, isDemo } from '../common/constants/common';

export const Login: React.FC = () => {
  useDocumentTitle(t('Login.title'));

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const [formError, setFormError] = useState<boolean>(false);
  const [isLoadingUser, setLoadingUser] = useState<boolean>(false);
  const [isLoadingAdmin, setLoadingAdmin] = useState<boolean>(false);

  const from = location.state?.from?.pathname || routes.dashboard;

  const formik = useFormik<IPostReqSecurityAuthenticationLogin>({
    initialValues: { email: '', password: '' },
    validate: () => setFormError(false),
    validationSchema: Yup.object({
      email: YupEmail,
      password: Yup.string().required(),
    }),
    onSubmit: (values) => {
      thunkDispatch(loginUser(values))
        .unwrap()
        .then(() => navigate(from, { replace: true }))
        .catch((error) => {
          formik.setSubmitting(false);
          setFormError(true);
        });
    },
  });

  const handleDemoLogin = (type: 'user' | 'admin') => {
    let values = { email: '', password: '' };

    if (type === 'admin') {
      values = demoAdminCredentials;
      setLoadingAdmin(true);
    }

    if (type === 'user') {
      values = demoUserCredentials;
      setLoadingUser(true);
    }

    thunkDispatch(loginUser(values))
      .unwrap()
      .then(() => navigate(from, { replace: true }))
      .catch((error) => {
        formik.setSubmitting(false);
        setFormError(true);
      });
  };

  if (getSearchParam('logout')) dispatch(logout());

  return (
    <form className="OnboardingForm" onSubmit={formik.handleSubmit}>
      <Row>
        <Col className="mb-2">
          <Text size="h1" color="Neutral800">
            {t('Login.Form.title')}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>{formError && <FormMessage message={t('Login.Form.error')} />}</Col>
      </Row>
      {isDemo ? (
        <Row>
          <Col size="6">
            <Button
              variant="primary"
              title={t('Login.Demo.asUser')}
              loading={isLoadingUser}
              disabled={isLoadingAdmin}
              onClick={() => handleDemoLogin('user')}
            />
          </Col>
          <Col size="6">
            <Button
              variant="primary"
              title={t('Login.Demo.asAdmin')}
              loading={isLoadingAdmin}
              disabled={isLoadingUser}
              onClick={() => handleDemoLogin('admin')}
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col size="12" className="mb-2 mt-2">
              <TextField
                name="email"
                autocomplete="username"
                placeholder={t('Login.Form.Fields.Email.placeholder')}
                label={t('Login.Form.Fields.Email.label')}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={{ message: formik.errors.email, touched: formik.touched.email }}
              />
            </Col>
          </Row>
          <Row>
            <Col size="12" className="mb-4">
              <PasswordField
                name="password"
                autocomplete="current-password"
                placeholder={t('Login.Form.Fields.Password.placeholder')}
                label={t('Login.Form.Fields.Password.label')}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={{ message: formik.errors.password, touched: formik.touched.password }}
              />
            </Col>
          </Row>

          <Row>
            <Col size="12" className="mb-2">
              <Button type="submit" title={t('Login.Form.Fields.Submit.title')} loading={formik.isSubmitting} />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <div className="OnboardingDivider">
                <Text size="body-xs" as="span" color="Neutral600">
                  {t('Login.dividerText')}
                </Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mb-2">
              <ButtonLink variant="secondary" to={routes.registration} title={t('Login.toRegistration')} />
            </Col>
          </Row>

          <Row>
            <Col className="mb-2">
              <Text size="body-medium" className="text--center">
                <Trans i18nKey="Login.passwordReset">
                  <Link to={routes.passwordReset} className="text Primary500"></Link>
                </Trans>
              </Text>
            </Col>
          </Row>
        </>
      )}
    </form>
  );
};

export default Login;
