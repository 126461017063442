import { TSFixMe } from '../frontend-libs/evlapp-types';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Content, FileField, FormMessage, Header, Icon, Modal, Text } from '../common/components';
import { useModal } from '../common/components/Modal/useModal';
import routes from '../common/constants/routes';

import { useAppSelector, useDocumentTitle } from '../common/hooks';
import { t } from '../common/i18n';
import { selectSecurity } from '../common/store/securitySlice';
import { setFlashMessage } from '../common/store/uiSlice';
import { formatDateMonthYear } from '../common/utils/Date/Date';
import Yup from '../common/utils/FormValidation';
import { ModalDelete } from './components/ModalDelete';
import { ModalRename } from './components/ModalRename';
import { downloadStorageFile, getFiles, uploadFiles } from './services/FilesService';
import { sortByYearMonth } from './utils/FilesUtils';

export const FilesDefault: React.FC = () => {
  const namespace = 'Files';
  useDocumentTitle(t('title', namespace));

  const ui = useAppSelector(selectSecurity).ui;
  const isAdmin = ui.state === 'admin' ? true : undefined;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [formError, setFormError] = useState(false);

  const [files, setFiles] = useState<TSFixMe[]>([]);
  const [activeFile, setActiveFile] = useState<TSFixMe>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isShownUpload, toggleUpload] = useModal();
  const [isShownRename, toggleRename] = useModal();
  const [isShownDelete, toggleDelete] = useModal();

  const fetchFiles = async () => {
    const files = await getFiles();
    const sortedFiles = sortByYearMonth(files);
    setFiles(sortedFiles);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const formik = useFormik<TSFixMe>({
    initialValues: { attachment: null },
    validate: () => setFormError(false),
    validationSchema: Yup.object({
      attachment: Yup.string().nullable().required(),
    }),
    onSubmit: (values) => {
      const formData = new FormData();

      for (let i = 0; i < values.attachment.length; i++) {
        formData.append(`files[${i}]`, values.attachment[i]);
      }

      uploadFiles(formData)
        .then(() => {
          // TODO: REFACTOR INTO REDUX
          fetchFiles().then(() => {
            formik.setSubmitting(false);
            formik.setFieldValue('attachment', null);
            formik.setFieldTouched('attachment', false);
            if (fileInputRef.current) fileInputRef.current.value = '';
            dispatch(setFlashMessage({ message: t('ModalUpload.Flash.success', namespace), type: 'success' }));
            toggleUpload();
          });
        })
        .catch(() => {
          setFormError(true);
          formik.setSubmitting(false);
        });
    },
  });

  const handleDelete = (item: TSFixMe) => {
    setActiveFile(item);
    toggleDelete();
  };

  const handleRenameFile = (item: TSFixMe) => {
    setActiveFile(item);
    toggleRename();
  };

  return (
    <>
      <Header
        title={t('Header.title', namespace)}
        customBacklink={routes.poll}
        actions={
          !isLoading && isAdmin && <Button size="small" title={t(`Header.upload`, namespace)} onClick={toggleUpload} />
        }
      />
      <Content
        isLoading={isLoading}
        isEmpty={files.length === 0 || Object.keys(files).length === 0}
        isEmptyText={t('Content.emptyText', namespace)}
      >
        {Object.keys(files)
          .sort()
          .reverse()
          .map((key: TSFixMe) => {
            return (
              <div key={key} className="mb-4">
                <Text size="h2" className="mb-1">
                  {formatDateMonthYear(key)}
                </Text>
                <Card className="CardAttachments">
                  {files[key].map((item: TSFixMe, index: number) => {
                    return (
                      <React.Fragment key={item.id}>
                        <div className="Card__link">
                          <Text size="body-medium" color="Inherit" className="mr-auto">
                            {item.filename}
                          </Text>

                          <Icon
                            name="download"
                            color="Inherit"
                            className="icon--clickable"
                            onClick={() => downloadStorageFile(item)}
                          />

                          {isAdmin && (
                            <div className="CardActions">
                              <Icon name="edit" onClick={() => handleRenameFile(item)} />
                              <Icon name="trash" onClick={() => handleDelete(item)} />
                            </div>
                          )}
                        </div>
                        {index !== files[key].length - 1 && <div className="Card__divider"></div>}
                      </React.Fragment>
                    );
                  })}
                </Card>
              </div>
            );
          })}
      </Content>

      <Modal isShown={isShownUpload} onHide={toggleUpload}>
        <Text size="h2" align="center" className="mb-2">
          {t('ModalUpload.title', namespace)}
        </Text>
        <Text size="body-medium" color="Neutral700" align="center" className="mb-3">
          {t('ModalUpload.subtitle', namespace)}
        </Text>
        {formError && <FormMessage message={t('ModalUpload.formError', namespace)} variant="dark" />}
        <FileField
          name="attachment"
          label={t('ModalUpload.Attachment.label', namespace)}
          filesLabel={`${namespace}.ModalUpload.Attachment.filesLabel`}
          multiple={true}
          reference={fileInputRef}
          formikValue={formik.values.attachment}
          className="mt-2 mb-4"
          error={{ message: formik.errors.attachment as string, touched: formik.touched.attachment as boolean }}
          onChange={(event) => {
            if (event && event.currentTarget && event.currentTarget.files) {
              formik.setFieldValue('attachment', event.currentTarget.files);
            }
          }}
        />

        <div className="Flex FlexAlign--center FlexJustify--center mb-2">
          <Button
            onClick={formik.handleSubmit}
            title={t('ModalUpload.submit', namespace)}
            loading={formik.isSubmitting}
            disabledDeps={['demo', 'expired']}
            wide={true}
          />
        </div>
      </Modal>
      <ModalDelete
        id={activeFile?.id || ''}
        isShown={isShownDelete}
        onHide={toggleDelete}
        handleFetchFiles={fetchFiles}
      />
      <ModalRename file={activeFile} isShown={isShownRename} onHide={toggleRename} handleFetchFiles={fetchFiles} />
    </>
  );
};
