import React, { useState } from 'react';
import { useFormik } from 'formik';

import { t } from '../common/i18n';
import { useAppSelector, useDocumentTitle } from '../common/hooks';
import { selectSecurity } from '../common/store/securitySlice';

import { Card, Header, Avatar, Button, Text, PasswordField, FormMessageProps, FormMessage } from '../common/components';
import { getInitials } from '../common/utils/Profile/profile';
import { updateProfilePassword } from './services/ProfileService';
import Yup, { backendViolations, YupPassword } from '../common/utils/FormValidation';
import routes from '../common/constants/routes';

interface IProfileChangePassword {
  currentPassword: string;
  newPassword: string;
  newPasswordCheck: string;
}

const ProfileChangePassword: React.FC = () => {
  useDocumentTitle(t('Profile.ChangePassword.title'));

  const data = useAppSelector(selectSecurity).profile;
  const initials = getInitials(data?.firstName, data?.lastName);

  const [formMessage, setFormMessage] = useState<FormMessageProps>({ message: '', type: 'error' });

  const formik = useFormik<IProfileChangePassword>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordCheck: '',
    },
    validate: () => setFormMessage({}),
    validationSchema: Yup.object({
      currentPassword: Yup.string().required(),
      newPassword: YupPassword,
      newPasswordCheck: Yup.string()
        .required()
        .oneOf([Yup.ref('newPassword'), null], t('Profile.ChangePassword.Form.Fields.NewPasswordCheck.matches')),
    }),

    onSubmit: (values) => {
      updateProfilePassword(values)
        .then(() => {
          setFormMessage({ message: t('Profile.ChangePassword.Form.success'), type: 'success' });
        })
        .catch((error) => {
          backendViolations(formik, error);
          setFormMessage({ message: t('Profile.ChangePassword.Form.error'), type: 'error' });
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <>
      <Header title={t('Profile.ChangePassword.Header.title')} customBacklink={routes.profile} />
      <div className="Content">
        <Card className="Card--content">
          <form onSubmit={formik.handleSubmit}>
            {initials && (
              <div className="profileEditAvatar">
                <Avatar initials={initials} size="large" className="mr-3" />

                <Text size="body-medium" color="Primary500">
                  {data?.firstName} {data?.lastName}
                </Text>
              </div>
            )}

            {formMessage.message && <FormMessage {...formMessage} variant="dark" className="mb-3" />}
            <PasswordField
              name="currentPassword"
              label={t('Profile.ChangePassword.Form.Fields.CurrentPassword.label')}
              placeholder={t('Profile.ChangePassword.Form.Fields.CurrentPassword.placeholder')}
              error={{ touched: formik.touched.currentPassword, message: formik.errors.currentPassword }}
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              className="mb-3"
            />
            <PasswordField
              name="newPassword"
              label={t('Profile.ChangePassword.Form.Fields.NewPassword.label')}
              placeholder={t('Profile.ChangePassword.Form.Fields.NewPassword.placeholder')}
              error={{ touched: formik.touched.newPassword, message: formik.errors.newPassword }}
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              className="mb-2"
            />
            <PasswordField
              name="newPasswordCheck"
              label={t('Profile.ChangePassword.Form.Fields.NewPasswordCheck.label')}
              placeholder={t('Profile.ChangePassword.Form.Fields.NewPasswordCheck.placeholder')}
              error={{ touched: formik.touched.newPasswordCheck, message: formik.errors.newPasswordCheck }}
              value={formik.values.newPasswordCheck}
              onChange={formik.handleChange}
              className="mb-2"
            />

            <Button
              onClick={formik.handleSubmit}
              disabledDeps={['demo', 'expired']}
              loading={formik.isSubmitting}
              title={t('Profile.ChangePassword.Form.Fields.Submit.title')}
            />
          </form>
        </Card>
      </div>
    </>
  );
};

export default ProfileChangePassword;
