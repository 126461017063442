const SECRET_TOKEN = 'app_token';

export const getToken = (): string | null => {
  return window.localStorage.getItem(SECRET_TOKEN);
};

export const setToken = (token: string): void => {
  window.localStorage.setItem(SECRET_TOKEN, token);
};

export const deleteToken = (): void => {
  window.localStorage.removeItem(SECRET_TOKEN);
};

export default {
  getToken,
  setToken,
  deleteToken,
};
