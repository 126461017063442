import { fromEntries } from './Polyfills/fromEntries';

interface IgetSearchParams {
  [k: string]: string;
}

export const getSearchParams = (url: string): IgetSearchParams => {
  const urlSearchParams = new URLSearchParams(url);
  const params = fromEntries(urlSearchParams.entries());

  // Fix for params with no value
  Object.keys(params).forEach((key) => {
    if (params[key] === '') params[key] = 'true';
  });

  return params;
};

export const getSearchParam = (name: string, urlSearch?: string): string | null => {
  if (!urlSearch) urlSearch = window.location.search;

  return getSearchParams(urlSearch)?.[name] || null;
};
