import { cloneElement, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import {
  Placement,
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
} from '@floating-ui/react-dom-interactions';
import { arrow } from '@floating-ui/dom';
import { TSFixMe } from '../../../frontend-libs/evlapp-types';

interface Props {
  text: string | boolean;
  wrapper?: boolean;
  placement?: Placement;
  children: JSX.Element;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Tooltip = ({ children, text, placement = 'top', wrapper = true }: Props) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef<TSFixMe>(null);

  const middlewares = [offset(8), flip(), shift({ padding: 24 })];
  if (arrowRef.current) middlewares.push(arrow({ element: arrowRef.current }));

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    placement: resolvedPlacement,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    placement,
    open,
    middleware: middlewares,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
  });

  const { getFloatingProps } = useInteractions([
    useHover(context, { enabled: true }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  const staticSide = (
    {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    } as TSFixMe
  )[resolvedPlacement.split('-')[0]];

  const arrowStyle = {
    left: arrowX != null ? `${arrowX}px` : '',
    top: arrowY != null ? `${arrowY}px` : '',
    right: '',
    bottom: '',
    [staticSide]: '-4px',
  };

  const floatingContent = createPortal(
    <div
      {...getFloatingProps({
        ref: floating,
        className: 'Tooltip',
        style: {
          position: strategy,
          top: y ?? '',
          left: x ?? '',
        },
      })}
    >
      <div className="Tooltip__Content">{text}</div>
      <div ref={arrowRef} style={arrowStyle} className="Tooltip__Arrow" />
    </div>,
    document.querySelector('#overlay') as Element
  );

  if (!text) return children;

  let child = <div style={{ display: 'inline-flex' }}>{children}</div>;
  if (!wrapper) child = children;

  return (
    <>
      {cloneElement(child, { ref: reference, ...child.props })}
      {open && floatingContent}
    </>
  );
};
