import cx from 'classnames';
import Flatpickr from 'react-flatpickr';

import { Czech } from 'flatpickr/dist/l10n/cs.js';

import { FormFieldProps } from '../Base';

import { Label } from '../Label/Label';
import { FieldError } from '../Error/FieldError';
import { t } from '../../../i18n';
import { formatDate } from '../../../utils/Date/Date';

interface DateFieldProps extends Omit<FormFieldProps<Date>, 'value'> {
  onChange: (date: string) => void;
  value?: Date | string;
  label?: string;
  minDate?: Date | 'today';
  maxDate?: Date;

  type?: 'date' | 'datetime' | 'time';
  placeholder?: string;
  className?: string;
}

export const DateField: React.FC<DateFieldProps> = (props) => {
  const { minDate, maxDate, type = 'date', placeholder } = props;
  const id = props.id || props.name;

  const showCalendar = type === 'date' || type === 'datetime';
  const enableTime = type === 'datetime' || type === 'time';

  let altFormat = 'j. F Y';
  if (type === 'datetime') altFormat = 'j. n. Y H:i';
  if (type === 'time') altFormat = 'H:i';

  const options = {
    locale: Czech,
    altInput: true,
    disableMobile: true,
    minDate: minDate,
    maxDate: maxDate,
    enableTime: enableTime,
    noCalendar: !showCalendar,
    altFormat: altFormat,
  };

  return (
    <div className={cx('DateField', props.className)}>
      {props.label && <Label classNamePrefix="TextField" id={id} label={props.label} />}

      <Flatpickr
        name={props.name}
        value={props.value}
        onChange={(dates) => props.onChange(dates?.[0] ? formatDate(dates?.[0], true) : '')}
        options={options}
        render={(props, ref) => {
          return (
            <input className="TextField__control" placeholder={placeholder || t('DateField.placeholder')} ref={ref} />
          );
        }}
      />

      <FieldError {...props.error} />
    </div>
  );
};
