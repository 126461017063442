import { t } from '../common/i18n';

import { Header, Text, CardMessage } from '../common/components';
import { useDocumentTitle } from '../common/hooks';

export const Notifications: React.FC = () => {
  useDocumentTitle(t('Profile.title'));

  return (
    <>
      <Header title="Notifikace" backlink={-1} />
      <div className="Content">
        <CardMessage date="30. května 2021" title="Nová verze aplikace" className="mb-3">
          <Text size="body-small" color="Neutral700" weight="500">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy...Lorem Ipsum is simply dummy text of the printing and typesetting
            industry...Lorem Ipsum is simply dummy text of the printing and typesetting industry...Lorem Ipsum is simply
            dummy text of the printing and typesetting industry...Lorem Ipsum is simply dummy text of the printing and
            typesetting industry...Lorem Ipsum is simply dummy text of the printing and typesetting industry...
          </Text>
        </CardMessage>

        <CardMessage date="30. května 2021" title="Nová verze aplikace">
          <Text size="body-small" color="Neutral700" weight="500">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy...Lorem Ipsum is simply dummy text of the printing and typesetting
            industry...Lorem Ipsum is simply dummy text of the printing and typesetting industry...Lorem Ipsum is simply
            dummy text of the printing and typesetting industry...Lorem Ipsum is simply dummy text of the printing and
            typesetting industry...Lorem Ipsum is simply dummy text of the printing and typesetting industry...
          </Text>
        </CardMessage>
      </div>
    </>
  );
};

export default Notifications;
