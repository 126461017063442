import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import Yup, { YupIco } from '../../common/utils/FormValidation/index';

import { t } from '../../common/i18n';
import routes from '../../common/constants/routes';
import { Col, Row, Text, TextField, Button, CheckboxField, AsyncSelectField } from '../../common/components';

import { IncompleteData } from './IncompleteData';
import { IAdminBuildingSearch, IAdminRegistration } from './interfaces';
import { getBuildingOptions } from '../utils/AddressAutocomplete';

// prettier-ignore
type FormModel = IAdminRegistration['legalEntity'] & Partial<IAdminRegistration['agreements']> & { isPermitted: boolean };

const composeDefaultValue = (building: Partial<IAdminBuildingSearch>) => {
  return {
    address: building.address || '',
    addressId: building.addressId || '',
    isDisabled: false,
  };
};

export const LegalEntity: React.FC<{ state: IAdminRegistration }> = (props) => {
  const namespace = 'AdminRegistration.LegalEntity';

  const { state } = props;

  const address = state?.building?.address;

  const navigate = useNavigate();

  const formik = useFormik<FormModel>({
    initialValues: {
      company: '',
      addressId: state?.building?.addressId || '',
      companyId: '',
      taxId: '',
      admin: false,
      isPermitted: false,
    },
    onSubmit: (values) => {
      const { admin, ...rest } = values;

      return navigate(`${routes.registration}user/`, { state: { ...state, legalEntity: rest, agreements: { admin } } });
    },

    validationSchema: Yup.object({
      company: Yup.string().required(t('Form.Fields.Company.required', namespace)),
      addressId: Yup.string().required(t('Form.Fields.AddressId.required', namespace)),
      companyId: YupIco,
      // taxId: Yup.string().required(t('Form.Fields.TaxId.required', namespace)),

      admin: Yup.boolean().oneOf([true], t('Form.Fields.Admin.required', namespace)),
      isPermitted: Yup.boolean().oneOf([true], t('Form.Fields.IsPermitted.required', namespace)),
    }),
  });

  // Return page with incomplete date
  if (!address) return <IncompleteData />;

  return (
    <form className="OnboardingForm" onSubmit={formik.handleSubmit}>
      <Row>
        <Col className="mb-4">
          <Text size="h1">{t('heading', namespace, { address })}</Text>
        </Col>
      </Row>

      <Row>
        <Col size="12" className="mb-2">
          <TextField
            name="company"
            value={formik.values.company}
            onChange={formik.handleChange}
            placeholder={t('Form.Fields.Company.placeholder', namespace)}
            label={t('Form.Fields.Company.label', namespace)}
            error={{ touched: formik.touched.company, message: formik.errors.company }}
            required={true}
          />
        </Col>
      </Row>
      <Row>
        <Col size="12" className="mb-2">
          <AsyncSelectField
            label={t('Form.Fields.AddressId.label', namespace)}
            placeholder={t('Form.Fields.AddressId.placeholder', namespace)}
            cacheOptions
            loadOptions={getBuildingOptions}
            defaultValue={composeDefaultValue(state.building)}
            error={{ touched: formik.touched.addressId, message: formik.errors.addressId }}
            onChange={(v: IAdminBuildingSearch | null) => formik.setFieldValue('addressId', v ? v.addressId : '')}
            getOptionLabel={(option) => option.address || ''}
            getOptionValue={(option) => option.addressId || ''}
            noOptionsMessage={(v) => {
              const { inputValue } = v;
              if (inputValue.length > 3) return t('Form.Fields.AddressId.noData', namespace);
              return t('Form.Fields.AddressId.minLength', namespace);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col size="6" className="mb-2">
          <TextField
            name="companyId"
            value={formik.values.companyId}
            onChange={formik.handleChange}
            placeholder={t('Form.Fields.CompanyId.placeholder', namespace)}
            label={t('Form.Fields.CompanyId.label', namespace)}
            error={{ touched: formik.touched.companyId, message: formik.errors.companyId }}
            required
          />
        </Col>

        <Col size="6" className="mb-2">
          <TextField
            name="taxId"
            value={formik.values.taxId}
            onChange={formik.handleChange}
            placeholder={t('Form.Fields.TaxId.placeholder', namespace)}
            label={t('Form.Fields.TaxId.label', namespace)}
            error={{ touched: formik.touched.taxId, message: formik.errors.taxId }}
          />
        </Col>
      </Row>

      <Row>
        <Col className="mb-2">
          <CheckboxField
            name="admin"
            label={t('Form.Fields.Admin.label', namespace)}
            onChange={formik.handleChange}
            error={{ touched: formik.touched.admin, message: formik.errors.admin }}
            className="mb-1"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <CheckboxField
            name="isPermitted"
            label={
              <>
                {t('Form.Fields.IsPermitted.label', namespace)}{' '}
                <a href="https://stanovy.evlastnici.cz" target="_blank" rel="noreferrer">
                  {t('Form.Fields.IsPermitted.label_2', namespace)}
                </a>
              </>
            }
            onChange={formik.handleChange}
            error={{ touched: formik.touched.isPermitted, message: formik.errors.isPermitted }}
            className="mb-1"
          />
        </Col>
      </Row>

      <Row>
        <Col size="12" className="mb-2">
          <Button title={t('Form.Fields.Submit.title', namespace)} type="submit" />
        </Col>
      </Row>
    </form>
  );
};
