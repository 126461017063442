import cx from 'classnames';

interface IButtonClassNames {
  variant?: string;
  wide?: boolean;
  size?: string;
  rounded?: boolean;
  isDisabled?: boolean;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
}

export const buttonClassNames = ({
  variant,
  wide,
  size,
  rounded,
  isDisabled,
  disabled,
  loading,
  className,
}: IButtonClassNames): string =>
  cx(
    'Button',
    `Button--${variant}`,
    wide && `Button--wide`,
    `Button--${size}`,
    rounded && `Button--rounded`,
    className,
    isDisabled && `Button--disabled`,
    disabled && `Button--disabled`,
    loading && `Button--loading`
  );
