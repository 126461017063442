import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { t } from '../../common/i18n';
import { useAppSelector, useAppThunkDispatch, useDocumentTitle } from '../../common/hooks';
import routes from '../../common/constants/routes';
import {
  Card,
  CardAttachments,
  CardGallery,
  CardQuestion,
  Header,
  Text,
  Spinner,
  Editor,
} from '../../common/components';

import { getPoll, selectPoll, setQuestionChoice } from '../../common/store/pollSlice';
import { sendPollQuestionVote } from '../services/PollService';
import { TSFixMe } from '../../frontend-libs/evlapp-types';

interface PollQuestionDetailProps {
  isEditable?: boolean;
}

export const PollQuestionDetail: React.FC<PollQuestionDetailProps> = (props) => {
  const { isEditable } = props;
  useDocumentTitle(t('Poll.title'));

  const { pollId = null, questionId = null } = useParams();
  const namespace = 'Poll.Question';

  const dispatch = useAppThunkDispatch();
  const poll = useAppSelector(selectPoll);

  // TODO FIX TS
  const question: TSFixMe = poll.questions?.find((item: TSFixMe) => item.id === questionId);
  const [isLoading, setIsLoading] = useState<boolean>(!question);

  useEffect(() => {
    if (question || !pollId) return;
    dispatch(getPoll(pollId))
      .unwrap()
      .then(() => setIsLoading(false));
  }, [question, dispatch, pollId]);

  if (isLoading) return <Spinner size="large" position="overlay" />;
  if (!question) return <div>Nebyla nalezena otázka</div>;

  const sendPollQuestion = async (questionId: string, answer: 'yes' | 'no' | 'undecided') => {
    try {
      await sendPollQuestionVote(answer, questionId, poll.id);
      return dispatch(setQuestionChoice({ questionId, answer }));
    } catch {}
  };

  return (
    <>
      <Header
        title={poll.name || ''}
        customBacklink={isEditable ? `${routes.poll}detail/${pollId}` : `${routes.poll}results/${pollId}`}
      />

      <div className="Content">
        <CardQuestion
          title={question.name}
          answer={question.answer}
          onYes={() => sendPollQuestion(question.id, 'yes')}
          onNo={() => sendPollQuestion(question.id, 'no')}
          onUndecided={() => sendPollQuestion(question.id, 'undecided')}
          className="mb-4"
          isEditable={isEditable}
        />
        {question.description && (
          <>
            <Text size="h2" color="Neutral800" className="mb-1">
              {t('description', namespace)}
            </Text>
            <Card className="Card--content mb-3">
              <Text size="body-medium" color="Neutral750" weight="400">
                {question.description}
              </Text>
            </Card>
          </>
        )}

        {question.descriptionExtra && (
          <>
            <Text size="h2" color="Neutral800" className="mb-1">
              {t('descriptionExtra', namespace)}
            </Text>
            <Card className="Card--content mb-3">
              <Editor content={question.descriptionExtra} isEditable={false} />
            </Card>
          </>
        )}

        {question.attachments && question.attachments.length > 0 && (
          <>
            <Text size="h2" color="Neutral800" className="mb-1">
              {t('attachments', namespace)}
            </Text>
            <CardAttachments attachments={question.attachments} className="mb-3" />
          </>
        )}

        {question.images && question.images.length > 0 && (
          <>
            <Text size="h2" color="Neutral800" className="mb-1">
              {t('gallery', namespace)}
            </Text>
            <CardGallery images={question.images} />
          </>
        )}
      </div>
    </>
  );
};

export default PollQuestionDetail;
