export default {
  title: 'Pozvánka do aplikace',

  tokenErrorTitle: 'Tato pozvánka byla již použita',
  tokenErrorSubtitle: 'Můžete se přihlásit do aplikace se svými přihlašovacími údaji.',
  tokenErrorButton: 'Zpět na přihlášení',

  Form: {
    title: 'Vítejte v aplikaci eVlastníci',
    subtitle: 'Prosím zvolte si vaše přihlašovací heslo do aplikace.',
    error: 'Registrace se nepodařila, zkuste to prosím znovu.',

    Fields: {
      Email: {
        label: 'Váš přihlašovací email',
        placeholder: 'Zadejte váš email pomocí kterého se později přihlásíte',
      },
      Password: {
        label: 'Nové heslo',
        placeholder: 'Zadejte vaše nové heslo do aplikace',
      },
      PasswordCheck: {
        label: 'Nové heslo znovu',
        placeholder: 'Zadejte nové heslo znovu',
        matches: 'Hesla se musí shodovat',
      },
      Gdpr: {
        label: 'Souhlasíte se zpracováním vašich osobních údajů',
        required: 'K dokončení registrace od vás potřebujeme souhlas se zpracováním osobních údajů',
      },
      TermsAndConditions: {
        label: 'Souhlasíte s podmínkami použití',
        required: 'K dokončení registrace od vás potřebujeme souhlas s podmínkami používání',
      },
      Marketing: {
        label: 'Souhlasíte se zasíláním marketingových nabídek',
      },
      Submit: {
        title: 'Přijmout pozvánku a dokončit registraci',
      },
    },
  },
};
