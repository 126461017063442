import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { t } from '../common/i18n';
import { useAppSelector, useDocumentTitle } from '../common/hooks';
import routes from '../common/constants/routes';
import {
  Button,
  Card,
  CardUserList,
  Content,
  Header,
  RadioField,
  RadioFieldLabel,
  Text,
  TextField,
} from '../common/components';

import { getUnit, updateUnitVotingStrategy } from './services/UnitService';
import { TSFixMe } from '../frontend-libs/evlapp-types';
import { getProfileName } from '../common/utils/Profile/profile';
import { useFormik } from 'formik';
import Yup from '../common/utils/FormValidation';
import { selectSecurity } from '../common/store/securitySlice';

export const UnitDetail: React.FC = () => {
  const namespace = 'Units.UnitDetail';
  useDocumentTitle(t('title', namespace));

  const security = useAppSelector(selectSecurity).ui;
  const isAdmin = security.isAdmin;

  const { id = null } = useParams();
  const [unit, setUnit] = useState<TSFixMe>({});
  const hasOwners = unit.owners || false;
  const unitNumber = unit?.label || '';
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const formik = useFormik<{ votingStrategy: string }>({
    initialValues: { votingStrategy: 'first_vote' },
    // validate: () => setFormError(false),
    validationSchema: Yup.object({
      votingStrategy: Yup.string().required(),
    }),
    onSubmit: (values) => {
      if (id === null) {
        formik.setFieldError('votingStrategy', t('Form.error', namespace));
        formik.setSubmitting(false);
        return;
      }

      updateUnitVotingStrategy(id, values)
        .then((res) => {
          console.log(res);
        })
        .catch((er) => formik.setFieldError('votingStrategy', t('Form.error', namespace)))
        .finally(() => formik.setSubmitting(false));
    },
  });

  useEffect(() => {
    async function fetchUnit() {
      try {
        const data = await getUnit(id);
        setUnit(data);

        formik.setFieldValue('votingStrategy', data.votingStrategy);
        setIsLoading(false);
      } catch {
        setIsEmpty(true);
        setIsLoading(false);
      }
    }
    fetchUnit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Header
        title={`${t('Header.title', namespace)} ${unitNumber ? `č.${unitNumber}` : ''}`}
        customBacklink={routes.units}
      />
      <Content isLoading={isLoading} isEmpty={isEmpty}>
        <Card className="Card--content">
          <TextField
            name="useType"
            label={t('Form.Fields.UseType.label', namespace)}
            placeholder={t('Form.Fields.UseType.placeholder', namespace)}
            value={unit?.useType && t(`Enums.UnitUseType.${unit?.useType}`).toLowerCase()}
            className="mb-2"
            disabled={true}
          />
          {/*
          <TextField
            name="address"
            label={t('Form.Fields.Address.label', namespace)}
            placeholder={t('Form.Fields.Address.placeholder', namespace)}
            value={`${unit?.locationInfo?.municipalityPart} ${unit?.locationInfo?.houseNumber}`}
            className="mb-2"
            disabled={true}
          />
          */}
          {unit.share && unit.share.numerator && unit.share.denominator && (
            <>
              <RadioFieldLabel label={t('Form.Fields.share', namespace)} className="mt-3" />
              <Text style={{ marginTop: '-1rem' }}>
                {unit.share.numerator}/{unit.share.denominator}
              </Text>
            </>
          )}

          {hasOwners && (
            <>
              <RadioFieldLabel label={t('Form.Fields.Owners.label', namespace)} className="mt-3" />
              <div className="Flex FlexWrap FlexGap--1">
                {unit?.owners?.map((owner: TSFixMe) => {
                  const { profile } = owner;

                  return (
                    <div key={profile.id} className="UserList__cell UserList__cell--small mr-0">
                      <CardUserList
                        text={''}
                        to={`${routes.units}profile/${profile.id}`}
                        title={getProfileName(profile)}
                        variant="bordered"
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}

          <form onSubmit={formik.handleSubmit} className="mt-3">
            <RadioFieldLabel
              label={t('Form.Fields.VotingStrategy.label', namespace)}
              className="mt-2"
              error={{ message: formik.errors.votingStrategy, touched: formik.touched.votingStrategy }}
              required={true}
            />

            <RadioField
              name="votingStrategy"
              value="first_vote"
              className="mb-1"
              label={t('Form.Fields.VotingStrategy.default', namespace)}
              onChange={(e) => formik.setFieldValue('votingStrategy', e.target.value)}
              checked={formik.values.votingStrategy === 'first_vote'}
              disabled={!isAdmin}
              description={t('Form.Fields.VotingStrategy.default_description', namespace)}
            />

            <RadioField
              name="votingStrategy"
              value="with_quorum"
              className="mb-1"
              label={t('Form.Fields.VotingStrategy.separateVoting', namespace)}
              onChange={(e) => formik.setFieldValue('votingStrategy', e.target.value)}
              checked={formik.values.votingStrategy === 'with_quorum'}
              disabled={!isAdmin}
              description={t('Form.Fields.VotingStrategy.separateVoting_description', namespace)}
            />

            <RadioField
              name="votingStrategy"
              value="without_vote"
              className="mb-2"
              label={t('Form.Fields.VotingStrategy.excludedFromVoting', namespace)}
              onChange={(e) => formik.setFieldValue('votingStrategy', e.target.value)}
              checked={formik.values.votingStrategy === 'without_vote'}
              disabled={!isAdmin}
              description={t('Form.Fields.VotingStrategy.excludedFromVoting_description', namespace)}
            />

            {isAdmin && (
              <Button
                onClick={formik.handleSubmit}
                loading={formik.isSubmitting}
                disabledDeps={['demo', 'expired']}
                title={t('Form.submit', namespace)}
              />
            )}
          </form>
        </Card>
      </Content>
    </>
  );
};
