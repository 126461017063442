import { useState } from 'react';
import { useFormik } from 'formik';
import { IPostReqSecurityResetPasswordRequest } from '../../frontend-libs/evlapp-types';

import routes from '../../common/constants/routes';
import { t } from '../../common/i18n';
import { useAppThunkDispatch } from '../../common/hooks';
import { Row, Col, Button, ButtonLink, TextField, Text, FormMessage, FormMessageProps } from '../../common/components';
import Yup, { YupEmail } from '../../common/utils/FormValidation';

import { passwordReset } from '../../common/store/securitySlice';

export const PasswordResetForm: React.FC = (props) => {
  const [formMessage, setFormMessage] = useState<FormMessageProps>({ message: '', type: 'error' });

  const thunkDispatch = useAppThunkDispatch();
  const [isEmailSent, setIsEmailSent] = useState(false);

  const formik = useFormik<IPostReqSecurityResetPasswordRequest>({
    initialValues: { email: '' },
    validate: () => setFormMessage({}),
    validationSchema: Yup.object({ email: YupEmail }),
    onSubmit: (values) => {
      thunkDispatch(passwordReset(values))
        .unwrap()
        .then(() => {
          formik.setSubmitting(false);
          setIsEmailSent(true);
          setFormMessage({ message: t('PasswordReset.PasswordReset.Form.success'), type: 'success' });
        })
        .catch(() => {
          formik.setSubmitting(false);
          setFormMessage({ message: t('PasswordReset.PasswordReset.Form.error'), type: 'error' });
        });
    },
  });

  return (
    <form className="OnboardingForm" onSubmit={formik.handleSubmit}>
      <Row>
        <Col className="mb-2">
          <Text size="h1" color="Neutral800">
            {t('PasswordReset.PasswordReset.Form.title')}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>{formMessage.message && <FormMessage {...formMessage} />}</Col>
      </Row>
      {!isEmailSent && (
        <>
          <Row>
            <Col size="12" className="mb-2 mt-2">
              <TextField
                name="email"
                autocomplete="username"
                placeholder={t('PasswordReset.PasswordReset.Form.Fields.Email.placeholder')}
                label={t('PasswordReset.PasswordReset.Form.Fields.Email.label')}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={{ message: formik.errors.email, touched: formik.touched.email }}
              />
            </Col>
          </Row>

          <Row>
            <Col size="12" className="mb-2">
              <Button
                type="submit"
                title={t('PasswordReset.PasswordReset.Form.Fields.Submit.title')}
                loading={formik.isSubmitting}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <div className="OnboardingDivider">
                <Text size="body-xs" as="span" color="Neutral600">
                  {t('PasswordReset.dividerText')}
                </Text>
              </div>
            </Col>
          </Row>
        </>
      )}
      <Row className={isEmailSent ? 'mt-2' : ''}>
        <Col className="mb-2">
          <ButtonLink variant="secondary" to={{ pathname: routes.login }} title={t('PasswordReset.toLogin')} />
        </Col>
      </Row>
    </form>
  );
};
