import cx from 'classnames';
import { Link, NavLink, LinkProps, useNavigate } from 'react-router-dom';

import { t } from '../../i18n';
import { Icon, Text, TextField } from '..';
import { useEffect, useRef } from 'react';
import { useAppSelector } from '../../hooks';
import { selectSecurity } from '../../store/securitySlice';
import { Hamburger } from '../Hamburger/Hamburger';
import { setElementHeight } from '../../utils/ElementHeight/elementHeight';

interface HeaderTabProps {
  label: string;
  to: LinkProps['to'];
  renderCondition?: 'isAdmin';
}

export const HeaderTab: React.FC<HeaderTabProps> = (tab) => {
  const security = useAppSelector(selectSecurity).ui;
  const isAdmin = security.isAdmin;

  if (tab.renderCondition === 'isAdmin' && !isAdmin) return null;

  return (
    <NavLink end to={tab.to} className={({ isActive }) => cx('HeaderTab', isActive && 'HeaderTab--active')}>
      {tab.label}
    </NavLink>
  );
};

interface BackLinkProps {
  backlink?: number;
  customBacklink?: never;
  className?: string;
}

const BackLink: React.FC<BackLinkProps> = (props) => {
  const { backlink = -1 } = props;
  const navigate = useNavigate();

  return (
    <div className={cx('HeaderBacklink', props.className)} onClick={() => navigate(backlink)}>
      <Icon color="Primary500" name="arrowLeft" />
    </div>
  );
};

interface CustomBackLinkProps {
  backlink?: never;
  customBacklink: LinkProps['to'];
  className?: string;
}
const CustomBackLink: React.FC<CustomBackLinkProps> = (props) => {
  return (
    <div className={cx('HeaderBacklink', props.className)}>
      <Link to={props.customBacklink}>
        <Icon color="Primary500" name="arrowLeft" />
      </Link>
    </div>
  );
};

interface HeaderProps {
  title?: string;
  actions?: JSX.Element | false;
  secondRow?: JSX.Element;
  afterTitle?: JSX.Element;
  tabs?: HeaderTabProps[];
  search?: {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
}

type HeaderP = HeaderProps & (BackLinkProps | CustomBackLinkProps);

export const Header: React.FC<HeaderP> = (props) => {
  const tabs = props?.tabs?.map((tab) => <HeaderTab key={tab.to.toString()} {...tab} />);

  const headerRef = useRef<HTMLDivElement>(null);
  const headerSmallRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleHeight = () => {
      setElementHeight(headerRef.current, '--header-height');
      setElementHeight(headerSmallRef.current, '--header-sm-height');
    };

    handleHeight();
    window.addEventListener('resize', handleHeight);
    return () => window.removeEventListener('resize', handleHeight);
  }, [headerRef, headerSmallRef]);

  return (
    <>
      <div className={cx('Header', props.tabs && 'Header--has-tabs')} ref={headerRef}>
        <div className="HeaderRow">
          <Hamburger action="open" className="mr-1" />
          {props.backlink && <BackLink backlink={props.backlink} className="HeaderBacklink--lg" />}
          {props.customBacklink && (
            <CustomBackLink customBacklink={props.customBacklink} className="HeaderBacklink--lg" />
          )}

          <div className="HeaderTitle HeaderTitle--lg">
            {props.title && <Text size="h1">{props.title}</Text>}
            {props.search && (
              <div className="HeaderSearch">
                <TextField
                  name="search"
                  value={props.search.value}
                  onChange={props.search.onChange}
                  placeholder={t('Header.Search.placeholder')}
                />
              </div>
            )}
            {props.afterTitle}
          </div>
          {props.actions && <div className="HeaderActions">{props.actions}</div>}
        </div>
        {props.secondRow && <div className="HeaderRow">{props.secondRow}</div>}
        {tabs && <div className="HeaderRow HeaderRow--tabs">{tabs}</div>}
      </div>
      <div className="Header--sm">
        <div className="HeaderRow">
          {props.backlink && <BackLink backlink={props.backlink} className="HeaderBacklink--sm" />}
          {props.customBacklink && (
            <CustomBackLink customBacklink={props.customBacklink} className="HeaderBacklink--sm" />
          )}
          <div className="HeaderTitle HeaderTitle--sm" ref={headerSmallRef}>
            {props.title && <Text size="h2">{props.title}</Text>}
            {props.afterTitle}
          </div>
        </div>
      </div>
    </>
  );
};
