import {
  IBuildingProfile,
  IGetResBuildingProfileDetail,
  IGetResBuildingUnit,
  IGetResBuildingUnitDetail,
  TSFixMe,
} from '../../frontend-libs/evlapp-types';

import { store } from '../../common/store/store';
import { RestApi } from '../../common/services';
import { AxiosRequestConfig } from 'axios';
import { downloadFile } from '../../common/utils';

/**
 * @return {Promise<IPoll[]>} array with polls
 */
export const getUnits = async (): Promise<IGetResBuildingUnit> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.get<never, IGetResBuildingUnit>(`/building/${buildingId}/unit`);
};

export const getUnit = async (unitId: string | null): Promise<IGetResBuildingUnitDetail> => {
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.get<string, IGetResBuildingUnitDetail>(`/building/${buildingId}/unit/${unitId}`);
};

export const getProfile = async (profileId: string | null): Promise<IBuildingProfile> => {
  const buildingId = store.getState().security.ui.buildingId;
  const res = await RestApi.get<string, IGetResBuildingProfileDetail>(`/building/${buildingId}/profile/${profileId}`);

  return res.profile;
};

export const sendAppInvitation = async (profileId?: string): Promise<void> => {
  if (!profileId) return;
  const buildingId = store.getState().security.ui.buildingId;
  return await RestApi.post<never, never>(`/building/${buildingId}/profile/${profileId}/invite`);
};

export const exportBuildingOwners = async (): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;

  // return await RestApi.get<never, never>();

  const config: AxiosRequestConfig = {
    method: 'GET',
    responseType: 'blob',
    url: `/building/${buildingId}/profiles/export`,
  };

  return await RestApi.axios(config).then((res) => {
    downloadFile({
      data: res.data,
      filename: `eVlastnici-budova-${buildingId}.xlsx`,
      mime: res.headers['content-type'],
    });
  });
};

export const importBuildingOwners = async (values: TSFixMe): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;

  return await RestApi.post<TSFixMe, TSFixMe>(`/building/${buildingId}/profiles/import`, values, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateUnitVotingStrategy = async (
  unitId: string,
  values: { votingStrategy: string }
): Promise<TSFixMe> => {
  const buildingId = store.getState().security.ui.buildingId;

  return await RestApi.patch<TSFixMe, TSFixMe>(`/building/${buildingId}/unit/${unitId}`, values);
};
