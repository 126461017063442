import ReactDOM from 'react-dom';

import { useAppDispatch } from '../../hooks';
import { FlashMessage, FlashMessageProps } from '..';
import { removeFlashMessage } from '../../store/uiSlice';
import { uniqId } from '../../utils/uniqId';

export interface FlashMessageContainerProps {
  items?: FlashMessageProps[];
}

export const FlashMessageContainer: React.FC<FlashMessageContainerProps> = (props) => {
  const dispatch = useAppDispatch();
  if (!props.items) return null;

  const items = props.items.map((item) => {
    const id = item.id || uniqId('msg');

    setTimeout(() => {
      dispatch(removeFlashMessage({ id }));
    }, item.timeout || -1);

    return <FlashMessage key={id} {...item} handleOnClose={() => dispatch(removeFlashMessage({ id }))} />;
  });

  const container = <div className="FlashMessage__container">{items}</div>;

  return ReactDOM.createPortal(container, document.querySelector('#overlay') as Element);
};
