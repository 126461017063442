import { IFeed, IGetResFeed } from '../../frontend-libs/evlapp-types';
import { RestApi } from '../../common/services';

import { store } from '../../common/store/store';

export const sortByDate = (items: IFeed[]): IFeed[] => {
  return items.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
};

export const getFeeds = async (): Promise<IGetResFeed> => {
  const buildingId = store.getState().security.ui.buildingId;
  const isAdmin = store.getState().security.ui.isAdmin;

  const res = await RestApi.get<never, IGetResFeed>(`/building/${buildingId}/message`);

  return sortByDate(res.filter((item) => item.isPublished === true || isAdmin));
};

export const getFeedById = async (id: string): Promise<IFeed> => {
  const buildingId = store.getState().security.ui.buildingId;

  return await RestApi.get<never, IFeed>(`/building/${buildingId}/message/${id}`);
};

export const createFeed = async (values: Partial<IFeed>): Promise<void> => {
  const buildingId = store.getState().security.ui.buildingId;

  return await RestApi.post<Partial<IFeed>, void>(`/building/${buildingId}/message`, values);
};

export const updateFeed = async (id: string, values: Partial<IFeed>): Promise<void> => {
  const buildingId = store.getState().security.ui.buildingId;

  return await RestApi.patch<Partial<IFeed>, void>(`/building/${buildingId}/message/${id}`, values);
};

export const deleteFeedById = async (id: string): Promise<void> => {
  const buildingId = store.getState().security.ui.buildingId;

  return await RestApi.delete<void>(`/building/${buildingId}/message/${id}`);
};
