import routes from '../../common/constants/routes';
import { t } from '../../common/i18n';
import { ButtonLink, Col, Row, Text } from '../../common/components';

export const IncompleteData: React.FC = () => {
  const namespace = 'AdminRegistration.IncompleteData';

  return (
    <div className="OnboardingForm">
      <Row>
        <Col className="mb-4">
          <Text size="h1">{t('heading', namespace)}</Text>
        </Col>
      </Row>

      <Row>
        <Col>
          <ButtonLink title={t('buttonTitle', namespace)} to={{ pathname: routes.registration }} />
        </Col>
      </Row>
    </div>
  );
};
