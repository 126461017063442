import cx from 'classnames';

import { Colors } from '../../constants/Colors';

type TextSizes = 'h1' | 'h2' | 'h3' | 'body-large' | 'body-medium' | 'body-small' | 'body-xs';
type TextWeights = '400' | '500' | '600' | '700';
type TextAligns = 'left' | 'center' | 'right';

interface TextProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  as?: keyof Pick<JSX.IntrinsicElements, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div' | 'span'>;
  size?: TextSizes;
  weight?: TextWeights;
  color?: Colors;
  align?: TextAligns;
}

const getElementTag = (size: TextSizes) => {
  if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(size)) {
    return size;
  } else if (['body-large', 'body-medium', 'body-small', 'body-xs'].includes(size)) {
    return 'p';
  } else {
    return 'span';
  }
};

export const Text: React.FC<TextProps> = (props) => {
  const { as, size = 'body-medium', weight, color, align, className, ...rest } = props;

  let Element = getElementTag(size) as keyof JSX.IntrinsicElements;
  if (as) Element = as;

  return (
    <Element
      className={cx(
        `text-${size}`,
        color && `${color}`,
        weight && `Weight${weight}`,
        className,
        align && `text--${align}`
      )}
      {...rest}
    >
      {props.children}
    </Element>
  );
};
