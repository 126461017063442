import cx from 'classnames';

interface RowProps {
  id?: string;
  className?: string;
}

export const Row: React.FC<RowProps> = (props) => (
  <div id={props.id} className={cx('row', props.className)}>
    {props.children}
  </div>
);
