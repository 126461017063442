import React from 'react';

import { useAppSelector } from '../../common/hooks';
import { selectPoll } from '../../common/store/pollSlice';

import { CardQuestionResults, Text } from '../../common/components';
import { t } from '../../common/i18n';
import { TSFixMe } from '../../frontend-libs/evlapp-types';

export const TabPoll: React.FC = () => {
  const namespace = 'Poll.Results.TabPoll';
  const poll = useAppSelector(selectPoll);

  return (
    <>
      <Text size="h2" color="Neutral800" className="mb-3">
        {t('title', namespace)}
      </Text>
      {poll?.questions?.map((question: TSFixMe, index: number) => {
        return (
          <CardQuestionResults
            key={question.id}
            title={question.name}
            label={`${t('questionLabel', namespace)} ${index + 1}`}
            values={question.voteResult.count}
            isResultPublished={poll.isResultPublished}
            detailLink={`question/${question.id}`}
            decision={question.voteResult.decision}
          />
        );
      })}
    </>
  );
};
