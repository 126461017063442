import { useRef, useState } from 'react';
import cx from 'classnames';

import { Text, Icon } from '../index';

interface AccordionProps extends React.HTMLAttributes<HTMLElement> {
  title: string;
  isOpen?: boolean;
}

export const Accordion: React.FC<AccordionProps> = (props) => {
  const { title, isOpen = true, className } = props;

  const contentElement = useRef<HTMLDivElement>(null);
  const [state, setState] = useState({ isOpened: isOpen, height: isOpen ? 'auto' : '0px' });

  // TODO: Check multiple rerender
  const toggleAccordion = () => {
    setState((prevState) => ({
      ...prevState,
      isOpened: !prevState.isOpened,
      height: `${contentElement?.current?.scrollHeight}px`,
    }));

    if (state.isOpened) {
      setTimeout(() => setState((prevState) => ({ ...prevState, height: '0' })), 0);
    } else {
      setTimeout(() => setState((prevState) => ({ ...prevState, height: 'auto' })), 350);
    }
  };

  return (
    <div className={cx('Accordion', `${state.isOpened ? 'Accordion--opened' : 'Accordion--closed'}`, className)}>
      <div className="AccordionHeader" onClick={toggleAccordion}>
        <Text as="span" size="h2" color="Neutral800">
          {title}
        </Text>
        <Icon name="arrowUp" className="Accordion__toggler" color="Neutral700" />
      </div>
      <div className="AccordionContentWrapper" style={{ height: state.height }}>
        <div className="AccordionContent" ref={contentElement}>
          {props.children}
        </div>
      </div>
    </div>
  );
};
