import cx from 'classnames';
import { IPollQuestionImage } from '../../../frontend-libs/evlapp-types';

import { Card, Image } from '../index';

interface CardGalleryProps {
  images: IPollQuestionImage[];
  className?: string;
}

export const CardGallery: React.FC<CardGalleryProps> = (props) => {
  const items = props.images.map((image) => <Image key={image.id} ratio="standard" {...image} />);

  return <Card className={cx('CardGallery', props.className)}>{items}</Card>;
};
