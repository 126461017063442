import { RestApi } from '../../common/services';

import { store } from '../../common/store/store';

import {
  IGetResUserProfile,
  IPutReqUserProfile,
  IPutResUserProfile,
  IPostReqUserChangePassword,
  IPostResUserChangePassword,
  TSFixMe,
} from '../../frontend-libs/evlapp-types';

/**
 * @return {Promise<ILoginProfile>} - instance of current user
 */
export const getUserProfile = async (): Promise<IGetResUserProfile> => {
  return await RestApi.get<never, IGetResUserProfile>('/user/profile');
};

/**
 * @param {IProfile} values - profile values
 * @param {string} profileId - profileId
 * @return {Promise<ILoginPayload>} - instance of current user
 */
export const updateUserProfile = async (values: IPutReqUserProfile): Promise<IPutResUserProfile> => {
  const profileId = store.getState().security.profile?.id;

  if (values.email === '') values.email = null;
  if (values.phone === '') values.phone = null;

  return await RestApi.put<IPutReqUserProfile, IPutResUserProfile>(`/user/profile/${profileId}`, values);
};

export const updateUserProfileRequest = async (values: IPutReqUserProfile): Promise<TSFixMe> => {
  const profileId = store.getState().security.profile?.id;

  if (values.email === '') values.email = null;
  if (values.phone === '') values.phone = null;

  return await RestApi.post<IPutReqUserProfile, TSFixMe>(`/user/profile/${profileId}/request-confirmation`, values);
};

export const confirmVerificationCode = async (values: { token: string; code: string }): Promise<TSFixMe> => {
  const profileId = store.getState().security.profile?.id;
  return await RestApi.post<TSFixMe, TSFixMe>(`/user/profile/${profileId}/confirmation`, values);
};

export const updateProfilePassword = async (
  values: IPostReqUserChangePassword
): Promise<IPostResUserChangePassword> => {
  return await RestApi.post<IPostReqUserChangePassword, IPostResUserChangePassword>(
    '/user/profile/change-password',
    values
  );
};
