import React, { useRef, useState } from 'react';

import { t } from '../../common/i18n';
import { Button, CardQuestion, CardUserList, FileField, Modal, Text } from '../../common/components';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { getPoll, selectPoll } from '../../common/store/pollSlice';
import { IProfile, TSFixMe } from '../../frontend-libs/evlapp-types';
import { Colors } from '../../common/constants/Colors';
import routes from '../../common/constants/routes';
import { getProfileName } from '../../common/utils/Profile/profile';
import { generatePollTicket, pollAdminVote } from '../services/PollService';
import { setFlashMessage } from '../../common/store/uiSlice';
import { useModal } from '../../common/components/Modal/useModal';
import { useFormik } from 'formik';
import { selectSecurity } from '../../common/store/securitySlice';
import Yup from '../../common/utils/FormValidation';

const getOwnerStatus = (profile: IProfile) => {
  const { email, phone } = profile;
  let profileContacts = 'missingAll';
  if (email && phone) profileContacts = 'missingNone';
  if (email && !phone) profileContacts = 'missingPhone';
  if (!email && phone) profileContacts = 'missingEmail';

  return profileContacts;
};

const MAXOWNERS = 2;

const ownersListCount = (owners: TSFixMe[]) => {
  if (MAXOWNERS < owners.length) {
    const count = owners.length - MAXOWNERS;

    return (
      <Text size="body-medium" weight="400" color="Neutral750" className="UserList__count">
        {t('ownersCount', 'Units.Table', { count: count })}
      </Text>
    );
  }

  return null;
};

const ownersList = (owners: TSFixMe[]) => {
  return owners
    .filter((_, index) => index < MAXOWNERS)
    .map((owner) => {
      const { profile } = owner;

      const ownerName = getProfileName(profile);
      const ownerStatus = getOwnerStatus(profile);

      let textColor: Colors = 'SystemRed';
      if (ownerStatus === 'missingNone') textColor = 'SystemGreen';
      if (ownerStatus === 'missingPhone' || ownerStatus === 'missingEmail') textColor = 'SystemOrange';

      return (
        <CardUserList
          key={profile.id}
          to={`${routes.units}profile/${profile.id}`}
          title={ownerName}
          text={t(`Enums.OwnerType.${ownerStatus}`)}
          textColor={textColor}
        />
      );
    });
};

export const TabVoterList: React.FC = () => {
  const namespace = 'Poll.Results.TabVoterList';

  const dispatch = useAppDispatch();
  const poll = useAppSelector(selectPoll);
  const security = useAppSelector(selectSecurity);
  const [isShown, toggle] = useModal();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedUnitId, setSelectedUnitId] = useState('');

  const initialValues = {
    questions: {},
    attachment: null,
    voterId: security?.profile?.id,
  };

  const handleGenerateTicket = (unitId: string) => {
    generatePollTicket(poll.id, unitId).catch(() => {
      dispatch(setFlashMessage({ message: t('Ticket.error', namespace), type: 'error' }));
    });
  };

  const handleModalToggle = (unitId: string) => {
    setSelectedUnitId(unitId);
    toggle();
    if (unitId === '') {
      formik.setFieldValue('questions', {});
      formik.setFieldValue('attachment', null);
      formik.setFieldTouched('attachment', false);
      if (fileInputRef.current) fileInputRef.current.value = '';
      if (security?.profile?.id) formik.setFieldValue('voterId', security?.profile?.id);
    }
  };

  const formik = useFormik<TSFixMe>({
    initialValues: { ...initialValues },
    validationSchema: Yup.object({
      attachment: Yup.mixed().required(),
    }),
    onSubmit: (values) => {
      pollAdminVote(poll.id, selectedUnitId, values)
        .then(() => {
          const updateModal = () => {
            dispatch(setFlashMessage({ message: t('Flash.success', namespace), type: 'success' }));
            handleModalToggle('');
          };

          if (poll.id) {
            dispatch(getPoll(poll.id)).then(() => {
              formik.setSubmitting(false);
              updateModal();
            });
          } else {
            updateModal();
          }
        })
        .catch(() => {
          dispatch(setFlashMessage({ message: t('Flash.error', namespace), type: 'error' }));
          formik.setSubmitting(false);
        });
    },
  });

  const handleQuestion = async (questionId: string, answer: string) => {
    const questions = formik.values.questions;
    questions[questionId] = answer;

    await formik.setFieldValue('questions', questions);
  };

  return (
    <>
      <div className="UserList UserList--top">
        <div className="UserList__header">
          <div className="UserList__row">
            <div className="UserList__cell UserList__cell--small">
              <Text size="body-small" color="Neutral600" weight="600" className="text-uppercase">
                {t('unit', namespace)}
              </Text>
            </div>
            <div className="UserList__cell">
              <Text size="body-small" color="Neutral600" weight="600" className="text-uppercase">
                {t('owner', namespace)}
              </Text>
            </div>
          </div>
        </div>
        <div className="UserList__content">
          {poll.units.map((x: TSFixMe, i: TSFixMe) => {
            return (
              <div className="UserList__row" key={i}>
                <div className="UserList__cell UserList__cell--small">
                  <CardUserList
                    to={`${routes.units}unit/${x.id}`}
                    title={`${t('Units.Table.Body.unit')} ${x.label}`}
                    text={`${t(`Vote.${x.completedVote ? 'completed' : 'nonCompleted'}`, namespace)}`}
                    textColor={x.completedVote ? 'Primary600' : 'SystemRed'}
                    afterText={
                      !x.completedVote ? (
                        <div className="CardUserList__tickets">
                          {x.canGenerateTicket && (
                            <div
                              className="text-body-small link Neutral600 CardUserList__text--ticket"
                              onClick={() => handleGenerateTicket(x.id)}
                            >
                              {t('Ticket.generate', namespace)}
                            </div>
                          )}
                          {!x.completedVote && !poll.isResultPublished && (
                            <div
                              className="text-body-small link Neutral600 CardUserList__text--vote"
                              onClick={() => handleModalToggle(x.id)}
                            >
                              {t('AdminVote.button', namespace)}
                            </div>
                          )}
                        </div>
                      ) : undefined
                    }
                  />
                </div>
                <div className="UserList__cell">
                  {ownersList(x.owners)}

                  {ownersListCount(x.owners)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal isShown={isShown} onHide={() => handleModalToggle('')} size="large">
        <Text size="h2" align="center" className="mb-2">
          {t('AdminVote.title', namespace, { unit: '' })}
        </Text>
        <Text size="body-medium" color="Neutral700" align="center" className="mb-3">
          {t('AdminVote.subtitle', namespace)}
        </Text>
        {poll?.questions?.map((question: TSFixMe, index: number) => {
          return (
            <CardQuestion
              key={question.id}
              title={question.name}
              label={`${t('Poll.Detail.TabDetail.Question.label')} ${index + 1}`}
              answer={formik.values.questions[question.id]}
              onYes={() => handleQuestion(question.id, 'yes')}
              onNo={() => handleQuestion(question.id, 'no')}
              onUndecided={() => handleQuestion(question.id, 'undecided')}
              isEditable={true}
            />
          );
        })}
        <FileField
          name="attachment"
          label={t('AdminVote.attachment', namespace)}
          filesLabel={`${namespace}.AdminVote.filesAction`}
          multiple={false}
          reference={fileInputRef}
          formikValue={formik.values.attachment}
          className="mt-2 mb-4"
          error={{ message: formik.errors.attachment as string, touched: formik.touched.attachment as boolean }}
          onChange={(event) => {
            if (event && event.currentTarget && event.currentTarget.files) {
              // TODO: Only first file, from target...
              formik.setFieldValue('attachment', event.currentTarget.files[0]);
            }
          }}
        />
        <div className="Flex FlexAlign--center FlexJustify--center mb-2">
          <Button
            onClick={formik.handleSubmit}
            title={t('AdminVote.submit', namespace)}
            loading={formik.isSubmitting}
            wide={true}
            disabledDeps={['demo', 'expired']}
          />
        </div>
      </Modal>
    </>
  );
};
