import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { IBuildingProfile } from '../frontend-libs/evlapp-types';

import { t } from '../common/i18n';
import { useAppDispatch, useDocumentTitle } from '../common/hooks';
import routes from '../common/constants/routes';
import { Button, Card, Content, Header, TextField, PhoneField, Modal, Text } from '../common/components';

import { addMissingContact } from '../Poll/services/PollService';
import { getProfile, sendAppInvitation } from './services/UnitService';
import Yup, { backendViolations, YupEmailNonRequired, YupPhoneNonRequired } from '../common/utils/FormValidation';
import { setFlashMessage } from '../common/store/uiSlice';
import { useModal } from '../common/components/Modal/useModal';

export const ProfileDetail: React.FC = () => {
  const namespace = 'Units.ProfileDetail';

  const dispatch = useAppDispatch();

  useDocumentTitle(t('title', namespace));

  const { id = null } = useParams();
  const [profile, setProfile] = useState<IBuildingProfile>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isInvitationLoading, setIsInvitationLoading] = useState(false);

  const [isShown, toggle] = useModal();
  const canBeInvited = profile.email || profile.phone;

  useEffect(() => {
    async function fetchProfile() {
      try {
        const data = await getProfile(id);
        setProfile(data);
        setIsLoading(false);
      } catch {
        setIsEmpty(true);
        setIsLoading(false);
      }
    }
    fetchProfile();
  }, [id]);

  const handleSendAppInvitation = () => {
    if (!profile.id) return;

    setIsInvitationLoading(true);

    sendAppInvitation(profile.id)
      .then(() => {
        toggle();
        setIsInvitationLoading(false);
        dispatch(setFlashMessage({ message: t('InvitationModal.Flash.success', namespace), type: 'success' }));
      })
      .catch(() => {
        setIsInvitationLoading(false);
        dispatch(setFlashMessage({ message: t('InvitationModal.Flash.error', namespace), type: 'error' }));
      });
  };

  const formik = useFormik<IBuildingProfile>({
    enableReinitialize: true,
    initialValues: {
      firstName: profile.firstName || '',
      lastName: profile.lastName || '',
      company: profile.company || '',
      phone: profile.phone || '',
      email: profile.email || '',
      birthDate: profile.birthDate,
    },

    validationSchema: Yup.object({
      email: YupEmailNonRequired,
      phone: YupPhoneNonRequired,
    }),

    onSubmit: (values) => {
      if (!profile.id) return;

      addMissingContact(profile.id, { email: values.email || '', phone: values.phone || '' })
        .then((res) => {
          setProfile(res.profile);
          dispatch(setFlashMessage({ message: t('Form.Flash.success', namespace), type: 'success' }));
        })
        .catch((error) => {
          const validations = backendViolations(formik, error);

          if (validations === 'valid') {
            dispatch(setFlashMessage({ message: t('Form.Flash.error', namespace), type: 'error' }));
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <>
      <Header title={`${t('Header.title', namespace)}`} customBacklink={routes.units} />
      <Content isLoading={isLoading} isEmpty={isEmpty}>
        <Card className="Card--content">
          <form onSubmit={formik.handleSubmit}>
            {profile.firstName && (
              <TextField
                name="firstName"
                label={t('Form.Fields.FirstName.label', namespace)}
                placeholder={t('Form.Fields.FirstName.placeholder', namespace)}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                className="mb-2"
                disabled={true}
              />
            )}
            {profile.lastName && (
              <TextField
                name="lastName"
                label={t('Form.Fields.LastName.label', namespace)}
                placeholder={t('Form.Fields.LastName.placeholder', namespace)}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                className="mb-2"
                disabled={true}
              />
            )}
            {profile.company && (
              <TextField
                name="company"
                label={t('Form.Fields.Company.label', namespace)}
                placeholder={t('Form.Fields.Company.placeholder', namespace)}
                value={formik.values.company}
                onChange={formik.handleChange}
                className="mb-2"
                disabled={true}
              />
            )}
            <TextField
              name="email"
              type="email"
              label={t('Form.Fields.Email.label', namespace)}
              placeholder={t('Form.Fields.Email.placeholder', namespace)}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={{ touched: formik.touched.email, message: formik.errors.email }}
              className="mb-2"
              disabled={profile.isUser}
            />
            <PhoneField
              name="phone"
              label={t('Form.Fields.Phone.label', namespace)}
              placeholder={t('Form.Fields.Phone.placeholder', namespace)}
              value={formik.values.phone}
              onChange={(phone) => formik.setFieldValue('phone', phone)}
              error={{ message: formik.errors.phone, touched: formik.touched.phone }}
              className="mb-2"
              disabled={profile.isUser}
            />

            <TextField
              name="birthNumber"
              label={t('Form.Fields.BirthNumber.label', namespace)}
              placeholder={t('Form.Fields.BirthNumber.placeholder', namespace)}
              value={formik.values.birthNumber}
              onChange={formik.handleChange}
              className="mb-2"
              disabled={true}
            />

            {!profile.isUser && (
              <div className="Flex FlexAlign--center">
                <Button
                  onClick={formik.handleSubmit}
                  loading={formik.isSubmitting}
                  disabledDeps={['demo', 'expired']}
                  title={t('Form.Fields.Submit.title', namespace)}
                  className="mr-1"
                />
                {canBeInvited && (
                  <>
                    <Button
                      title={t('InvitationModal.linkTitle', namespace)}
                      onClick={toggle}
                      variant="text"
                      disabledDeps={['demo', 'expired']}
                    />
                  </>
                )}
              </div>
            )}

            <Modal isShown={isShown} onHide={toggle}>
              <Text size="h2" align="center" className="mb-2">
                {t('InvitationModal.title', namespace)}
              </Text>
              <Text size="body-medium" color="Neutral700" align="center" className="mb-3">
                {t('InvitationModal.subtitle', namespace)}
              </Text>
              <div className="Flex FlexColumn FlexAlign--center">
                <Button
                  title={t('InvitationModal.confirm', namespace)}
                  wide={true}
                  onClick={handleSendAppInvitation}
                  loading={isInvitationLoading}
                />
              </div>
            </Modal>
          </form>
        </Card>
      </Content>
    </>
  );
};
