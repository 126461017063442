import cx from 'classnames';
import { useState } from 'react';
import { t } from '../../../i18n';
import { FormFieldProps } from '../Base';
import { CheckboxField } from '../CheckboxField/CheckboxField';
import { TextField, TextFieldProps } from '../TextField/TextField';

interface PasswordFieldProps<T> extends FormFieldProps<string> {
  placeholder?: string;
  className?: string;
  autocomplete?: string;
  onChange?: (e: React.ChangeEvent<T>) => void;
}

export const PasswordField: React.FC<PasswordFieldProps<HTMLInputElement>> = (props) => {
  const [type, setType] = useState<TextFieldProps<HTMLInputElement>['type']>('password');

  const typeToggler = (
    <CheckboxField
      name={`${props.name}-show-password`}
      label={t('PasswordField.showPassword')}
      onChange={() => setType(type === 'text' ? 'password' : 'text')}
    />
  );

  return (
    <TextField
      type={type}
      afterLabel={typeToggler}
      autocomplete={props.autocomplete}
      {...props}
      className={cx('TextField--password', props.className)}
    />
  );
};
