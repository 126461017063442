import cx from 'classnames';

export interface FormMessageProps {
  message?: string;
  type?: 'success' | 'error' | 'warning';
  variant?: 'light' | 'dark';
  className?: string;
}

export const FormMessage: React.FC<FormMessageProps> = (props) => {
  const { children, message, type = 'error', variant = 'light' } = props;

  if (!message && !children) return null;

  return (
    <div
      className={cx(
        'FormMessage',
        `FormMessage--${type}`,
        `FormMessage--${variant}`,
        'text-body-medium',
        'text--center',
        props.className
      )}
    >
      <span>{children || message}</span>
    </div>
  );
};
