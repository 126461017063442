import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { TSFixMe } from '../../frontend-libs/evlapp-types';
import cx from 'classnames';

import routes from '../../common/constants/routes';
import { t } from '../../common/i18n';
import {
  Button,
  ButtonLink,
  ContentEditableDiv,
  FlashMessage,
  Header,
  Icon,
  Modal,
  Spinner,
  Text,
} from '../../common/components';

import { createNewPoll, getPollDrafts, removePollDraft } from '../services/PollService';
import { Question } from '../components/addQuestion';
import { useModal } from '../../common/components/Modal/useModal';
import { setFlashMessage } from '../../common/store/uiSlice';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { Link } from 'react-router-dom';
import { selectSecurity } from '../../common/store/securitySlice';

export const AddPoll: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [draftModal, setDraftModal] = useModal();
  const [isRemovingDraft, setRemovingDraft] = useState(false);
  const [pollData, setPollData] = useState<TSFixMe>({
    id: '',
    name: '',
    questions: [],
    created: false,
    isLoading: false,
  });

  useEffect(() => {
    async function fetchDrafts() {
      const drafts = await getPollDrafts();
      const draft = drafts[0];
      if (draft) {
        setPollData((prevState: TSFixMe) => ({
          ...prevState,
          id: draft.id,
          name: draft.name,
          questions: draft.questions,
          created: true,
          isLoading: false,
        }));
      }
      setIsLoading(false);
    }
    fetchDrafts();
  }, []);

  const handleCreateNewPoll = async () => {
    if (pollData.isLoading) return;

    setPollData((prevState: TSFixMe) => ({ ...prevState, created: true, isLoading: true }));
    createNewPoll({ name: pollData.name.trim() }).then((res) => {
      setPollData((prevState: TSFixMe) => ({
        ...prevState,
        id: res.id,
        isLoading: false,
        questions: [{ isDraft: true }],
      }));
    });
  };

  const handleRemovePollDraft = async () => {
    setRemovingDraft(true);

    if (!pollData.id) {
      dispatch(setFlashMessage({ message: t('Poll.MissingContacts.Flash.Remove.success'), type: 'success' }));
      return navigate(routes.poll);
    }

    removePollDraft(pollData.id)
      .then(() => {
        dispatch(setFlashMessage({ message: t('Poll.MissingContacts.Flash.Remove.success'), type: 'success' }));
        navigate(routes.poll);
      })
      .catch(() => {
        dispatch(setFlashMessage({ message: t('Poll.MissingContacts.Flash.Remove.error'), type: 'error' }));
      });
  };

  const handleAddQuestionDraft = () => {
    const questions = pollData.questions.push({ isDraft: true });
    setPollData((prevState: TSFixMe) => ({ ...prevState, ...questions }));
  };

  // prettier-ignore
  const canPollSubmit = pollData.id && pollData.questions.length > 0 && !pollData.questions.find((item: TSFixMe) => item.isDraft === true);
  const canCreateNewQuestion = pollData.id && !pollData.questions.find((item: TSFixMe) => item.isDraft);
  const canCreatePoll = !pollData.id && pollData.name.length > 0;

  const ui = useAppSelector(selectSecurity).ui;
  const hasSubscription = ui.tokens?.filter((item) => item.type === 'building-subscription').length || 0;
  const hasPollVotes = ui.tokens?.filter((item) => item.type === 'poll-publish').length || 0;

  return (
    <>
      {isLoading ? (
        <Spinner size="large" position="overlay" />
      ) : (
        <>
          <Modal isShown={draftModal} onHide={setDraftModal}>
            <Text size="h2" align="center" className="mb-2">
              {t('Poll.AddPoll.Header.discardDraftModal.title')}
            </Text>
            <Text size="body-medium" color="Neutral700" align="center" className="mb-3">
              {t('Poll.AddPoll.Header.discardDraftModal.subtitle')}
            </Text>
            <div className="Flex FlexColumn FlexAlign--center">
              <Button
                title={t('Poll.AddPoll.Header.discardDraftModal.confirm')}
                onClick={handleRemovePollDraft}
                loading={isRemovingDraft}
              />
            </div>
          </Modal>

          <Header
            afterTitle={
              <ContentEditableDiv
                value={pollData.name}
                onChange={(value) => setPollData((prevState: TSFixMe) => ({ ...prevState, name: value }))}
                placeholder={t('Poll.AddPoll.Header.placeholder')}
                className="HeaderTextField"
                contentEditable={!pollData.created}
              />
            }
            customBacklink={routes.poll}
            actions={
              <Button
                size="small"
                variant="secondary"
                iconAfter="trash"
                title={t('Poll.AddPoll.Header.discardDraft')}
                onClick={setDraftModal}
              />
            }
          />

          <div className="Content">
            {!hasSubscription && hasPollVotes === 0 && (
              <FlashMessage
                message="Hlasování nebude možné publikovat, jelikož jste v neplaceném režimu aplikace."
                type="warning"
                placement="content"
                className="mb-4"
                action={
                  <Link className="FlashMessage__link text-body-medium link" to={routes.subscriptionOrder}>
                    Objednat
                  </Link>
                }
              />
            )}

            {pollData.questions.map((question: TSFixMe, i: number) => {
              return (
                <Question
                  key={question.id || 'new'}
                  {...question}
                  listIndex={i}
                  pollId={pollData.id}
                  pollData={pollData}
                  setPollData={setPollData}
                />
              );
            })}

            {canCreatePoll && (
              <div
                className={cx(
                  'PollAddQuestion__add-button',
                  pollData.isLoading && 'PollAddQuestion__add-button--loading'
                )}
                onClick={handleCreateNewPoll}
              >
                {!pollData.isLoading && <Icon name="plus" className="mr-1" />}
                <Text as="span" className="Flex" size="body-medium" weight="500" color="Inherit">
                  {pollData.isLoading && <Spinner size="small" className="mr-1" />}
                  {t('Poll.AddPoll.addPollLabel')}
                </Text>
              </div>
            )}

            {canCreateNewQuestion && (
              <div className="PollAddQuestion__add-button" onClick={handleAddQuestionDraft}>
                <Icon name="plus" className="mr-1" />
                <Text as="span" size="body-medium" weight="500" color="Inherit">
                  {t('Poll.AddPoll.addQuestionLabel')}
                </Text>
              </div>
            )}

            {canPollSubmit && <ButtonLink wide title="Pokračovat k nastavení hlasování" to="poll-settings" />}
          </div>
        </>
      )}
    </>
  );
};

export default AddPoll;
