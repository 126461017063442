import cx from 'classnames';
import { Spinner } from '../Spinner/Spinner';

import img from '../../../../src/assets/images/empty-state.svg';
import { Text } from '../Text/Text';
import { t } from '../../i18n';

interface ContentProps {
  id?: string;
  className?: string;
  isLoading?: boolean;
  isEmpty?: boolean;
  isEmptyText?: string;
}

export const Content: React.FC<ContentProps> = (props) => {
  const { id, className, isLoading = false, isEmpty = false, isEmptyText = t('App.emptyState') } = props;

  return (
    <div id={id} className={cx('Content', className)}>
      {isLoading && <Spinner size="large" position="overlay" />}
      {!isLoading && !isEmpty && props.children}

      {!isLoading && isEmpty && (
        <div className="FlexColumn FlexAlign--center FlexJustify--center">
          <img src={img} alt="" className="Content--empty__image" />
          <Text as="div" size="h2" color="Neutral700" className="Content--empty__text text--center mt-3">
            {isEmptyText}
          </Text>
        </div>
      )}
    </div>
  );
};
