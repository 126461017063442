'use client';

import cx from 'classnames';
import { ButtonLink } from '../Button/ButtonLink';
import { Text } from '../Text/Text';

interface SidebarSubscriptionProps {
  type: 'warning' | 'error';
}

export const SidebarSubscription: React.FC<SidebarSubscriptionProps> = (props) => {
  const { type = 'warning' } = props;

  return (
    <div className={cx('SidebarNotification', `SidebarNotification--${type}`)}>
      <Text className="mb-1" color="Neutral100">
        {type === 'warning' ? 'Omezený režim aplikace' : 'Neplacený režim aplikace'}
      </Text>
      <ButtonLink
        to={'/subscription'}
        title="Objednat"
        size="small"
        variant="secondary-outline"
        className="SidebarNotification__button"
        onClick={(e) => e.currentTarget?.blur()}
      />
    </div>
  );
};
