export default {
  title: 'Přihlášení',
  Form: {
    title: 'Přihlašte se',
    error: 'Zadali jste špatný přihlašovací email nebo heslo.',
    Fields: {
      Email: {
        label: 'Email',
        placeholder: 'Zadejte váš email',
      },
      Password: {
        label: 'Heslo',
        placeholder: 'Zadejte vaše heslo',
      },
      Submit: {
        title: 'Přihlásit se',
      },
    },
  },

  Demo: {
    asUser: 'jako uživatel',
    asAdmin: 'jako administrátor',
  },

  dividerText: 'Nebo',
  toRegistration: 'Zaregistrovat se',
  passwordReset: 'Zapomněli jste heslo? <0>Obnovit heslo.</0>',
};
