import cx from 'classnames';
import { FieldError, FieldErrorProps } from '../Error/FieldError';
import { Label } from '../Label/Label';

interface RadioFieldLabelProps {
  label?: string;
  className?: string;
  error?: FieldErrorProps;
  required?: boolean;
}

export const RadioFieldLabel: React.FC<RadioFieldLabelProps> = (props) => {
  return (
    <div className={cx('TextField', 'RadioFieldLabel', props.className)}>
      <Label classNamePrefix="TextField" label={props.label} required={props.required} />
      <FieldError {...props.error} />
    </div>
  );
};
