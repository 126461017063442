import React from 'react';
import { Route, Routes } from 'react-router';

import { UnitsDefault } from './UnitsDefault';
import { ProfileDetail } from './ProfileDetail';
import { UnitDetail } from './UnitDetail';
import { Content } from '../common/components';

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route index element={<UnitsDefault />} />
      <Route path={`profile/:id`} element={<ProfileDetail />} />
      <Route path={`unit/:id`} element={<UnitDetail />} />

      <Route path="*" element={<Content isEmpty={true} />} />
    </Routes>
  );
};

export default Router;
