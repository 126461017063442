import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { t } from '../../common/i18n';
import { useAppThunkDispatch, useDocumentTitle } from '../../common/hooks';

import Yup, { backendViolations, YupEmail, YupPassword } from '../../common/utils/FormValidation';

import {
  Row,
  Col,
  Button,
  Text,
  PasswordField,
  FormMessage,
  Content,
  FormMessageProps,
  ButtonLink,
  TextField,
  CheckboxField,
} from '../../common/components';
import { getInvitationDetails } from './services/InvitationService';
import { useNavigate, useParams } from 'react-router-dom';
import { registerUserWithInvitation } from '../../common/store/securitySlice';
import routes from '../../common/constants/routes';
import { IProfile } from '../../frontend-libs/evlapp-types';
import { GTM } from '../../common/utils';

type Form = {
  email: string;
  password: string;
  passwordCheck: string;
  agreements: {
    marketing: boolean;
    gdpr: boolean;
    termsAndConditions: boolean;
  };
};

export const Invitation: React.FC = () => {
  const namespace = 'Invitation';
  useDocumentTitle(t('title', namespace));

  const navigate = useNavigate();
  const thunkDispatch = useAppThunkDispatch();
  const { token = '' } = useParams();

  const [formMessage, setFormMessage] = useState<FormMessageProps>({ message: '', type: 'error' });

  const [profileData, setProfileData] = useState<IProfile>();
  const [tokenError, setTokenError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchData() {
      getInvitationDetails(token)
        .then((res) => {
          setProfileData({ ...res.profile });
          setIsLoading(false);
        })
        .catch(() => {
          setTokenError(true);
          setIsLoading(false);
        });
    }
    fetchData();
  }, [token]);

  const formik = useFormik<Form>({
    enableReinitialize: true,
    initialValues: {
      email: profileData?.email || '',
      password: '',
      passwordCheck: '',
      agreements: {
        marketing: false,
        gdpr: false,
        termsAndConditions: false,
      },
    },
    validate: () => setFormMessage({}),
    validationSchema: Yup.object({
      email: YupEmail,
      password: YupPassword,
      passwordCheck: Yup.string()
        .required()
        .oneOf([Yup.ref('password'), null], t('Form.Fields.PasswordCheck.matches', namespace)),
      agreements: Yup.object({
        gdpr: Yup.boolean().oneOf([true], t('Form.Fields.Gdpr.required', namespace)),
        termsAndConditions: Yup.boolean().oneOf([true], t('Form.Fields.TermsAndConditions.required', namespace)),
      }),
    }),
    onSubmit: (values) => {
      thunkDispatch(registerUserWithInvitation({ token: token, ...values }))
        .unwrap()
        .then(() => {
          GTM.push({ 'Registration:User': 'complete', event: 'Registration:User:complete' });
          navigate(routes.root, { replace: true });
        })
        .catch((error) => {
          const validations = backendViolations(formik, error);
          if (validations === 'valid') {
            setFormMessage({ message: t('Form.error', namespace), type: 'error' });
          }

          formik.setSubmitting(false);
        });
    },
  });

  return (
    <Content isLoading={isLoading}>
      {tokenError ? (
        <div className="OnboardingForm">
          <Row>
            <Col>
              <Text size="h1" className="mb-1">
                {t('tokenErrorTitle', namespace)}
              </Text>
              <Text size="body-large" color="Neutral700" className="mb-2">
                {t('tokenErrorSubtitle', namespace)}
              </Text>

              <ButtonLink variant="secondary" to={routes.login} title={t('tokenErrorButton', namespace)} />
            </Col>
          </Row>
        </div>
      ) : (
        <form className="OnboardingForm" onSubmit={formik.handleSubmit}>
          <Row>
            <Col className="mb-2">
              <Text size="h1" color="Neutral800" className="mb-1">
                {t('Form.title', namespace)}
              </Text>
              <Text size="body-large">{t('Form.subtitle', namespace)}</Text>
            </Col>
          </Row>

          {formMessage.message && (
            <Row className="mb-2">
              <Col>{formMessage.message && <FormMessage {...formMessage} />}</Col>
            </Row>
          )}

          {!profileData?.email && (
            <Row>
              <Col size="12" className="mb-2">
                <TextField
                  name="email"
                  autocomplete="username"
                  placeholder={t('Form.Fields.Email.placeholder', namespace)}
                  label={t('Form.Fields.Email.label', namespace)}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  required
                  error={{ message: formik.errors.email, touched: formik.touched.email }}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col size="12" className="mb-2">
              <PasswordField
                name="password"
                placeholder={t('Form.Fields.Password.placeholder', namespace)}
                autocomplete="new-password"
                label={t('Form.Fields.Password.label', namespace)}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={{ message: formik.errors.password, touched: formik.touched.password }}
              />
            </Col>
          </Row>

          <Row>
            <Col size="12" className="mb-2">
              <PasswordField
                name="passwordCheck"
                placeholder={t('Form.Fields.PasswordCheck.placeholder', namespace)}
                autocomplete="new-password"
                label={t('Form.Fields.PasswordCheck.label', namespace)}
                value={formik.values.passwordCheck}
                onChange={formik.handleChange}
                error={{ message: formik.errors.passwordCheck, touched: formik.touched.passwordCheck }}
              />
            </Col>
          </Row>

          <Row>
            <Col size="12" className="mb-2">
              <CheckboxField
                name="agreements.termsAndConditions"
                label={t('Form.Fields.TermsAndConditions.label', namespace)}
                onChange={formik.handleChange}
                error={{
                  touched: formik.touched.agreements?.termsAndConditions,
                  message: formik.errors.agreements?.termsAndConditions,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <CheckboxField
                name="agreements.gdpr"
                label={t('Form.Fields.Gdpr.label', namespace)}
                onChange={formik.handleChange}
                error={{ touched: formik.touched.agreements?.gdpr, message: formik.errors.agreements?.gdpr }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              <CheckboxField
                name="agreements.marketing"
                label={t('Form.Fields.Marketing.label', namespace)}
                onChange={formik.handleChange}
                error={{ touched: formik.touched.agreements?.marketing, message: formik.errors.agreements?.marketing }}
              />
            </Col>
          </Row>

          <Row>
            <Col size="12">
              <Button type="submit" title={t('Form.Fields.Submit.title', namespace)} loading={formik.isSubmitting} />
            </Col>
          </Row>
        </form>
      )}
    </Content>
  );
};

export default Invitation;
