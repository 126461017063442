export default {
  title: 'Zprávy',

  Default: {
    Header: {
      title: 'Zprávy',
      add: 'Vytvořit novou zprávu',
    },
    Content: {
      emptyText: 'Zatím nejsou publikovány žádné zprávy',
    },
  },

  Detail: {
    Header: {
      placeholder: 'Zadejte název zprávy',
      edit: 'Upravit zprávu',
      createdAt: 'Vytvořeno',
      updatedAt: 'Naposledy upraveno',
      delete: 'Smazat zprávu',
      Actions: {
        save: 'Uložit',
        saveAndPublish: 'Uložit a publikovat',
        publish: 'Publikovat',
      },
    },
    Flash: {
      Create: {
        success: 'Zpráva byla úspěštně vytvořena.',
        error: 'Zprávu se nepodařilo uložit, prosím zkuste to znovu.',
      },
      Update: {
        success: 'Zpráva byla úspěšně upravena.',
        error: 'Zprávu se nepodařilo upravit, prosím zkuste to znovu.',
      },
      Delete: {
        success: 'Zpráva byla úspěšně smazána.',
        error: 'Zprávu se nepodařilo smazat, prosím zkuste to znovu.',
      },
    },
    Content: {
      save: 'Uložit zprávu',
    },
    Modal: {
      Delete: {
        title: 'Smazat zprávu',
        subtitle: 'Opravdu chcete zprávu smazat?',
        confirm: 'Ano, smazat',
      },
    },
  },
};
