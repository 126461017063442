import cx from 'classnames';
import { useEffect, useState } from 'react';
import { t, translationExist } from '../../i18n';
import { Text } from '../Text/Text';

interface SpinnerProps {
  size?: 'small' | 'default' | 'large';
  position?: 'default' | 'overlay';
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = (props) => {
  const { size = 'default', position = 'default' } = props;
  return <div className={cx(`Spinner`, `Spinner--${size}`, `Spinner--${position}`, props.className)} />;
};

export const PageSpinner: React.FC<SpinnerProps> = (props) => {
  return (
    <div className="SpinnerPageWrapper">
      <Spinner size="large" {...props} />
    </div>
  );
};

interface FullpageSpinnerProps extends SpinnerProps {
  step: number;
  namespace?: string;
}

export const FullPageSpinner: React.FC<FullpageSpinnerProps> = (props) => {
  const { step } = props;

  const [stateClassName, setStateClassName] = useState('fade-out');
  const [text, setText] = useState(t(`string${step}`, props.namespace));

  useEffect(() => {
    if (translationExist(`${props.namespace}.string${step}`)) {
      const handleTransitionIn = () => {
        setStateClassName('fade-in');

        if (translationExist(`${props.namespace}.string${step}`)) {
          setText(t(`string${step}`, props.namespace));
        }
      };

      setStateClassName('fade-out');
      setTimeout(handleTransitionIn, 500);
    }
  }, [step, props.namespace]);

  return (
    <div className="FullPageSpinner">
      <div className="FlexColumn FlexAlign--center">
        <Text as="div" size="h1" align="center" className={cx('mb-1', 'animated', stateClassName)}>
          {text}&#8203;
        </Text>
        <Spinner size="large" />
      </div>
    </div>
  );
};
