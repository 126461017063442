import { Routes, Route } from 'react-router-dom';

import { t } from '../common/i18n';

import { PasswordResetForm } from './components/PasswordResetForm';
import { PasswordChangeForm } from './components/PasswordChangeForm';
import { PageError } from '../System/PageError';

import { useDocumentTitle } from '../common/hooks';

export const Router: React.FC = () => {
  useDocumentTitle(t('PasswordReset.title'));

  return (
    <Routes>
      <Route index element={<PasswordResetForm />} />
      <Route path=":token" element={<PasswordChangeForm />} />

      <Route path="*" element={<PageError code="404" />} />
    </Routes>
  );
};

export default Router;
