export default {
  title: 'Jednotky',

  Table: {
    Header: {
      unit: 'jednotka',
      owner: 'vlastník',
    },
    Body: {
      unit: 'Jednotka',
    },

    ownersCount_zero: '',
    ownersCount_one: 'Další {{count}} vlastník',
    ownersCount_few: 'Další {{count}} vlastníci',
    ownersCount_other: 'Dalších {{count}} vlastníků',
  },

  Default: {
    Header: {
      exportContactsAction: 'Exportovat kontakty',
      importContactsAction: 'Importovat kontakty',
    },

    ModalImport: {
      title: 'Importovat kontakty',
      subtitle_1: 'Import kontaktů slouží k hromadnému doplnění, či změně kontaktních údajů vlastníků.',
      subtitle_2:
        'Doporučujeme vám v prvním kroku stáhnout export kontaktů, doplnit údaje a následně soubor zpět naimportovat (formát XLSX).',
      subtitle_3:
        'Dodržujte (neměňte) pořadí jednotlivých sloupců. Aplikace v průběhu importu doplní, či zaktualizuje pouze kontaktní e-mail a telefonní kontakt.',

      submit: 'Importovat kontakty',
      formError: 'Při importu došlo k chybě, zkontrolujte prosím zadané údaje a soubor nahrajte znovu.',
      BadFormats: {
        email: 'emailu',
        phone: 'telefonu',
        profileId: 'ProfileId',
      },
      Attachment: {
        label: 'Vybrat soubor',
        filesLabel: 'Pro dokončení nahrávání klikněte na tlačítko níže.',
      },

      Flash: {
        importSuccess: 'Import kontaktu proběhl v pořádku.',
      },
    },
  },

  ProfileDetail: {
    title: 'Detail vlastníka',
    Header: {
      title: 'Upravit informace o vlastníkovi',
    },
    Form: {
      Flash: {
        success: 'Úprava profilu proběhla úspěšně.',
        error: 'Úprava profilu se nezdařila, zkuste to prosím znovu.',
      },
      Fields: {
        FirstName: {
          label: 'Jméno',
          placeholder: 'Jméno vlastníka není v katastru nemovitostí k dispozici',
          required: 'Jméno musí být vyplněné',
        },
        LastName: {
          label: 'Příjmení',
          placeholder: 'Příjmení vlastníka není v katastru nemovitostí k dispozici',
          required: 'Příjmení musí být vyplněné',
        },
        Company: {
          label: 'Společnost',
          placeholder: 'Název společnosti není v katastru nemovitostí k dispozici',
          required: 'Název společnosti můsí být vyplněn',
        },
        Email: {
          label: 'Email',
          placeholder: 'Email vlastníka',
        },
        Phone: {
          label: 'Telefon',
          placeholder: 'Telefon vlastníka',
          required: 'Telefon je povinný',
        },
        Birthday: {
          label: 'Datum narození',
          placeholder: 'Datum narození vlastníka není k dispozici',
        },
        BirthNumber: {
          label: 'Rodné číslo',
          placeholder: 'Rodné číslo není v katastru nemovitostí k dispozici',
        },
        Submit: {
          title: 'Uložit změny',
        },
      },
    },
    InvitationModal: {
      linkTitle: 'Odeslat pozvánku do aplikace',
      title: 'Opravdu chcete odeslat pozvánku?',
      subtitle: 'Pozvánky se automaticky rozesílají uživatelům při publikování hlasování.',
      confirm: 'Odeslat',
      Flash: {
        success: 'Pozvánka byla úspěšně odeslána',
        error: 'Pozvánku se nepodařilo odeslat',
      },
    },
  },

  UnitDetail: {
    title: 'Detail jednotky',
    Header: {
      title: 'Jednotka',
    },
    Form: {
      submit: 'Uložit',
      error: 'Úprava jednotky se nezdařila, zkuste to prosím znovu.',
      Fields: {
        UseType: {
          label: 'Způsob využití',
          placeholder: 'Zadejte využití jednotky',
        },
        Address: {
          label: 'Adresa jednotky',
          placeholder: 'Zadejte adresu jednotky',
        },
        Owners: {
          label: 'Vlastníci jednotky',
        },
        share: 'Podíl na společných prostorách',

        VotingStrategy: {
          label: 'Vyberte typ hlasování za tuto jednotku',
          default: 'první z hlasujících odhlasuje za celou jednotku',
          default_description:
            'V případě, že přístup do aplikace má více vlastníků jednotky, ten který odhlasuje jako první, hlasuje za celý podíl jednotky. Ostatní spoluvlastníci musí dodat zmocnění tohoto vlastníka, jinak nelze hlas započítat! V případě, že stanovy nevyžadují udělení plné moci, může být zmocnění udělené jakýmkoliv písemným způsobem - např. i elektronicky (e-mailem).',
          separateVoting: 'oddělené hlasování v jednotce (přičtení k celku na konci)',
          separateVoting_description:
            'U tohoto typu hlasování aplikace spočítá nejdříve hlasy spoluvlastníků v dané jednotce (s respektováním síly dle podílů v jednotce) a výsledek pak přičte k celkovému hlasování v domě po ukončení hlasování (se zachováním nastaveného kvóra pro přijetí daného usnesení). Konstatujeme, že zákon připouští v případě více spoluvlastníků pouze hlasování přes zmocněného zástupce a tedy tento typ hlasování nemá oporu v zákoně.',
          excludedFromVoting: 'vyloučení jednotky z hlasování',
          excludedFromVoting_description:
            'Pokud je u jednotky více spoluvlastníků, kteří z jakéhokoliv důvodu neurčí společného zástupce, neměl by být hlas za tuto jednotku vůbec do hlasování započítán. V případě volby tohoto typu hlasování nebude podíl jednotky do hlasování započítán - aplikace se zachová tak, jakoby tato jednotka vůbec nehlasovala (nezávisle na odeslaných hlasech spoluvlastníků). Sníží se tím ale samozřejmě pravděpodobnost přijetí navrhovaného usnesení.',
        },
      },
    },
  },
};
